import KanbanBoard from "./KanbanBoard";
import "./kanban.scss";

function Kanban({ workflowSteps, cards, id, boardName }) {

  return (
    <div>
      <KanbanBoard
        boardName={boardName}
        workflowSteps={workflowSteps}
        cards={cards}
        id={id}
      />
    </div>
  );
}

export default Kanban;

import React, { useEffect, useMemo, useState } from "react";
import { DragDropContext } from "react-beautiful-dnd";
import { useSelector } from "react-redux";
import { debounce } from "lodash";

import { useMutation, useQueryClient } from "@tanstack/react-query";
import toast from "react-hot-toast";
import { updateCard } from "../../../../sevices/apiCards";
import KanbanContainor from "./KanbanContainor";
import OpenCardDetail from "../../../board/components/Kanban/openCardDetail";
import { useSearchParams } from "react-router-dom";

function KanbanBoard({ workflowSteps, cards, id, callback }) {
  const queryClient = useQueryClient();
  const [projects, setProjects] = useState(cards);
  const [draggedOverCol, setDraggedOverCol] = useState(0);
  const epicSearch = useSelector((state) => state.epic.searchEpic);
  // const [loading, setLoading] = useState(false);
  console.log("epicSearch", epicSearch);
  const [searchParams, setSearchParams] = useSearchParams();
  const cardId = searchParams.get("card_id");
  // Redux state
  const searchText = useSelector((state) => state.search.searchText);
  // const filterUser = useSelector((state) => state.users.filterByUserId);
  const filterUser = searchParams.get("search");

  useEffect(() => {
    setProjects(cards);
  }, [cards]);

  useEffect(() => {
    // Define the debounce delay (e.g., 300 milliseconds)
    const debounceDelay = 1000;

    // Create a debounced version of the filter function
    const debouncedFilter = debounce(() => {
      if (searchText) {
        const filteredBySearch = cards.filter(
          (el) =>
            (el.card?.title
              ? `${el.card.title}`.toLowerCase().includes(searchText)
              : "") ||
            (el.card.assigned_to_user?.first_name
              ? `${el.card.assigned_to_user?.first_name}`
                  .toLowerCase()
                  .includes(searchText)
              : "")
        );
        console.log(filteredBySearch);
        setProjects(filteredBySearch);
      } else {
        setProjects(cards);
      }
    }, debounceDelay);

    if (filterUser) {
      const filteredCardsByUser = cards.filter(
        (el) => el.card.assigned_to_user_id == filterUser
      );
      setProjects(filteredCardsByUser);
    } else if (epicSearch && epicSearch.length > 0) {
      console.log("filter");
      const filterEpics = cards.filter((item) =>
        epicSearch.includes(item.card.epic_id)
      );

      setProjects(filterEpics);
    } else {
      // Call the debounced filter function whenever searchText, id, or cards change
      debouncedFilter();
    }

    return () => {
      // Cleanup the debounce function on component unmount
      debouncedFilter.cancel();
    };
  }, [searchText, filterUser, epicSearch]);

  //Mutate a card //

  const { mutate: updateMutate } = useMutation({
    mutationFn: updateCard,
    onSuccess: (res) => {
      // queryClient.invalidateQueries({
      //   queryKey: [`cards`, id],
      // });
      // setLoading(false);
      callback?.();
      toast.success(res.data.message);
      //  handleCloseDialog();
    },
    onError: (error) => {
      console.error("Error Delete Attachments", error);
      // toast.error(error.response.data.message);
      // setLoading(false);
      //  handleCloseDialog();
    },
  });

  //this is called when a Kanban card is dragged over a column (called by column)
  const handleOnDragEnter = (e, stageValue) => {
    console.log(stageValue);
    setDraggedOverCol(stageValue);
  };

  //this is called when a Kanban card dropped over a column (called by card)
  const handleOnDragEnd = (e, project) => {
    console.log("drop card =>", project);
    const updatedProjects = projects.slice(0);
    console.log("updatedProjects", updatedProjects);
    const updatedProjectIndex = updatedProjects.findIndex(
      (projectObject) => projectObject.card.card_id === project.card_id
    );
    console.log("updatedProjectIndex", updatedProjectIndex);
    if (updatedProjectIndex !== -1) {
      updateMutate({
        card_id: project.card_id,
        data: { workflow_step_id: draggedOverCol },
      });
      updatedProjects[updatedProjectIndex].card.workflow_step_id =
        draggedOverCol;
      setProjects(updatedProjects);
    }
  };

  // if (isLoading) {
  //   return <h3>Loading...</h3>;
  // }

  // console.log("drag card =>", draggedOverCol);
  // function scrollLeft() {
  //   var scrollContainer = document.querySelector(".board-card-scroll-btn");
  //   scrollContainer.scrollLeft -= 280;
  // }

  // function scrollRight() {
  //   var scrollContainer = document.querySelector(".board-card-scroll-btn");
  //   scrollContainer.scrollLeft += 280;
  // }

  const dragCardInBoard = (source, destination, draggableId) => {
    const updatedProjects = projects.slice(0);
    const updatedProjectIndex = updatedProjects.findIndex(
      (projectObject) => projectObject.card.card_id == draggableId
    );
    if (updatedProjectIndex !== -1) {
      updateMutate({
        card_id: draggableId * 1,
        data: { workflow_step_id: destination.droppableId * 1 },
      });
      updatedProjects[updatedProjectIndex].card.workflow_step_id =
        destination.droppableId * 1;
      setProjects(updatedProjects);
    }

    // let tempData = [...projects];
    // const destinationBoardIdx = tempData.findIndex(
    //   (item) => item.workflow_step_id.toString() === destination.droppableId
    // );
    // const sourceBoardIdx = tempData.findIndex(
    //   (item) => item.workflow_step_id.toString() === source.droppableId
    // );
    // tempData[destinationBoardIdx].card.splice(
    //   destination.index,
    //   0,
    //   tempData[sourceBoardIdx].card[source.index]
    // );
    // tempData[sourceBoardIdx].card.splice(source.index, 1);

    // return tempData;
  };

  const onDragEnd = (result) => {
    const { source, destination, draggableId } = result;
    // console.log("result", source, destination, draggableId);
    // console.log("result", result);
    if (!destination) return;

    if (source.droppableId === destination.droppableId) return;

    dragCardInBoard(source, destination, draggableId);
  };

  console.log("pROJE......", projects);
  return (
    <>
      {/* <div className="vertical-scrollbar width-100"> */}
      {projects && cardId && (
        <OpenCardDetail
          cardsOnBoard={projects.map((el) => {
            return {
              ...el,
              ...el.card,
            };
          })}
        />
      )}
      {/* <DragDropContext onDragEnd={onDragEnd}> */}
      <KanbanColumnMemo
        projects={projects}
        workflowSteps={workflowSteps}
        onDragEnter={handleOnDragEnter}
        onDragEnd={handleOnDragEnd}
        callback={callback}
      />

      {/* <div className="fixed-div">
          <IconButton aria-label="left" size="small">
            <NavigateBeforeIcon onClick={scrollLeft} />
          </IconButton>
          <IconButton aria-label="right" size="small">
            <NavigateNextIcon onClick={scrollRight} />
          </IconButton>
        </div> */}
      {/* </DragDropContext> */}
      {/* </div> */}
    </>
  );
}

// Memoized KanbanColumn component
const KanbanColumnMemo = React.memo(KanbanContainor);

export default KanbanBoard;

import React from "react";
import { useNavigate } from "react-router-dom";
import Loader from "../../components/Loader";
import { useBoards } from "../../utils/reactQuery/boards";

function CheckBoardAvailability() {
  const { isLoading, boards, isError } = useBoards();
  const navigate = useNavigate();

  const filterBoardsByStatus = (data, isActive) => {
    return data
      .filter((el) => (isActive ? !el.is_archive : el.is_archive))
      .sort(function (a, b) {
        return a.board_name.localeCompare(b.board_name);
      });
  };
  if (isLoading || isError)
    return (
      <div className="loader-center">
        <Loader color={"black"} />
      </div>
    );
  const {
    data: {
      data: { totalRecord, responseData },
    },
  } = boards;
  if (totalRecord) {
    const getActiveBoardID = filterBoardsByStatus(responseData, true);
    const getInActiveBoardID = filterBoardsByStatus(responseData, false);
    let boardId =
      getActiveBoardID?.length !== 0
        ? getActiveBoardID[0]?.board_id
        : getInActiveBoardID.length !== 0
        ? getInActiveBoardID[0]?.board_id
        : null;

    navigate(`/board/${boardId}`);
  } else {
    return (
      <>
        <h1>No Board Found</h1>
      </>
    );
  }
}

export default CheckBoardAvailability;

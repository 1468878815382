import { useMutation, useQueryClient } from '@tanstack/react-query';
import React,{useState} from 'react'
import { TextareaAutosize as BaseTextareaAutosize } from '@mui/base/TextareaAutosize';
// import { TextareaAutosize } from '@mui/base/TextareaAutosize';
import { styled } from '@mui/system';
import toast from 'react-hot-toast';
import { useParams } from 'react-router-dom';
import { updateEpic } from '../../../../../sevices/apiEpic';
import EditIcon from '../../../../../styles/svg/edit-title.svg'
const EditEpic = ({epicId, epicName,setShowEditArea}) => {
    const [newEpic, setNewEpic] = useState(null)
    const queryClient = useQueryClient();
    const {id} = useParams()
    const { mutate: updateEpicMutate } = useMutation({
        mutationFn: updateEpic,
        onSuccess: (res) => {
          queryClient.invalidateQueries({
            queryKey: [`epic`, id],
          });
          queryClient.invalidateQueries({
            queryKey: [`cards`, id],
          });
          // setLoading(false);
          // console.log(res);
          //    reset();
          toast.success(res.data.message);
          // handleClose();
        },
        onError: (error) => {
          console.error("Error Delete Attachments", error);
          toast.error(error.response.data.message);
          // setLoading(false);
          // handleClose();
        },
      });

      const handleEdit = (e)=>{
        if(e.key == 'Enter'){
          e.preventDefault();
          updateEpicMutate({
            epic_id: epicId,
            board_id: id,
            data: {
              epic_name: newEpic
            }
            
          })
          setShowEditArea(false)
        }
       
      }

      const handleChange = (e)=>{
        setNewEpic(e.target.value)
      }

  


  return (
  <>
   <TextareaAutosize aria-label="empty textarea" defaultValue={epicName} onKeyDown={handleEdit} onChange={handleChange} />
  {/* <img src={EditIcon} onKeyDown={handleEditEpic} onClick={handleShowEditArea}/> */}
  </>
  )
}

export default EditEpic


const blue = {
  100: '#DAECFF',
  200: '#b6daff',
  400: '#3399FF',
  500: '#007FFF',
  600: '#0072E5',
  900: '#003A75',
};

const grey = {
  50: '#F3F6F9',
  100: '#E5EAF2',
  200: '#DAE2ED',
  300: '#C7D0DD',
  400: '#B0B8C4',
  500: '#9DA8B7',
  600: '#6B7A90',
  700: '#434D5B',
  800: '#303740',
  900: '#1C2025',
};

const TextareaAutosize = styled(BaseTextareaAutosize)(
  ({ theme }) => `
  box-sizing: border-box;
  width: 90%;
  font-family: 'IBM Plex Sans', sans-serif;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5;
  padding: 4px 8px 4px 6px;
  border-radius: 8px;
  color: ${theme.palette.mode === 'dark' ? grey[300] : grey[900]};
  background: ${theme.palette.mode === 'dark' ? grey[900] : '#fff'};
  border: 1px solid ${theme.palette.mode === 'dark' ? grey[700] : grey[200]};
  box-shadow: 0px 2px 2px ${theme.palette.mode === 'dark' ? grey[900] : grey[50]};

  &:hover {
    border-color: ${blue[400]};
  }

  &:focus {
    border-color: ${blue[400]};
    box-shadow: 0 0 0 3px ${theme.palette.mode === 'dark' ? blue[600] : blue[200]};
  }

  // firefox
  &:focus-visible {
    outline: 0;
  }
`,
);
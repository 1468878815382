import React, { useEffect, useState } from "react";
import { Menu, MenuItem } from "@mui/material";
import toast from "react-hot-toast";
import { useMutation, useQueryClient } from "@tanstack/react-query";

import { createAvtarName, hasImage } from "../../../../../utils/helpers";
import ConfirmationDialog from "../../../../../components/Dialog/ConfirmationDialog";
import { updateBoard } from "../../../../../sevices/apiBoards";
import { useBoardUsers } from "../../../../../utils/reactQuery/users";
import Avatar from "../../../../../components/Avatar";
import Loader from "../../../../../components/Loader";

function ListBoardUser({ anchorEl, open, handleClose, id, params }) {
  const queryClient = useQueryClient();
  const [users, setUsers] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [loading, setLoading] = useState(false);
  const [userId, setUserId] = useState(null);

  const { isLoading, error, data, isError } = useBoardUsers(id);
  // console.log("boardUser", data);

  useEffect(() => {
    const sortedUsers = data?.data?.data?.responseData
      ? data?.data?.data?.responseData.sort(function (a, b) {
          return a.username.localeCompare(b.username);
        })
      : [];

    setUsers(sortedUsers);
  }, [data, isLoading, id]);

  const { mutate: assignBoardMutate } = useMutation({
    mutationFn: updateBoard,
    onSuccess: (res) => {
      queryClient.invalidateQueries({
        queryKey: [`boards`],
      });
      queryClient.invalidateQueries({
        queryKey: [`boardName`, id],
      });
      setLoading(false);
      toast.success(res.data.message);
      setOpenDialog(false);
      handleClose();
    },
    onError: (error) => {
      console.error("Error assig board", error);
      toast.error(error.response.data.message);
      setLoading(false);
      setOpenDialog(false);
    },
  });

  const callback = () => {
    setLoading(true);
    assignBoardMutate({
      board_id: id,
      data: { owner_user_id: userId.user_id },
    });
  };

  const assignUser = (id) => {
    setOpenDialog(true);
    setUserId(id);
  };
  return (
    <>
      {openDialog && (
        <ConfirmationDialog
          open={openDialog}
          handleClose={() => setOpenDialog(false)}
          message={`Are you sure to assign ${userId.username} as Board Owner?`}
          callback={callback}
          loading={loading}
          btnTitle={"Assign"}
          title={"Assign Board Owner"}
        />
      )}
      <Menu
        id="account-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        // onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            mt: 1.5,

            "&::before": {
              content: '""',
              display: "block",
              position: "absolute",
              top: 0,
              left: 14,
              width: 14,
              height: 14,
              bgcolor: "background.paper",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: "left", vertical: "top" }}
        anchorOrigin={{ horizontal: "left", vertical: "bottom" }}
        className="assign-board-owner-list"
      >
        {isLoading && (
          <div className="loader-center">
            <Loader color={"black"} />
          </div>
        )}
        {users.map((el) => {
          return (
            <MenuItem
              key={`user-avtar-dropdown-admin-assign-${el.user_id}`}
              onClick={() => assignUser(el)}
              className={
                params.row.board_owner_id == el.user_id ? "active-assign" : ""
              }
            >
              <Avatar
                key={`user-avtar-${el.user_id}`}
                username={el.username}
                user_image={el.user_image}
                title={el.username}
                className="user-avtar-dropdown user-avatar mr-8"
                style={
                  !hasImage(el.user_image) && {
                    bgcolor: el?.profile_color?.background,
                    color: el?.profile_color?.text,
                  }
                }
              >
                {createAvtarName(el.username)}
              </Avatar>

              {el.username}
            </MenuItem>
          );
        })}
      </Menu>
    </>
  );
}

export default ListBoardUser;

import React from 'react'
import Header from './components/header/Header'
import StatusTable from './components/statusTable/StatusTable'
import './report.scss'
const index = () => {
  return (
    <>
    <Header/>
    <StatusTable/>
    </>
  )
}

export default index
import { Button } from "@mui/material";
import React from "react";

import AddIcon from "@mui/icons-material/Add";
import FormDialog from "../../../../components/Dialog/FormDialog";
import InviteUserForm from "./InviteUserForm";
import CreateTeamForm from "./CreateTeamForm";
import { useResponsive } from "../../../../hooks/useResponsive";

function CreateTeam() {
  const [open, setOpen] = React.useState(false);
  const isMobile = useResponsive("down", "md");
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  return (
    <div>
      {open && (
        <FormDialog
          open={open}
          handleClose={handleClose}
          title={"Create Team"}
          btnTitle={"Add"}>
          <CreateTeamForm onClose={handleClose} />
        </FormDialog>
      )}
      <Button
        onClick={handleClickOpen}
        className="capitalize theme-bg-color"
        variant="contained"
        sx={{
          fontSize: isMobile ? "12px" : "",
          fontWeight: isMobile ? "500" : "",
          padding: isMobile ? "6px 9px" : "",
        }}
        startIcon={<AddIcon />}>
        Create Team
      </Button>
    </div>
  );
}

export default CreateTeam;

import { MenuItem, Select, TextField } from "@mui/material";
import React, { useEffect } from "react";
import { Draggable } from "react-beautiful-dnd";
import workflowStepdotsIcon from "../../../../../styles/svg/workflowStepdotsIcon.svg";
import adminWorkflowDelete from "../../../../../styles/svg/adminWorkflowDelete.svg";
// import RemoveStep from "../RemoveStep";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { deleteWorkflowStep } from "../../../../../sevices/apiWorkflows";
import toast from "react-hot-toast";
import { Controller } from "react-hook-form";
import { useGenericWorkflowStatus } from "../../../../../utils/reactQuery/workflows";

const DraggableField = ({
  field,
  index,
  remove,
  edit,
  setOpenRemoveStep,
  register,
  setCurrentStep,
  control,
}) => {
  const [targetStepId, setTargetStepId] = React.useState(null);
  const queryClient = useQueryClient();
  const { genericWorkflowStatus } = useGenericWorkflowStatus();
  const { mutate: deleteWorkflowStepMutate } = useMutation({
    mutationFn: deleteWorkflowStep,
    onSuccess: (res) => {
      queryClient.invalidateQueries({
        queryKey: [`adminWorkflow`],
      });
      toast.success(res.data.message);
      // handleDeleteClose();
      remove(index);
    },
    onError: (error) => {
      // close(true)
      setOpenRemoveStep(true);
      console.error("Error Delete Attachments", error);

      // toast.error(error.response.data.message);
      //   setLoading(false);
      // handleDeleteClose();
    },
  });

  const handleDelete = (field) => {
    if (field.workflow_step_id) {
      setCurrentStep(field);

      deleteWorkflowStepMutate({ workflow_step_id: field.workflow_step_id });
    } else {
      remove(index);
    }
  };

  return (
    <>
      <Draggable key={field.id} draggableId={field.id} index={index}>
        {(provided) => (
          <div
            className="workflowStep-container"
            ref={provided.innerRef}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            key={field.id}>
            <div className="workflowStep">
              <img src={workflowStepdotsIcon} alt="" />
              <TextField
                id={`workflow_steps.${index}.status`}
                // defaultValue={field.status}
                size="small"
                variant="standard"
                autoFocus
                {...register(`workflow_steps.${index}.status`)}
                InputProps={{
                  disableUnderline: true,
                  sx: {
                    padding: "0px",
                    marginLeft: "10px",
                    "& .MuiInputBase-input": {
                      fontSize: "14px",
                      fontWeight: "500",
                      color: "rgba(33, 43, 54, 1)",
                      padding: "0px",
                      textTransform: "uppercase",
                    },
                  },
                }}
                sx={{
                  "& .MuiOutlinedInput-root": {
                    padding: "0px",
                    marginLeft: "10px",
                    "& fieldset": {
                      border: "0px",
                    },
                  },
                }}
              />
            </div>
            <Controller
              name={`workflow_steps.${index}.generic_step_id`}
              control={control}
              render={({ field }) => (
                <Select
                  {...field}
                  value={field.value || ""}
                  onChange={(e) => {
                    field.onChange(e.target.value);
                    console.log("field value of workflowStep", field.value);
                  }}
                  displayEmpty
                  sx={{ minWidth: 120, marginLeft: "10px" }}>
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                  {genericWorkflowStatus?.data?.data?.responseData?.map(
                    (ele, idx) => (
                      <MenuItem key={idx} value={ele?.workflow_step_id}>
                        {ele.status}
                      </MenuItem>
                    )
                  )}
                </Select>
              )}
            />
            {/* <Select value={register.value(`workflow_steps.${index}.genric_workflow_id`)}>
              <MenuItem key="1" value={1}>
                first Id
              </MenuItem>
              <MenuItem key="2" value={2}>
                second Id
              </MenuItem>
              <MenuItem key="3" value={3}>
                third Id
              </MenuItem>
            </Select> */}
            {!edit ? (
              <div
                className="workflowStep-delete"
                onClick={() => remove(index)}>
                <img src={adminWorkflowDelete} alt="" />
              </div>
            ) : (
              <div
                className="workflowStep-delete"
                onClick={() => handleDelete(field)}>
                <img src={adminWorkflowDelete} alt="" />
              </div>
            )}
          </div>
        )}
      </Draggable>
    </>
  );
};

export default DraggableField;

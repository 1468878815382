import React from 'react'

import {isAdmin} from '../../../utils/constants'
import Header from './components/Header';
import Table from './components/BoardTable/Table';

function index() {
  return (
    <>
      {isAdmin() ? (
        <>
          <Header />
          <Table />
        </>
      ) : (
        <div className="loader-center">
          <div className="weight-700 size-16 text-color ">
            You cant access this page
          </div>
        </div>
      )}
    </>
  );
}

export default index
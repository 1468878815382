import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  Alert,
  Box,
  Button,
  MenuItem,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import toast from "react-hot-toast";

import { updateBoard } from "../../../../../sevices/apiBoards";
import { schema } from "../../../../../schemas/updateKeyBoard";
import { useWorkflow } from "../../../../../utils/reactQuery/workflows";
import Field from "../../../../../components/field/index";
import TransferIcon from "../../../../../styles/svg/iconoir_dot-arrow-right.svg";
import { changeBoardWorkflow } from "../../../../../sevices/apiWorkflows";

function WorkflowForm({ params, onClose }) {
  const [apiError, setApiError] = React.useState("");
  const [loading, setLoading] = React.useState(false);
  const [workflowOption, setWorkFlowOption] = useState([]);
  const queryClient = useQueryClient();
  const { isLoading: isWorkflowLoading, workflows } = useWorkflow();
  // React Hook Form
  const { register, handleSubmit, reset, getValues, formState, watch } =
    useForm({
      defaultValues: {
        board_name: params.row.board_name,
        workflow_id: params.row.workflow_id,
      },
    });
  const workflowValue = watch("workflow_id");
  const { errors } = formState;

  const { mutate } = useMutation({
    mutationFn: changeBoardWorkflow,
    onSuccess: (res) => {
      queryClient.invalidateQueries({
        queryKey: ["boards"],
      });
      setLoading(false);
      toast.success(res.data.message);
      onClose();
    },
    onError: (error) => {
      console.error("Error Delete Attachments", error);
      toast.error(error.response.data.message);
      setLoading(false);
    },
  });

  const submit = (data) => {
    /**
     * Create Board
     */
    setApiError("");
    setLoading(true);
    const newData = { ...data };
    delete newData.workflow_id;
    delete newData.board_name;

    mutate({
      board_id: params.row.board_id,
      workflow_id: data.workflow_id,
      data: { workflow_step_exchange_id: newData },
    });
    // console.log(data);
  };

  useEffect(() => {
    if (!isWorkflowLoading) {
      setWorkFlowOption(
        workflows.data.data.responseData.map((el) => {
          return { label: el.workflow_name, value: el.workflow_id };
        })
      );
    }
  }, [isWorkflowLoading, workflows]);

  const prepareOptions = (id) => {
    return workflows?.data.data.responseData
      .find((el) => el.workflow_id == id)
      .steps.map((el) => {
        return { label: el.status, value: el.workflow_step_id };
      });
  };
  return (
    <Box
      component="form"
      onSubmit={handleSubmit(submit)}
      noValidate
      sx={{
        padding: "14px",
      }}
    >
      <Stack spacing={3}>
        {apiError && <Alert severity="error">{apiError}</Alert>}
        <TextField
          label={"Board Name"}
          required
          disabled
          id="board_name"
          name="board_name"
          error={errors?.board_name ? true : false}
          helperText={errors?.board_name && errors.board_name.message}
          {...register("board_name")}
        />
        <Field
          label={"Board Workflow"}
          required
          id="workflow_id"
          name="workflow_id"
          error={errors?.workflow_id ? true : false}
          helperText={errors?.workflow_id && errors.workflow_id.message}
          select
          defaultValue={params.row.workflow_id}
          {...register("workflow_id")}
        >
          {workflowOption.map((option) => (
            <MenuItem
              key={`workflow-option-${option.value}`}
              id={`workflow-option-id-${option.value}`}
              value={option.value}
            >
              {option.label}
            </MenuItem>
          ))}
        </Field>
        {workflowValue != params.row.workflow_id && (
          <Box>
            <Typography className="weight-700 size-16 text-color">
              Ticket Reassignment Step
            </Typography>
            <Stack spacing={1}>
              {workflows?.data.data.responseData
                .find((el) => el.workflow_id == params.row.workflow_id)
                .steps.map((step, idx) => {
                  return (
                    <Box className="board-workflow-change-item">
                      <TextField
                        sx={{ width: "40%", backgroundColor: "white" }}
                        className="mui-status-field"
                        disabled
                        value={step.status}
                      />
                      <img src={TransferIcon} />
                      <Field
                        sx={{ width: "40%", backgroundColor: "white" }}
                        className="mui-status-field"
                        required
                        id={`${step.workflow_step_id}`}
                        name={`${step.workflow_step_id}`}
                        // error={errors?.workflow_id ? true : false}
                        // helperText={
                        //   errors?.workflow_id && errors.workflow_id.message
                        // }
                        select
                        defaultValue={prepareOptions(workflowValue)[0].value}
                        {...register(`${step.workflow_step_id}`)}
                      >
                        {prepareOptions(workflowValue).map((option) => (
                          <MenuItem
                            key={`workflow-option-${option.value}`}
                            id={`workflow-option-id-${option.value}`}
                            value={option.value}
                          >
                            {option.label}
                          </MenuItem>
                        ))}
                      </Field>
                    </Box>
                  );
                })}
            </Stack>
          </Box>
        )}
        <Box
          sx={{
            alignSelf: "end",
          }}
        >
          <Button
            type="submit"
            variant="contained"
            sx={{
              bgcolor: "#1B3E61",
              mr: "10px",
              fontSize: "16px",
              padding: "10px 16px",
              fontWeight: "600",
              textTransform: "capitalize",
              minWidth: "130px",
            }}
            disabled={loading || workflowValue == params.row.workflow_id}
          >
            Update
          </Button>
          <Button
            variant="outlined"
            sx={{
              color: "#1B3E61",
              border: "1px solid #1B3E61",
              textTransform: "capitalize",
              fontSize: "16px",
              padding: "10px 16px",
              fontWeight: "600",
              opacity: "0.6",
              minWidth: "130px",
            }}
            onClick={onClose}
            disabled={loading}
          >
            Cancel
          </Button>
        </Box>
      </Stack>
    </Box>
  );
}

export default WorkflowForm;

import React, { useState } from "react";
import ConfirmationDialog from "../Dialog/ConfirmationDialog";
import ConfirmationLock from "../Milestone/confirmLock";
import { useQueryClient } from "@tanstack/react-query";
import { useCustomSearchParams } from "../../hooks/useCustomSearchParams";

function ToggleInput({ name, setValue, defaultValue }) {
  const [status, setStatus] = useState(defaultValue);
  const [confirmPopup, setConfirmPopup] = useState(false);
  const [milestone, setMilestone] = useCustomSearchParams("milestone");
  // console.log(name);
  const handleIslock = () => {
    setConfirmPopup(true);
  };

  const callback=()=>{
    // setStatus(!status);
    setConfirmPopup(false);
  }
  return (
    <>
      <label className="switch">
        <input
          type="checkbox"
          value={defaultValue}
          checked={defaultValue}
          // defaultValue={defaultValue}
          onChange={handleIslock}
        />
        <div className={`slider `}></div>
      </label>
      <ConfirmationLock
        open={confirmPopup}
        title={name}
        is_locked={defaultValue}
        message={
          status
            ? "Add a comment to unLock Milestone"
            : "Add a comment to Lock Milestone"
        }
        btnTitle={"Confirm"}
        callback={callback}
        handleClose={() => setConfirmPopup(false)}
      />
    </>
  );
}

export default ToggleInput;

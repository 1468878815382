import React, { useState } from "react";
import FilterStatus from "./FilterStatus";
import FilterUserType from "./FilterUserType";
import InviteUser from "./InviteUser";
import CreateTeam from "./CreateTeam";
import { useResponsive } from "../../../../hooks/useResponsive";
import {
  Box,
  Drawer,
  Grid,
  Stack,
  styled,
  Typography,
  useScrollTrigger,
} from "@mui/material";
import filterIcon from "../../../../styles/svg/epic-icon.svg";

const AnimatedDrawerContent = styled(Box)(({ theme }) => ({
  width: 250,
  transition: "transform 1s ease-in-out",
}));

function Header() {
  const isMobile = useResponsive("down", "md");
  const trigger = useScrollTrigger();
  const [filterDrawer, setFilterDrawer] = useState(false);

  return (
    <>
      {isMobile ? (
        <Grid
          container
          xs={12}
          className="remove-container-width fixed-ui"
          sx={{ top: trigger ? "0px" : isMobile ? "51px" : "" }}
          justifyContent={"center"}
          boxShadow={" 0px 1px 7px rgba(0, 0, 0, 0.3)"}
          pb={"16px !important"}
          pt={trigger ? "24px !important" : "20px !important"}
          alignItems={"center"}>
          <Grid
            item
            xs={6}
            md={12}
            lg={3}
            className="d-flex align-items-center">
            <Typography fontWeight={600}>User Management</Typography>
          </Grid>
          <Grid item xs={6} md={7} lg={9}>
            <Stack direction="row" justifyContent="end">
              <img
                src={filterIcon}
                alt="filter"
                style={{ marginRight: "8px" }}
                onClick={() => setFilterDrawer(true)}
              />
            </Stack>{" "}
          </Grid>
        </Grid>
      ) : (
        <div className="d-flex justify-content-space-between mb-4">
          <p className="admin-header-heading">User Management</p>
          <div className="d-flex align-items-center ">
            <div className="d-flex align-items-center mr-2">
              <p className="mr-3 filter-heading">Filter by:</p>
              <FilterUserType />
              <FilterStatus />
              <InviteUser />
              <CreateTeam />
            </div>
          </div>
        </div>
      )}
      {filterDrawer && (
        <Drawer
          open={filterDrawer}
          anchor="right"
          onClose={() => setFilterDrawer(false)}>
          <AnimatedDrawerContent
            style={{
              transform: filterDrawer ? "translateX(0)" : "translateX(100%)",
            }}>
            <Box p={1}>
              <div className="mt-2">
                <p className="filter-text">Select User Type:</p>
                <div className="mt-2">
                  <FilterUserType />
                </div>
              </div>

              <div className="mt-4">
                <p className="filter-text">Select Status:</p>
                <div className="mt-2">
                  <FilterStatus />
                </div>
              </div>

              <div className="mt-4">
                <div className="mt-2 d-flex">
                  <InviteUser />
                  <CreateTeam />
                </div>
              </div>
            </Box>
          </AnimatedDrawerContent>
        </Drawer>
      )}
    </>
  );
}

export default Header;

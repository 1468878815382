import React from "react";
import { Button, Tooltip } from "@mui/material";
import { useParams, useSearchParams } from "react-router-dom";
import GroupUser from "../../../styles/svg/group-user.svg";

function Grouping() {
  const [searchParams, setSearchParams] = useSearchParams();

  const updateSearchParams = (key, value) => {
    const newSearchParams = new URLSearchParams(searchParams);
    newSearchParams.set(key, value);
    setSearchParams(newSearchParams);
  };

  return (
    <div className="ml-1">
      <Tooltip title="Group by user">
        <Button onClick={() => updateSearchParams("tab", "Grouping")}>
          <img src={GroupUser} />
        </Button>
      </Tooltip>
    </div>
  );
}

export default Grouping;

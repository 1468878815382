import React from "react";
import { useParams } from "react-router-dom";
import { List, ListItem, Typography, Box } from "@mui/material";
import { useAttachments } from "../../../../utils/reactQuery/boards";
import Loader from "../../../../components/Loader";
import ListItems from "./ListItem";

function AttachmentsList({ isMobile }) {
  const { id } = useParams();
  const { attachments, isLoading, isError, refetch } = useAttachments(id);
  // console.log("attachments", attachments, isLoading, isError);
  return (
    <div>
      <p
        className={`blue-text-color ${
          isMobile ? "attachment-text" : "attachment-list-header-text"
        }`}
      >
        Attachments list:
      </p>
      <Box sx={{ height: "16px" }}></Box>
      {isLoading && (
        <div className="loader-center">
          <Loader color={"black"} />
        </div>
      )}
      {!isLoading && !isError && attachments.data.data.totalRecord !== 0 && (
        <List
          dense
          sx={{
            width: "100%",
          }}
        >
          {attachments.data.data.responseData.map((attachment) => (
            <ListItems
              attachment={attachment}
              refetch={refetch}
              isMobile={isMobile}
            />
          ))}
        </List>
      )}
      {!isLoading && attachments.data.data.totalRecord === 0 && (
        <p className="empty-attachment-list">No Attachments</p>
      )}
      {!isLoading && isError && <div>No Attachments</div>}
    </div>
  );
}

export default AttachmentsList;

import React, { useState } from "react";

import { createAvtarName } from "../../../../../utils/helpers";
import Avatar from "../../../../../components/Avatar";
import ListBoardUser from "./ListBoardUser";

function BoardOwner({ params }) {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClose = (action) => {
    setAnchorEl(null);
  };
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  return (
    <>
      {open && (
        <ListBoardUser
          anchorEl={anchorEl}
          open={open}
          handleClose={handleClose}
          id={params.row.board_id}
          params={params}
        />
      )}
      <div className="d-flex align-items-center">
        <div onClick={handleClick} className="cursor-pointer">
          <Avatar
            // key={`user-avtar-${el.user_id}`}
            username={params.value}
            user_image={params.row.board_owner_image}
            title={params.value}
            className="user-avtar-dropdown user-avatar  mr-2"
            style={
              !params.row.board_owner_image && {
                bgcolor: params.row?.board_owner_profile_color?.background,
                color: params.row?.board_owner_profile_color?.text,
              }
            }
          >
            {createAvtarName(params.value)}
          </Avatar>
        </div>
        <p>{params.value}</p>
      </div>
    </>
  );
}

export default BoardOwner;

import Api from "./axios";

export const getMissingStatus = async({start_date,end_date})=>{
    console.log("missingStatus,",start_date,end_date)
    if(start_date){
        return await Api.get(`/missing_report?start_date=${start_date}&end_date=${end_date}`)
    }else{
        return await Api.get(`/missing_report`)
    }
    
}

import { Button, Divider, Menu, MenuItem, Typography } from "@mui/material";
import React from "react";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";

import DownArrow from "../../../../styles/svg/downArrow.svg";
import { useCustomSearchParams } from "../../../../hooks/useCustomSearchParams";
import { useResponsive } from "../../../../hooks/useResponsive";

function FilterArchive() {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [filterBoard, updateParams, removeSearchParam] =
    useCustomSearchParams("boards-type");
  const isMobile = useResponsive("down", "md");
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);
  return (
    <div>
      {isMobile ? (
        <>
          <Typography
            className={"workflow_steps pl-2"}
            sx={{
              backgroundColor: filterBoard == "all" && "#C5DCF3",
            }}
            onClick={() => updateParams("boards-type", "all")}>
            All
          </Typography>
          <Typography
            className={"workflow_steps pl-2"}
            sx={{
              backgroundColor: filterBoard == "active" && "#C5DCF3",
            }}
            onClick={() => updateParams("boards-type", "active")}>
            Active
          </Typography>
          <Typography
            className={"workflow_steps pl-2"}
            sx={{
              backgroundColor: filterBoard == "archive" && "#C5DCF3",
            }}
            onClick={() => updateParams("boards-type", "archive")}>
            Archive
          </Typography>
        </>
      ) : (
        <Button
          variant="outlined"
          color="primary"
          className="filter-btn white-button"
          onClick={handleClick}
          endIcon={<img src={DownArrow} />}
          sx={{
            height: "38px",
            // width:'80px',
            width: "135px",
            justifyContent: "space-between",
            "&:hover": {
              outline: "none",
              backgroundColor: "rgb(240 249 254)",
              border: "1px solid #1b3e61",
            },
          }}>
          <p className="mr-4 capitalize">{filterBoard || "All"}</p>
        </Button>
      )}
      <Menu
        id="account-menu"
        anchorEl={anchorEl}
        className="assign-board-owner-list"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        sx={{ padding: 0, paddingTop: 0, paddingBottom: 0 }}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            mt: 1.5,
            "&::before": {
              content: '""',
              display: "block",
              position: "absolute",
              top: 0,
              right: 14,
              width: 14,
              height: 14,
              bgcolor: "background.paper",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}>
        <MenuItem
          sx={{
            textTransform: "capitalize",
            color: "rgba(33, 43, 54, 1)",
            fontWeight: "400",
            fontSize: "14px",
          }}
          onClick={() => updateParams("boards-type", "all")}>
          All
        </MenuItem>
        <Divider style={{ marginBottom: "0px", marginTop: "0px" }} />
        <MenuItem
          sx={{
            textTransform: "capitalize",
            color: "rgba(33, 43, 54, 1)",
            fontWeight: "400",
            fontSize: "14px",
          }}
          onClick={() => updateParams("boards-type", "active")}>
          Active
        </MenuItem>
        <Divider style={{ marginBottom: "0px", marginTop: "0px" }} />
        <MenuItem
          sx={{
            textTransform: "capitalize",
            color: "rgba(33, 43, 54, 1)",
            fontWeight: "400",
            fontSize: "14px",
          }}
          onClick={() => updateParams("boards-type", "archive")}>
          Archive
        </MenuItem>
      </Menu>
    </div>
  );
}

export default FilterArchive;

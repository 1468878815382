import { localStorageInstance } from "./localStorage";

export function isAdmin() {
  const userData = localStorageInstance.getItem("role");
  return userData == "Super Admin";
}

export function currentUserId() {
  const userData = localStorageInstance.getItem("userId");
  return userData;
}

export const getAPIBaseUrl = () => {
  console.log(
    "originUrl",
    `${window.location.protocol}//api.${window.location.host}`
  );
  const url = `${window.location.protocol}//api.${window.location.host}`;
  return url;
};

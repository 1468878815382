import { useQuery } from "@tanstack/react-query";
import { getAllTeams, getTeams } from "../../sevices/apiTeam";

export function useTeams() {
  const {
    isLoading,
    data: teams,
    error,
    isError,
  } = useQuery({
    queryKey: ["teams"],
    queryFn: getAllTeams,
  });

  return { isLoading, error, teams, isError };
}

export function useAllTeams() {
  const {
    isLoading,
    data: teams,
    error,
    isError,
  } = useQuery({
    queryKey: ["all-teams"],
    queryFn: getTeams,
  });

  return { isLoading, error, teams, isError };
}
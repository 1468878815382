import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import { IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import RHFTextField from "../../RFH/RFHtextfield";
import { FormProvider, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { updateIsLock } from "../../../sevices/apiEpic";
import { useCustomSearchParams } from "../../../hooks/useCustomSearchParams";
import { useParams } from "react-router-dom";
import toast from "react-hot-toast";
import { useQueryClient } from "@tanstack/react-query";
import { useResponsive } from "../../../hooks/useResponsive";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

export default function ConfirmationLock({
  message,
  open,
  handleClose,
  callback,
  loading,
  title,
  btnTitle,
  is_locked,
  btnColor = "#1B3E61",
}) {
  const [milestone, setMilestone] = useCustomSearchParams("milestone");
  const { id } = useParams();
  const queryClient = useQueryClient();
  const isMobile = useResponsive("down", "md");

  const methods = useForm({
    resolver: yupResolver(
      yup.object({
        comment: yup.string().required("Comment is required"),
      })
    ),
  });

  const onSubmit = (formData) => {
    console.log("formData === ", formData);
    updateIsLock({
      epic_id: milestone,
      board_id: id,
      data: {
        is_locked: !is_locked,
        comment: formData.comment,
      },
    })
      .then((res) => {
        console.log("locked API === ", res);
        queryClient.invalidateQueries({
          queryKey: ["epic_id", milestone],
        });
        queryClient.invalidateQueries({
          queryKey: ["milestone_history", milestone],
        });
        callback();
        methods.setValue("comment", "");
        toast.success(res.data.message);
      })
      .catch((err) => {
        console.log("lock API error === ", err);
        toast.error(err.response.data.message);
      });
  };
  return (
    <React.Fragment>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        fullWidth
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
        sx={{
          height: "90vh",
          "& .MuiPaper-root": {
            borderRadius: "8px",
            maxWidth: "470px",
          },
        }}
      >
        <DialogTitle
          sx={{
            m: 0,
            p: isMobile ? "8px 10px !important" : 2,
            bgcolor: "rgba(242, 246, 254, 1)",
            fontSize: isMobile ? "16px" : "",
          }}
        >
          {title}
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 8,
            top: isMobile ? 5 : 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(onSubmit)}>
            <DialogContent className="isLocked">
              <DialogContentText
                id="alert-dialog-slide-description"
                className={isMobile ? "mobile-font" : ""}
              >
                {message}
                <RHFTextField
                  name="comment"
                  sx={{ marginTop: isMobile ? "" : "8px" }}
                  multiline
                  rows={4}
                />
              </DialogContentText>
            </DialogContent>
            <DialogActions
              sx={{ justifyContent: "center", mb: isMobile ? 1 : 3 }}
            >
              <Button
                // onClick={callback}
                disabled={loading}
                variant="contained"
                type="submit"
                sx={{
                  bgcolor: btnColor,
                  mr: "10px",
                  fontSize: "16px",
                  padding: isMobile ? "5px 18px" : "10px 16px",
                  fontWeight: "600",
                  textTransform: "capitalize",
                  minWidth: isMobile ? "" : "130px",
                }}
              >
                {btnTitle}
              </Button>
              <Button
                variant="outlined"
                sx={{
                  color: "#1B3E61",
                  border: "1px solid #1B3E61",
                  textTransform: "capitalize",
                  fontSize: "16px",
                  padding: isMobile ? "5px 16px" : "10px 16px",
                  fontWeight: "600",
                  opacity: "0.6",
                  minWidth: isMobile ? "" : "130px",
                }}
                onClick={handleClose}
                disabled={loading}
              >
                Cancel
              </Button>
            </DialogActions>
          </form>
        </FormProvider>
      </Dialog>
    </React.Fragment>
  );
}

import * as React from "react";
import Checkbox from "@mui/material/Checkbox";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

export default function CheckboxesTags({ options, setValue, errors }) {
  return (
    <Autocomplete
      multiple
      id="checkboxes-tags-demo"
      options={options || []}
      onChange={(event, newValue) => {
        // console.log("newValue", newValue);
        setValue("user_ids", newValue);
      }}
      disableCloseOnSelect
      getOptionLabel={(option) => `${option.first_name} ${option.last_name}`}
      renderOption={(props, option, { selected }) => {
        const { key, ...optionProps } = props;
        return (
          <li
            key={`${option.first_name}-${option.last_name}-${option.user_id}`}
            {...optionProps}
          >
            <Checkbox
              icon={icon}
              checkedIcon={checkedIcon}
              style={{ marginRight: 8 }}
              checked={selected}
            />
            {`${option.first_name} ${option.last_name}`}
          </li>
        );
      }}
      //   style={{ width: 500 }}
      renderInput={(params) => (
        <TextField
          {...params}
          label="Invite People to your Team"
          placeholder="Choose User"
          error={errors?.user_ids ? true : false}
          helperText={errors?.user_ids && errors.user_ids.message}
        />
      )}
    />
  );
}

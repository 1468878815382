import React, { useState } from "react";

import FormDialog from "../../../../../components/Dialog/FormDialog";
import KeyForm from "./KeyForm";

function KeyName({ params }) {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClose = (action) => {
    setAnchorEl(null);
  };
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  return (
    <>
      {open && (
        <FormDialog
          open={open}
          handleClose={handleClose}
          title={"Rename Board Key"}
          btnTitle={"Update"}
        >
          <KeyForm params={params} onClose={handleClose} />
        </FormDialog>
      )}
      <div onClick={handleClick} className="cursor-pointer">
        <p className="underline uppercase">{params.value}</p>
      </div>
    </>
  );
}

export default KeyName;

import React, { useState } from 'react'
import FormDialog from '../../../../../components/Dialog/FormDialog';
import WorkflowForm from './WorkflowForm';

function Workflow({ params }) {
     const [anchorEl, setAnchorEl] = useState(null);
     const open = Boolean(anchorEl);
     const handleClose = (action) => {
       setAnchorEl(null);
     };
     const handleClick = (event) => {
       setAnchorEl(event.currentTarget);
     };
  return (
    <>
      {open && (
        <FormDialog
          open={open}
          handleClose={handleClose}
          title={"Change Workflow"}
          btnTitle={"Update"}
        >
          <WorkflowForm params={params} onClose={handleClose} />
        </FormDialog>
      )}
      <div onClick={handleClick} className="cursor-pointer">
        <p className="underline capitalize">{params.value}</p>
      </div>
    </>
  );
}

export default Workflow
import React, { useState } from "react";

import { createAvtarName } from "../../../../../utils/helpers";
import Avatar from "../../../../../components/Avatar";
// import ListBoardUser from "./ListBoardUser";

function MissingStatusUser({ params }) {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClose = (action) => {
    setAnchorEl(null);
  };
  const handleClick = (event) => {
    // setAnchorEl(event.currentTarget);
  };
  // console.log("params",params)
  return (
    <>
      {/* {open && (
        <ListBoardUser
          anchorEl={anchorEl}
          open={open}
          handleClose={handleClose}
          id={params.row.board_id}
          params={params}
        />
      )} */}
      <div className="d-flex align-items-center" >
        <div onClick={handleClick} className="cursor-pointer">
          <Avatar
            // key={`user-avtar-${el.user_id}`}
            username={params.row.username}
            user_image={params.row.user_image}
            title={params.row.username}
            className="user-avtar-dropdown user-avatar  mr-2"
            style={
              !params.row.user_image && {
                bgcolor: params.row?.profile_color?.background,
                color: params.row?.profile_color?.text,
              }
            }
          >
            {createAvtarName(params.row.username)}
          </Avatar>
        </div>
        <p>{params.row.username}</p>
      </div>
    </>
  );
}

export default MissingStatusUser;

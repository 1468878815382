import React, { forwardRef } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css"; // Import the CSS for Quill editor

const MentionUserTextEditor = forwardRef(
  (
    { value, onChange, onMention, setEelementPros, minHeight, ...other },
    ref
  ) => {
    const handleChange = (content, delta, source, editor) => {
      const cursorPosition = editor.getSelection()?.index || 0;
      const text = editor.getText();

      // Detect @ character
      if (text[cursorPosition - 1] === "@") {
        setEelementPros({
          top: 62 + editor.getBounds(cursorPosition - 1).top,
          left: editor.getBounds(cursorPosition - 1).left,
        });
        onMention(cursorPosition, editor); // Trigger mention when '@' is typed
      }
      onChange(content);
    };

    return (
      <div style={{ minHeight: minHeight }}>
        <ReactQuill
          value={value}
          onChange={handleChange}
          ref={ref} // Forward ref to ReactQuill
          {...other}
        />
      </div>
    );
  }
);

export default MentionUserTextEditor;

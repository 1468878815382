import * as yup from "yup";

export const createMilestone = yup
  .object({
    epic_name: yup.string().required("Name is required"),
    description: yup.string().required("Description is required"),
    start_date: yup.date().max(
      yup.ref('target_release_date'),
      "start date can't be after target date"
    ),
    target_release_date: yup.date().min(
      yup.ref('start_date'),
      "end date can't be before start date"
    ),
  })
  .required();

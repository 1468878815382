import { useMutation, useQueryClient } from '@tanstack/react-query';
import React, { useState } from 'react'
import toast from 'react-hot-toast';
import ConfirmationDialog from '../../../../components/Dialog/ConfirmationDialog';
import { updateUser } from '../../../../sevices/apiUser';
import { objectToFormData } from '../../../../utils/helpers';

function Logs({ logRequired, params }) {
    const [status, setStatus] = useState(logRequired);
    const queryClient = useQueryClient();
    const [loading, setLoading] = useState(false);
    const [privatDialog, setPrivatDialog] = useState(false);

    const handleCloseDialog = () => {
      setPrivatDialog(false);
    };

    const { mutate } = useMutation({
      mutationFn: updateUser,
      onSuccess: (res) => {
       queryClient.invalidateQueries({
         queryKey: ["users"],
       });
        setStatus((data) => !data);
        setLoading(false);
        toast.success(res.data.message);
        handleCloseDialog();
      },
      onError: (error) => {
        console.error("Error User Management", error);
        toast.error(error.response.data.message);
        setLoading(false);
        handleCloseDialog();
      },
    });

    const callback = () => {
      const data = objectToFormData({ cardlog_required: !logRequired });
      setLoading(true);
      mutate({ id: params.row.user_id, data });
    };
  return (
    <>
      {privatDialog && (
        <ConfirmationDialog
          open={privatDialog}
          handleClose={() => setPrivatDialog(false)}
          message={
            logRequired
              ? `Are you sure you want to disable the worklog status? Disabling it will stop tracking your current work activities.`
              : "Are you sure you want to enable the worklog status?"
          }
          callback={callback}
          loading={loading}
          btnTitle={"Confirm"}
          title={"Change Worklog Status"}
        />
      )}
      <div className="w-100 d-flex justify-content-center">
        <label class="custom-checkbox">
          <input
            type="checkbox"
            checked={status}
            onClick={(event) => {
              setPrivatDialog(true);
            }}
          />
          <span class="checkmark"></span>
        </label>
      </div>
    </>
  );
}

export default Logs
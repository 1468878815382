import React from "react";
import toast from "react-hot-toast";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import attachFileIcon from "../../../../../../../styles/svg/attach-file.svg";
import addfile from "../../../../../../../styles/svg/add-file.svg";
import Button from "@mui/material/Button";
import "./AttachFile.scss";
import { createCardAttachment } from "../../../../../../../sevices/apiCards";
import AttachFileDialog from "./AttachFileDialog";
const AttachFile = ({ cardId, isIconBtn = false, isMobile = false }) => {
  const [loading, setLoading] = React.useState(false);
  const [openAttachFile, setOpenAttachFile] = React.useState(false);
  const queryClient = useQueryClient();
  const { mutate: createCardAttachmentMutate, isPending } = useMutation({
    mutationFn: createCardAttachment,
    onSuccess: (res) => {
      queryClient.invalidateQueries({
        queryKey: [`cardAttachment`, cardId],
      });
      setLoading(false);
      toast.success("Attachment added successfully");
      setOpenAttachFile(false);
    },
    onError: (error) => {
      console.error("Error Add Attachments", error);
      toast.error(error.response.data.message);
      setLoading(false);
    },
  });

  return (
    <>
      {openAttachFile && (
        <AttachFileDialog
          open={openAttachFile}
          close={setOpenAttachFile}
          cardId={cardId}
          createCardAttachmentMutate={createCardAttachmentMutate}
          isPending={isPending}
        />
      )}

      {isIconBtn ? (
        <div>
          <Button
            component="label"
            className="new-add-file"
            onClick={() => setOpenAttachFile(true)}
          >
            <img src={addfile} alt="" />
          </Button>
        </div>
      ) : (
        <Button
          variant="outlined"
          color="primary"
          className={`${
            isMobile ? "mobile-attach-btn " : ""
          }white-button d-flex justify-content-center align-items-center header-attach-btn`}
          component="label"
          onClick={() => setOpenAttachFile(true)}
        >
          <img
            src={attachFileIcon}
            className="header-filter-icon"
            alt="not found"
          />{" "}
          {isMobile ? "" : <span className="attach-btn-content">Attach</span>}
        </Button>
      )}
    </>
  );
};

export default AttachFile;

import {
  Box,
  Button,
  Grid,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Stack,
  Typography,
} from "@mui/material";
import React, { useRef, useState } from "react";
import Search from "../Search";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import Avatar from "../Avatar";
import UploadImage from "./uploadImage";
import { useQueryClient } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { localStorageInstance } from "../../utils/localStorage";
import { hasImage } from "../../utils/helpers";
import LogoutIcon from "../../styles/svg/tabler_logout.svg";
import userIcon from "../../styles/svg/users-icon-2.svg";
import workflowIcon from "../../styles/svg/workflow.svg";
import missingStatusIcon from "../../styles/svg/missing-status-report-icon.svg";
import boardsIcon from "../../styles/svg/boards-icon.svg";
import { useDispatch } from "react-redux";
import { searchBoard } from "../../redux/SearchSlice";
import gearIcon from "../../styles/svg/worklogIcon 2.svg";
import _ from "lodash";
import SettingsIcon from "@mui/icons-material/Settings";
import { isAdmin } from "../../utils/constants";
import { setActiveBoards } from "../../redux/ActiveBoardSlice";
import { changeExpand } from "../../redux/boardListSlice";
import { setSideBarDrawer } from "../../redux/Sidebar";

export default function Profile() {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const user = useSelector((state) => state.users.logInUser);
  const dispatch = useDispatch();
  const [inputValue, setInputValue] = useState("");
  // console.log("USER",user)
  const [showUpdateImage, setShowUpdateImage] = useState(true);
  const userName =
    Object.hasOwn(user, "first_name") &&
    `${user.first_name[0]}${user.last_name[0]}`.toUpperCase();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = (action) => {
    if (action === "logout") {
      localStorageInstance.clear();
      queryClient.clear();
      navigate(`/`);
    } else {
      setAnchorEl(null);
    }
  };
  const debouncedSearch = useRef(
    _.debounce((value) => {
      dispatch(searchBoard(value));
    }, 300) // 300ms delay
  ).current;

  const handleChange = (event) => {
    if (isAdmin) {
      dispatch(changeExpand("allPanel"));
    }
    const value = event.target.value;
    setInputValue(value);
    debouncedSearch(value);
  };

  //   const handleNavigate = (boardNumber)=>{
  //     dispatch(
  //       setAllGroupUser( [{ team: "Multiple", users: allUser }]
  //       )
  //     );
  //     dispatch(addAllBoard([]))
  //    const today = new Date();
  //    const sevenDaysAgo = new Date();

  //    // Subtract 7 days from the current date
  //    sevenDaysAgo.setDate(today.getDate() - 7);

  //    const date = {
  //      startDate: sevenDaysAgo,
  //      endDate: today,
  //      key: "selection",
  //    };

  //    // navigate("/my-task");
  //    const newSearchParams = new URLSearchParams({
  //      tab: "worklog",
  //      board: boardNumber,
  //      epic: epics,
  //      date: JSON.stringify(date)
  //    });
  //    // dispatch(setAllGroupUser([]));
  //    navigate(`/my-task?${newSearchParams.toString()}`);
  //    // newSearchParams.set(key, value);
  //    // setSearchParams(newSearchParams);
  //  }

  const handleNavigate = (boardNumber) => {
    const today = new Date();
    const yesterday = new Date();

    // Subtract 7 days from the current date
    yesterday.setDate(today.getDate() - 1);

    const date = {
      startDate: yesterday,
      endDate: yesterday,
      key: "selection",
    };

    // navigate("/my-task");
    const newSearchParams = new URLSearchParams({
      tab: "worklog",
      board: boardNumber,
      epic: null,
      missingStatus: JSON.stringify(date),
    });
    // dispatch(setAllGroupUser([]));
    navigate(`/admin-report?${newSearchParams.toString()}`);
    setAnchorEl(null);
    // newSearchParams.set(key, value);
    // setSearchParams(newSearchParams);
    dispatch(setSideBarDrawer(false));
  };
  return (
    <Grid item xs={5} className="">
      <div className="header-right">
        <Box mx={2} mb={2}>
          <Search
            searchText={inputValue}
            handleChange={handleChange}
            placeholder={"Find Board"}
          />
        </Box>
        {Object.hasOwn(user, "first_name") && (
          <Box mb={1}>
            <Stack direction="row" alignItems="center" ml={1}>
              <Button
                fullWidth
                id="user-profile-button"
                aria-controls={open ? "user-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={open ? "true" : undefined}
                onClick={handleClick}
                // endIcon={
                //   <KeyboardArrowDownIcon className="profile-down-arrow" />
                // }
                className="">
                <Avatar
                  title={`${user.first_name} ${user.last_name}`}
                  user_image={user?.user_image}
                  className="user-avatar"
                  style={
                    !hasImage(user?.user_image) && {
                      bgcolor: user?.profile_color?.background,
                      color: user?.profile_color?.text,
                    }
                  }>
                  {userName}
                </Avatar>
                <Typography className="user-name">
                  {user?.first_name} {user.last_name}
                </Typography>
                {/* <KeyboardArrowDownIcon
                  className="profile-down-arrow"
                  sx={{ color: "#919EAB", alignSelf: "right" }}
                /> */}
                <img
                  src={gearIcon}
                  style={{ width: "18px", color: "#1b3e61" }}
                  alt="ion"
                />
              </Button>
            </Stack>

            <Menu
              id="account-menu"
              className="profile-header"
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              // onClick={handleClose}
              PaperProps={{
                elevation: 0,
                sx: {
                  overflow: "visible",
                  filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                  mt: 1.5,
                  "& .MuiAvatar-root": {
                    width: 32,
                    height: 32,
                    ml: -0.5,
                    mr: 1,
                  },
                  "&::before": {
                    content: '""',
                    display: "block",
                    position: "absolute",
                    top: 0,
                    left: 14,
                    width: 14,
                    height: 14,
                    bgcolor: "background.paper",
                    transform: "translateY(-50%) rotate(45deg)",
                    zIndex: 0,
                  },
                },
              }}
              transformOrigin={{ horizontal: "left", vertical: "top" }}
              anchorOrigin={{ horizontal: "left", vertical: "bottom" }}>
              <MenuItem>
                {/* <Avatar
                      user_image={user?.user_image}
                      title={`${user?.first_name} ${user?.last_name}`}
                      className="user-avatar"
                      style={{
                        bgcolor: user?.profile_color?.background,
                        color: user?.profile_color?.text,
                      }}
                    >
                      {userName}
                    </Avatar> */}
                <Stack className="profile-container">
                  <UploadImage
                    user={user}
                    userName={userName}
                    setAnchorEl={setAnchorEl}
                  />

                  <ListItemText
                    primary={
                      <React.Fragment>
                        <Typography className="profile-dropdown-name">
                          {user.first_name} {user.last_name}
                        </Typography>
                      </React.Fragment>
                    }
                    secondary={
                      <React.Fragment>
                        <Typography className="profile-dropdown-email">
                          {user.email}
                        </Typography>
                      </React.Fragment>
                    }
                  />
                  <img
                    src={LogoutIcon}
                    alt="logout"
                    className="logout-icon"
                    onClick={() => handleClose("logout")}
                  />
                </Stack>
              </MenuItem>
              {/* <Divider /> */}
              {isAdmin() && (
                <MenuItem
                  onClick={() => {
                    navigate("/admin-user?users-status=active");
                    setAnchorEl(null);
                    dispatch(setSideBarDrawer(false));
                  }}>
                  <ListItemIcon sx={{ minWidth: "26px !important" }}>
                    <img src={userIcon} alt="user" />
                  </ListItemIcon>
                  Users
                </MenuItem>
              )}
              {isAdmin() && (
                <MenuItem
                  onClick={() => {
                    navigate("/admin-board?boards-type=active");
                    setAnchorEl(null);
                    dispatch(setSideBarDrawer(false));
                  }}>
                  <ListItemIcon sx={{ minWidth: "26px !important" }}>
                    <img src={boardsIcon} alt="board" />
                  </ListItemIcon>
                  Boards
                </MenuItem>
              )}
              {isAdmin() && (
                <MenuItem
                  onClick={() => {
                    navigate("/admin-workflow");
                    setAnchorEl(null);
                    dispatch(setSideBarDrawer(false));
                  }}>
                  <ListItemIcon sx={{ minWidth: "26px !important" }}>
                    <img src={workflowIcon} alt="workflow" />
                  </ListItemIcon>
                  Workflow
                </MenuItem>
              )}
              {isAdmin() && (
                <MenuItem onClick={() => handleNavigate()}>
                  <ListItemIcon sx={{ minWidth: "26px !important" }}>
                    <img src={missingStatusIcon} alt="missing status" />
                  </ListItemIcon>
                  Missing Status Report
                </MenuItem>
              )}
            </Menu>
          </Box>
        )}
      </div>
    </Grid>
  );
}

import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import { IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useResponsive } from "../../hooks/useResponsive";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

export default function FormDialog({
  open,
  children,
  handleClose,
  callback,
  loading,
  title,
  btnTitle,
  btnColor = "#1B3E61",
  width,
}) {
  const isMobile = useResponsive("down", "md");
  return (
    <React.Fragment>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        fullWidth
        keepMounted
        onClose={handleClose}
        onClick={(e) => e.stopPropagation()}
        aria-describedby="alert-dialog-slide-description"
        sx={{
          height: "90vh",
          "& .MuiPaper-root": {
            borderRadius: "8px",
            maxWidth: width ? width : "470px",
          },
        }}>
        <DialogTitle
          sx={{
            m: 0,
            p: isMobile ? "8px 10px" : 2,
            bgcolor: "rgba(242, 246, 254, 1)",
            fontSize: isMobile ? 16 : "inherit",
          }}>
          {title}
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 8,
            top: isMobile ? 5 : 8,
            color: (theme) => theme.palette.grey[500],
          }}>
          <CloseIcon />
        </IconButton>
        <DialogContent sx={{ padding: isMobile ? "14px 16px" : "20px 24px" }}>
          {children}
        </DialogContent>
      </Dialog>
    </React.Fragment>
  );
}

import {
  Box,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Slide,
  Stack,
  Tooltip,
  Typography,
  useScrollTrigger,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import EditTitle from "../EditTitle";
import checkMark from "../../../../../styles/svg/checkMark.svg";
import CheckBox from "../../../../../styles/svg/id-icon.svg";
import CustomizedProgressBars from "../../progressBar/ProgressBar";
import CustomDateFormatter from "../../dateTimeFormateConverter/dateTimeFormateConverter";
import AssignCard from "../AssignCard";
import { useSearchParams } from "react-router-dom";
import OpenCardDetail from "../openCardDetail";
import { useCustomSearchParams } from "../../../../../hooks/useCustomSearchParams";
import { useSelector } from "react-redux";
import { debounce } from "lodash";
import { useResponsive } from "../../../../../hooks/useResponsive";
import moment from "moment";
import mediumPriorityIcon from "../../../../../styles/svg/medium_priority.svg";
import highPriorityIcon from "../../../../../styles/svg/high_priority.svg";
import lowPriorityIcon from "../../../../../styles/svg/low_priority.svg";
import criticalPriorityIcon from "../../../../../styles/svg/critical_priority.svg";

function MobileKanban({ workflow, data, cards }) {
  const [selctedWorkflow, setSelectedWorkflow] = useState(1);
  const [projects, setProjects] = useState(cards);
  const [milestone, setMilestone] = useCustomSearchParams("milestone");
  const [searchParams, setSearchParams] = useSearchParams();
  const cardId = searchParams.get("card_id");
  const filterUser = searchParams.get("search");
  const epicSearch = searchParams.get("milestone");
  const noMileStoneSearch = searchParams.get("noMilestone");
  const searchText = useSelector((state) => state.search.searchText);
  const trigger = useScrollTrigger();
  const isMobile = useResponsive("down", "sm");

  useEffect(() => {
    const openStep = workflow
      .sort((a, b) => b.step_sequence - a.step_sequence)
      .find((item) => item.status === "Open" || item.status === "In-Progress");
    console.log("open step == ", openStep);

    if (openStep) {
      setSelectedWorkflow(openStep.workflow_step_id);
    } else {
      let selected = workflow.find((item) => item.step_sequence == 1);
      setSelectedWorkflow(selected.workflow_step_id);
    }
  }, []);

  const updateSearchParams = (key, value) => {
    const newSearchParams = new URLSearchParams(searchParams);
    newSearchParams.set(key, value);
    setSearchParams(newSearchParams);
  };

  const handleDetails = (id) => {
    updateSearchParams("card_id", id);
  };

  useEffect(() => {
    // Define the debounce delay (e.g., 300 milliseconds)
    const debounceDelay = 1000;
    let allFilteredCards = cards;

    // Create a debounced version of the filter function
    const debouncedFilter = debounce(() => {
      if (searchText) {
        allFilteredCards = allFilteredCards.filter(
          (el) =>
            (el?.card.title
              ? `${el.card.title}`.toLowerCase().includes(searchText)
              : "") ||
            (el.card.assigned_to_user?.first_name
              ? `${el.card.assigned_to_user?.first_name}`
                  .toLowerCase()
                  .includes(searchText)
              : "")
        );
        // console.log(filteredBySearch);
        setProjects(allFilteredCards);
      }
    }, debounceDelay);
    // console.log("allFilteredCards", allFilteredCards);
    if (filterUser) {
      allFilteredCards = allFilteredCards.filter(
        (el) => el.card.assigned_to_user_id == filterUser
      );
      // setProjects(filteredCardsByUser);
    }
    if (epicSearch) {
      console.log(
        "in epicSearch == ",
        allFilteredCards.filter((item) =>
          epicSearch.includes(item.card.epic_id)
        )
      );
      allFilteredCards = allFilteredCards.filter(
        (item) => epicSearch == item.card.epic_id
      );
    }

    if (noMileStoneSearch === "true") {
      allFilteredCards = allFilteredCards.filter(
        (item) => item.card.epic_id == null
      );
      console.log("FILTERED", allFilteredCards);
    }

    if (searchText) {
      // Call the debounced filter function whenever searchText, id, or cards change
      debouncedFilter();
    }

    setProjects(allFilteredCards);

    return () => {
      // Cleanup the debounce function on component unmount
      debouncedFilter.cancel();
    };
  }, [searchText, filterUser, epicSearch, cards, noMileStoneSearch]);

  const formatTimeFromNow = (createdDate) => {
    const newDate = moment.utc(createdDate);
    const localDate = newDate.local().toDate();
    // console.log("INPUTlocal1", createdDate, localDate);

    const now = moment();
    const diffInMinutes = now.diff(localDate, "minutes");
    const diffInHours = now.diff(localDate, "hours");
    const diffInDays = now.diff(localDate, "days");
    const diffInMonths = now.diff(localDate, "months");
    const diffInYears = now.diff(localDate, "years");
    if (diffInMinutes === 0) {
      return "just now";
    } else if (diffInMinutes < 60) {
      return `${diffInMinutes} Min${diffInMinutes === 1 ? "" : "s"} ago`;
    } else if (diffInHours < 24) {
      return `${diffInHours} Hr${diffInHours === 1 ? "" : "s"} ago`;
    } else if (diffInDays < 30) {
      return `${diffInDays} Day${diffInDays === 1 ? "" : "s"} ago`;
    } else if (diffInDays < 365) {
      return `${diffInMonths} Month${diffInMonths === 1 ? "" : "s"} ago`;
    } else {
      return `${diffInYears} Year${diffInYears === 1 ? "" : "s"} ago`;
    }
  };

  return (
    <Box pt={0}>
      {projects && cardId && <OpenCardDetail cardsOnBoard={projects} />}
      <Box
        sx={{
          position: "sticky",
          top: trigger
            ? milestone
              ? "169px"
              : "62px"
            : milestone
            ? "218px"
            : "111px",
          backgroundColor: "#fff",
          padding: "10px",
          boxShadow: "0 1px 5px -2px gray",
          zIndex: 2, // Ensure it stays above other content
        }}>
        <FormControl fullWidth variant="outlined" sx={{ position: "sticky" }}>
          <InputLabel>Select Workflow</InputLabel>
          <Select
            sx={{
              "& .MuiSelect-select": {
                padding: "10px",
                fontSize: "14px",
              },
            }}
            defaultValue=""
            label="Select Workflow"
            value={selctedWorkflow}
            onChange={(event) => setSelectedWorkflow(event.target.value)}>
            {workflow
              .sort((a, b) => a.step_sequence - b.step_sequence)
              .map((ele) => (
                <MenuItem
                  sx={{ padding: "8px", fontSize: "14px", minHeight: "24px" }}
                  key={ele.workflow_step_id}
                  value={ele.workflow_step_id}>
                  {ele.status}
                </MenuItem>
              ))}
          </Select>
        </FormControl>
      </Box>

      <Box
        sx={{
          backgroundColor: "#F2F6FE",
          display: "flex",
          flexWrap: "wrap",
          alignItems: "stretch",
        }}
        p={1}
        m={1}
        mt={1}
        overflow={"auto"}>
        {projects?.filter((ele) => ele.card.workflow_step_id == selctedWorkflow)
          .length == 0 && (
          <Stack
            minHeight={200}
            width="100%"
            justifyContent="center"
            alignItems="center">
            <p
              style={{
                border: "2px dashed",
                padding: "8px",
                borderRadius: "4px",
              }}>
              No card found
            </p>
          </Stack>
        )}
        {projects
          ?.filter((ele) => ele.card.workflow_step_id == selctedWorkflow)
          .map((ele, idx) => (
            <Box
              key={idx}
              onClick={() => handleDetails(ele.card.card_id)}
              sx={{
                flex: !isMobile && "1 1 45%",
                // margin: "10px",
                minWidth: "340px",
                flexGrow: 1,
                maxWidth: "550px",
                height: "auto",
                display: "flex",
              }}>
              <div
                className={
                  ele.card.parent_card_id && !ele.is_completed
                    ? "card-style-blur"
                    : "card-style"
                }
                // className="card-style"
              >
                <div className="d-flex align-items-center position_relative">
                  <AssignCard
                    tooltipName={"tooltipName"}
                    project={ele.card}
                    name={"name"}
                    accessForAssign={"accessForAssignOrEditTitle"}
                    cardId={ele?.card.card_id}
                  />
                  {ele.assigned_user_last_seen && (
                    <img
                      src={checkMark}
                      alt="checkMark"
                      className="cursor-pointer seen-mobile"
                    />
                  )}
                  <div style={{ marginLeft: "6px" }}>
                    <p className="card-id-number">
                      {ele.key}-{ele.card.card_id}
                    </p>
                    <p className="related_time">
                      {formatTimeFromNow(ele.card.date_created)}
                    </p>
                  </div>
                  {/* <img
                    className="priority_icon"
                    src={
                      ele?.card.priority === "Critical"
                        ? criticalPriorityIcon
                        : ele?.card.priority === "High"
                        ? highPriorityIcon
                        : ele?.card.priority === "Medium"
                        ? mediumPriorityIcon
                        : ele?.card.priority === "Low"
                        ? lowPriorityIcon
                        : ""
                    }
                    alt="priority"
                  /> */}
                </div>

                <div>
                  <div className="d-flex mt-2">
                    <Typography>{ele.card.title}</Typography>
                  </div>
                  {ele?.card?.epic && (
                    <div className="d-flex">
                      <div className="card-epic-tag mr-1">
                        <p className="card-epic-tag-text">
                          {ele?.card?.epic?.epic_name}
                        </p>
                      </div>
                    </div>
                  )}
                </div>
                <div>
                  <CustomizedProgressBars
                    allocatedHours={ele.card.allocated_hours}
                    hoursSpent={
                      ele?.total_hours_spent ? ele?.total_hours_spent : 0
                    }
                    showOnCardOnBoard={true}
                  />
                </div>
              </div>
            </Box>
          ))}
      </Box>
    </Box>
  );
}

export default MobileKanban;

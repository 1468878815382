import { useState } from "react";
import { Droppable } from "react-beautiful-dnd";

import KanbanCard from "./KanbanCard";
// import CreateCard from "./CreateCard";

function KanbanColumn({
  name,
  stage,
  projects,
  onDragEnter,
  onDragEnd,
  boardName,
  id,
  callback,
  maxHeight,
}) {
  const [mouseIsHovering, setMouseIsHovering] = useState(false);

  const preventing = (e) => {
    e.preventDefault();
  };
  console.log("projectcard", maxHeight);
  const generateKanbanCards = () => {
    return projects.map((project, index) => (
      <KanbanCard
        status={name}
        boardName={boardName}
        key={project.card.card_id}
        id={project.card.card_id}
        project={project}
        onDragEnd={onDragEnd}
        index={index}
        callback={callback}
      />
    ));
  };

  const columnStyle = {
    backgroundColor: mouseIsHovering ? "#f0eeee" : "rgba(242, 244, 246, 1)",
  };

  console.log("MAXHEIGHT", projects);

  return (
    <div className="card-column">
      <div className="coloumn-header">
        <h4 className="coloumn-header-h4">
          <p className="coloumn-header-text">{name}</p>
          <span className="coloumn-header-length">{projects.length}</span>
        </h4>
      </div>
      <div
        style={columnStyle}
        className={`coloumn-body${
          maxHeight > 0 && maxHeight < 4 ? `-${maxHeight}` : ""
        }`}
        onDragEnter={(e) => {
          setMouseIsHovering(true);
          onDragEnter(e, stage);
        }}
        onDragLeave={(e) => setMouseIsHovering(false)}
        onDragOver={preventing}
      >
        {/* <Droppable droppableId={id.toString()}>
          {(provided) => ( */}
        <div
          className={`coloumn-all-cards-task-page${
            maxHeight > 0 && maxHeight < 4 ? `-${maxHeight}` : ""
          }`}
          // ref={provided.innerRef}
          // {...provided.droppableProps}
        >
          {generateKanbanCards()}
          {/* <CreateCard stage={stage} /> */}
          {/* {provided.placeholder} */}
        </div>
        {/* )}
        </Droppable> */}
        <br />
        {/* <Button size="small">Create New</Button> */}
      </div>
    </div>
  );
}
export default KanbanColumn;

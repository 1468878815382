import React from 'react'
import ShowWorkflow from './showWorkflow/ShowWorkFlow'
import {isAdmin} from '../../../utils/constants'
import '../workflow/workflow.scss'
const index = () => {
  return (
    <>
    {isAdmin() ? (
        <>
          <ShowWorkflow/>
        </>
      ) : (
        <div>You cant access this page</div>
      )}
    </>
  )
}

export default index
import Api from "./axios";

export const getComments = async (card_id) => {
  return await Api.get(`/comments/${card_id}`);
};

export const addComment = async (data) => {
  return await Api.post(`/comment`, data, {
    headers: { "Content-Type": "application/x-www-form-urlencoded" },
  });
};

export const deleteComment = async (comment_id) => {
  return await Api.delete(`/comment/${comment_id}`);
};

export const updateComment = async (data) => {
  return await Api.put(`/comment/${data.comment_id}`, data, {
    headers: { "Content-Type": "application/x-www-form-urlencoded" },
  });
};

export const getCommentsBoard = async (board_id, milestoneId) => {
  return await Api.get(
    `/comments/${board_id}/board${
      milestoneId ? `?milestone_id=${milestoneId}` : ""
    }`
  );
};

import React, { useEffect, useState } from "react";
import { useWorkflowById } from "../../../../utils/reactQuery/workflows";
import Kanban from "../../components/KanbanBoard/Kanban";
import Loader from "../../../../components/Loader";
import { getUserCards } from "../../../../sevices/apiCards";
import Avatar from "../../../../components/Avatar";
import { createAvtarName } from "../../../../utils/helpers";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Chip,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import CalculateCards from "./CalculateCards";
import { useUserCards } from "../../../../utils/reactQuery/cards";
import { useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";

function UserBoard({ user, key, index, expandAll }) {
  // const expandAll = useSelector((state) => state.groupingUser.expanded);
  const { cards } = useUserCards(user.user_id);
  const [searchParams] = useSearchParams();
  const workflow_id = searchParams.get("workflow_id");
  const is_generic = searchParams.get("is_generic");
  const { data } = useWorkflowById(workflow_id, workflow_id);

  // const [cards, setCards] = useState([]);
  const [loading, setLoading] = useState(true);
  const [isUpdate, setUpdate] = useState(0);
  const [isExpand, setExpand] = useState(false);
  // useEffect(() => {
  //   getUserCards(user.user_id)
  //     .then((res) => {
  //       setCards(res.data.data.responseData);
  //       setLoading(false);
  //     })
  //     .catch((err) => {
  //       console.log("first");
  //       setLoading(false);
  //     });
  // }, [user, isUpdate]);

  useEffect(() => {
    setExpand(expandAll);
  }, [expandAll]);

  console.log(
    "cards.data.data.responseData.length",
    cards?.data?.data.responseData,
    data?.data?.data?.responseData.workflowStepId
  );
  const check = cards?.data?.data.responseData.some((item) =>
    data?.data?.data?.responseData.some(
      (el) => el.workflow_step_id == item.card.workflow_step_id
    )
  );
  console.log("check--", typeof is_generic, check);
  return (
    <>
      <div key={key}>
        <Accordion
          // defaultExpanded={index == 0}
          expanded={isExpand}
          className="accordion-allocation-board border-not-top"
          onChange={() => setExpand((val) => !val)}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1-content"
            id="panel1-header"
            sx={{ boxShadow: "none" }}
            className="accordian-summary-group-by-user"
          >
            <div className="justify-content-space-between d-flex w-100 align-items-center">
              <div className="d-flex">
                <Avatar
                  // key={`user-avtar-${el.user_id}`}
                  username={`${user.first_name} ${user.last_name}`}
                  user_image={user.user_image}
                  title={`${user.first_name} ${user.last_name}`}
                  className="user-avtar-dropdown user-avatar"
                  style={{
                    bgcolor: user?.profile_color?.background,
                    color: user?.profile_color?.text,
                  }}
                >
                  {createAvtarName(
                    `${user.first_name} ${user.last_name}` || ""
                  )}
                </Avatar>

                <p className="user-heading-name">
                  {user.first_name} {user.last_name}
                </p>
              </div>
              <div>
                {data && cards && (
                  <>
                    {" "}
                    <CalculateCards
                      cards={cards?.data?.data.responseData}
                      workflowSteps={data?.data?.data.responseData
                        .filter((el) => !el.is_completed)
                        .sort((a, b) => a.step_sequence - b.step_sequence)}
                      isExpand={isExpand}
                    />
                  </>
                )}
              </div>
            </div>
          </AccordionSummary>
          <AccordionDetails>
            {data && cards ? (
              cards.data.data.responseData.length > 0 &&
              (check || is_generic == "true") ? (
                <Kanban
                  cards={cards.data.data.responseData}
                  workflowSteps={data.data.data.responseData
                    .filter((el) => !el.is_completed)
                    .sort((a, b) => a.step_sequence - b.step_sequence)}
                  callback={() => setUpdate((data) => data + 1)}
                />
              ) : (
                <div className="d-flex justify-content-center align-items-center">
                  No cards available
                </div> // You can customize this message as needed
              )
            ) : (
              <div className="loader-center">
                <Loader color={"black"} />
              </div>
            )}

            {/* {data && cards ? (
              <Kanban
                cards={cards.data.data.responseData}
                workflowSteps={data.data.data.responseData
                  .filter((el) => !el.is_completed)
                  .sort((a, b) => a.step_sequence - b.step_sequence)}
                callback={() => setUpdate((data) => data + 1)}
              />
            ) : (
              <div className="loader-center">
                <Loader color={"black"} />
              </div>
            )} */}
          </AccordionDetails>
        </Accordion>
      </div>
    </>
  );
}

export default UserBoard;

import React, { useState } from "react";
import { MenuItem } from "@mui/material";
import Avatar from "../../../../../../components/Avatar";
import { differenceInDaysOrHours, hasImage,getInitials } from "../../../../../../utils/helpers";

function LastSeenUser({ user}) {
  const fullName = `${user?.user.first_name} ${user?.user.last_name}`
  return (
    <>
      <MenuItem
        key={`user-avtar-dropdown-${user.user_id}`}
      >
     
          <Avatar
            key={`user-avtar-${user.user_id}`}
            username={fullName}
            user_image={user.user.user_image}
            title={fullName}
            className="user-avtar-dropdown user-avatar mr-8"
            // style={!hasImage(user.user.user_image) && ({
            //   bgcolor: user?user?.profile_color?.background,
            //   color: user?.user?.profile_color?.text,
            // })}
          >
            {/* {createAvtarName(user.username)} */}
             {getInitials(user.user.first_name, user.user.last_name)}
          </Avatar>
          <div>
        {`${user.user.first_name} ${user.user.last_name}`}
        <p style={{fontWeight: '400px', fontSize: '14px', color: 'rgba(33, 43, 54, 0.6)'}}>{differenceInDaysOrHours(user.last_seen)}</p>
        </div>
      </MenuItem>
    </>
  );
}

export default LastSeenUser;

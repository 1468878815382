import React from "react";
import Checkbox from "@mui/material/Checkbox";
import MuiTable from "../../../../admin/board/components/BoardTable/MuiTbale";
import "./ImportCardMuiSelect.scss";
import { Box } from "@mui/material";

const ImportCardDataGrid = ({ data, setIsValidForSubmit }) => {
  console.log("DATAFORVAL", data);

  const validateRows = (rows) => {
    let allValid = true;

    const validatedRows = rows.map((row, index) => {
      // const title = row[0] || "";
      // const description = row[1] || "";
      // const allocatedHours = row[2] || "";
      const { title, description, allocated_hours } = row;
      let emptyTitle = true;
      let isTitleValid = true;
      // let isDescriptionValid = true;
      let isAllocatedHoursValid = true;

      const isTitleEmpty = title.trim() === "";
      const isTitleNumber = !isNaN(Number(title));
      // const isDescriptionNumber = description && !isNaN(Number(description));
      const isAllocatedHoursNotNumber = isNaN(Number(allocated_hours));

      if (isTitleEmpty) {
        emptyTitle = false;
        isTitleValid = false;
      }
      if (isTitleNumber) {
        isTitleValid = false;
      }
      // if (isDescriptionNumber) {
      //   isDescriptionValid = false;
      // }
      if (isAllocatedHoursNotNumber) {
        isAllocatedHoursValid = false;
      }

      const isValid = isTitleValid && isAllocatedHoursValid;
      if (!isValid) {
        allValid = false;
      }

      return {
        id: index + 1,
        title,
        description,
        allocatedHours: allocated_hours,
        isTitleValid,
        isAllocatedHoursValid,
        isValid,
        emptyTitle,
      };
    });

    setIsValidForSubmit(allValid);

    return validatedRows;
  };

  const rows = validateRows(data);

  const columns = [
    {
      field: "title",
      headerName: "Card Title",
      renderCell: (params) => {
        const row = params.row;
        let className = "";

        if (row.title.trim() === "") {
          className = "invalid-cell";
          return <div className={className}>Required</div>;
        }

        if (!row.isTitleValid) {
          className = "invalid-cell";
        }

        return <div className={className}>{params.value}</div>;
      },
      flex: 2.0,
      sortable: false,
      minWidth: 150,
    },

    {
      field: "description",
      headerName: "Description",
      renderCell: (params) => {
        const row = params.row;
        console.log("ROW", row);
        // const className = row.isDescriptionValid ? "" : "invalid-cell";
        return <div className="description-cell">{params.value}</div>;
      },
      flex: 3.0,
      sortable: false,
      minWidth: 250,
    },
    {
      field: "allocatedHours",
      headerName: "Allocated Hours",
      renderCell: (params) => {
        const row = params.row;
        const className = row.isAllocatedHoursValid ? "" : "invalid-cell";
        return (
          <div className={`${className} allocatedHours`}>
            <p>{params.value}</p>
          </div>
        );
      },
      flex: 2,
      sortable: false,
      minWidth: 100,
    },
  ];

  return (
    <>
      <Box sx={{ width: "100%" }}>
        <MuiTable
          initialRows={rows}
          columns={columns}
          pageSize={5}
          rowsPerPageOptions={[5]}
          defaultSort={"username"}
          headerBgColor={"#F2F4F6"}
          subHeight={330}
          // minRowHeight={"auto"}
        getRowHeight={() => "auto"}
      />
      </Box>
    </>
  );
};

export default ImportCardDataGrid;

import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { Alert, Box, Button, MenuItem, Stack, TextField } from "@mui/material";
import { useQueryClient, useMutation } from "@tanstack/react-query";
import { toast } from "react-hot-toast";

import Field from "../../../components/field/index";
import { schema } from "../../../schemas/createBoard";
import { useWorkflow } from "../../../utils/reactQuery/workflows";
import { useUsers } from "../../../utils/reactQuery/users";
import { createBoard } from "../../../sevices/apiBoards";
import Loader from "../../../components/Loader";
import { useNavigate } from "react-router-dom";
import { useResponsive } from "../../../hooks/useResponsive";

function Form({ onClose }) {
  const queryClient = useQueryClient();
  const {
    isLoading: isWorkflowLoading,
    workflows,
    isError: isWorkflowError,
  } = useWorkflow();
  const { isLoading: isUserLoading, users, isError: isUserError } = useUsers();
  const [workflowOption, setWorkFlowOption] = useState([]);
  const [userOption, setUserOption] = useState([]);
  const [apiError, setApiError] = React.useState("");
  const [loading, setLoading] = React.useState(false);
  const navigate = useNavigate();
  const isMobile = useResponsive("down", "md");

  const accessOption = [
    {
      label: "Private",
      value: true,
    },
    {
      label: "Public",
      value: false,
    },
  ];

  useEffect(() => {
    if (!isWorkflowLoading && !isWorkflowError) {
      setWorkFlowOption(
        workflows.data.data.responseData.map((el) => {
          return { label: el.workflow_name, value: el.workflow_id };
        })
      );
    }
  }, [isWorkflowLoading, workflows]);

  useEffect(() => {
    if (!isUserLoading && !isUserError) {
      let filteredArray = users.data.data.responseData
        .filter((el) => el.active)
        .sort(function (a, b) {
          return a.first_name.localeCompare(b.first_name);
        });
      setUserOption(
        filteredArray.map((el) => {
          return {
            label: `${el.first_name} ${el.last_name}`,
            value: el.user_id,
          };
        })
      );
    }
  }, [users, isUserLoading]);

  // React Hook Form
  const { register, handleSubmit, reset, getValues, formState } = useForm({
    defaultValues: {
      is_archive: false,
    },
    resolver: yupResolver(schema),
  });
  const { errors } = formState;

  const { mutate: createBoardMutate } = useMutation({
    mutationFn: createBoard,
    onSuccess: (res) => {
      // console.log("Board Created successfully", res);
      queryClient.invalidateQueries({
        queryKey: ["boards"],
      });
      setLoading(false);
      reset();
      toast.success("New board successfully created");
      onClose();
      navigate(`/board/${res.data.data.responseData.board_id}`);
    },
    onError: (error) => {
      console.error("Error Create Boards", error);
      toast.error(error.response.data.message);
      setLoading(false);
    },
  });

  const submit = (data) => {
    /**
     * Create Board
     */
    setApiError("");
    setLoading(true);
    // console.log(data);
    createBoardMutate(data);
  };

  return (
    <>
      <Box
        component="form"
        onSubmit={handleSubmit(submit)}
        noValidate
        sx={{
          // marginTop: 4,
          padding: isMobile ? "0px" : "14px",
          // borderRadius: "16px",
          // boxShadow: "0px 4px 30px 0px rgba(27, 62, 97, 0.06)",
        }}
      >
        <Stack spacing={3}>
          {apiError && <Alert severity="error">{apiError}</Alert>}
          {/* <Field
            label={"Board Name"}
            required
            id="board_name"
            name="board_name"
            error={errors?.board_name ? true : false}
            helperText={errors?.board_name && errors.board_name.message}
            {...register("board_name")}
          /> */}
          <TextField
            label={"Board Name"}
            required
            id="board_name"
            name="board_name"
            error={errors?.board_name ? true : false}
            helperText={errors?.board_name && errors.board_name.message}
            {...register("board_name")}
          />
          <Field
            label={"Board Workflow"}
            required
            id="workflow_id"
            name="workflow_id"
            error={errors?.workflow_id ? true : false}
            helperText={errors?.workflow_id && errors.workflow_id.message}
            select
            {...register("workflow_id")}
          >
            {workflowOption.map((option) => (
              <MenuItem
                key={`workflow-option-${option.value}`}
                id={`workflow-option-id-${option.value}`}
                value={option.value}
              >
                {option.label}
              </MenuItem>
            ))}
          </Field>
          <Field
            label={"Board Owner"}
            required
            id="owner_user_id"
            name="owner_user_id"
            error={errors?.owner_user_id ? true : false}
            helperText={errors?.owner_user_id && errors.owner_user_id.message}
            select
            {...register("owner_user_id")}
          >
            {userOption.length ? (
              userOption.map((option) => (
                <MenuItem
                  key={`user-option-${option.value}`}
                  id={`user-option-id-${option.value}`}
                  value={option.value}
                >
                  {option.label}
                </MenuItem>
              ))
            ) : (
              <div className="loader-center">
                <Loader color={"black"} />
              </div>
            )}
          </Field>
          <Field
            label={"Access"}
            required
            id="is_private"
            name="is_private"
            error={errors?.is_private ? true : false}
            helperText={errors?.is_private && errors.is_private.message}
            select
            {...register("is_private")}
          >
            {accessOption.map((option) => (
              <MenuItem
                key={`access-option-${option.value}`}
                id={`access-option-id-${option.value}`}
                value={option.value}
              >
                {option.label}
              </MenuItem>
            ))}
          </Field>
          <Box
            sx={{
              alignSelf: "end",
            }}
          >
            <Button
              type="submit"
              variant="contained"
              sx={{
                bgcolor: "#1B3E61",
                mr: "10px",
                fontSize: isMobile ? "14px" : "16px",
                padding: isMobile ? "5px 8px" : "10px 16px",
                fontWeight: "600",
                textTransform: "capitalize",
                minWidth: "130px",
              }}
              disabled={loading}
            >
              Create
            </Button>
            <Button
              variant="outlined"
              sx={{
                color: "#1B3E61",
                border: "1px solid #1B3E61",
                textTransform: "capitalize",
                fontSize: isMobile ? "14px" : "16px",
                padding: isMobile ? "5px 8px" : "10px 16px",
                fontWeight: "600",
                opacity: "0.6",
                minWidth: "130px",
              }}
              onClick={onClose}
              disabled={loading}
            >
              Cancel
            </Button>
          </Box>
        </Stack>
      </Box>
    </>
  );
}

export default Form;

import * as React from "react";
import { useState, useEffect } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import useMediaQuery from "@mui/material/useMediaQuery";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
import { useForm } from "react-hook-form";
import { InputLabel, MenuItem } from "@mui/material";
import TextField from "@mui/material/TextField";
import arrow from "../../../../../../styles/svg/arrow.svg";
import Field from "../../../../../../components/field";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import toast from "react-hot-toast";
import { updateCard } from "../../../../../../sevices/apiCards";
// import Select, { SelectChangeEvent } from "@mui/material/Select";
import { useTheme } from "@mui/material/styles";

import {
  useWorkflowStepNameByWorkflowStepId,
  useWorkflowById,
} from "../../../../../../utils/reactQuery/workflows";
import "./ChangeBoardToDifferentWorkflow.scss";
import { useParams, useSearchParams } from "react-router-dom";
import { useResponsive } from "../../../../../../hooks/useResponsive";
export default function ChangeBoardToDifferentWorkflow({
  open,
  close,
  card,
  currentBoardName,
  targetBoardName,
  workflowId,
  cardId,
  targetBoardId,
}) {
  const [currentWorkflowStepName, setCurrentWorkflowStepName] = useState(null);
  const [workflowSteps, setWorkflowSteps] = useState(null);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const { id } = useParams();
  const queryClient = useQueryClient();
  const isMobile = useResponsive("down", "md");
  const [searchParams, setSearchParams] = useSearchParams();
  const { data, refetch } = useWorkflowById(workflowId, workflowId);
  console.log("CARDDETAIL", workflowId);
  const { workflowStepName } = useWorkflowStepNameByWorkflowStepId(
    card?.data?.data?.responseData?.workflow_step_id,
    id
  );

  const {
    register,
    handleSubmit: updateBoardSubmit,
    watch,
    control,
    setValue,
    formState: { errors },
    getValues,
  } = useForm({
    targetStep: data?.data?.data?.responseData[0]?.workflow_step_id || "",
  });

  useEffect(() => {
    if (data) {
      setWorkflowSteps(data);
    }
  }, [workflowId, data]);

  useEffect(() => {
    if (card?.data?.data?.responseData?.workflow_step_id) {
      setCurrentWorkflowStepName(workflowStepName);
    }
  }, [card, workflowStepName]);

  const { mutate: updateMutate } = useMutation({
    mutationFn: updateCard,
    onSuccess: (res) => {
      const newSearchParams = new URLSearchParams(searchParams);
      newSearchParams.set("brd_id", targetBoardId);
      setSearchParams(newSearchParams);

      queryClient.invalidateQueries({
        queryKey: [`boards`],
      });
      queryClient.invalidateQueries({
        queryKey: [`cards`, id],
      });
      queryClient.invalidateQueries({
        queryKey: [`card`, cardId],
      });
      close(false);
      toast.success(res.data.message);
    },
    onError: (error) => {
      console.error("Error Delete Attachments", error);
    },
  });

  const onSubmit = (data) => {
    console.log("SubmitData", data);
    updateMutate({
      card_id: cardId,
      data: {
        board_id: targetBoardId,
        workflow_step_id: data.targetStep,
      },
    });
  };

  const handleClose = () => {
    close(false);
  };
  console.log("target", getValues("targetStep"));
  return (
    <React.Fragment>
      <Dialog
        sx={{
          "& .MuiDialog-container": {
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: "100vh !important",
            "& .MuiPaper-root": {
              //   marginTop: "15px",
              // width: "100%",
              // height: "100% !important",
              borderRadius: "8px",
              // margin: "15px auto",
              maxWidth: "494px",
            },
          },
        }}
        // fullScreen={fullScreen}
        open={open}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title">
        <DialogTitle
          id="responsive-dialog-title"
          className="move-ticket-dialog-title">
          {"Moving Ticket"}
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 10,
            top: isMobile ? 1 : 9,
            padding: "8px",
            color: (theme) => theme.palette.grey[500],
            "&:hover": {
              // backgroundColor: "rgba(242, 246, 254, 1)",
              // backgroung:'none',
              // color: "none",
              // transform: "none",
              // padding: "14px"
            },
          }}>
          <CloseIcon />
        </IconButton>
        <DialogContent className="move-ticket-dialog-content">
          <DialogContentText className="move-ticket-dialog-contentText">
            Modify the status of the task. This allow you to update the status
            to reflect any changes needed before transfering.
          </DialogContentText>
        </DialogContent>
        <form onSubmit={updateBoardSubmit(onSubmit)}>
          <DialogContent className="move-ticket-form-dialogContent">
            {/* <DialogContentText>
              <p className="ticket-reasign-step">Ticket Reassignment Step</p>
            </DialogContentText> */}

            <div
              className={
                isMobile
                  ? "mobile-ticket-reasign-step-container"
                  : "ticket-reasign-step-container"
              }>
              {/* <div style={{ display: "flex", flexDirection: "column" }}> */}
              <div
                className={
                  isMobile
                    ? "mobile-current-target-board-name"
                    : "current-target-board-name"
                }>
                <p> {currentBoardName} </p>
                <div className="initial-step">
                  <TextField
                    className="mui-status-field"
                    disabled
                    value={
                      currentWorkflowStepName?.data?.data?.responseData?.status
                    }
                  />
                </div>
              </div>
              {/* </div> */}
              <img src={arrow} alt="" />
              <div
                className={
                  isMobile
                    ? "mobile-current-target-board-name"
                    : "current-target-board-name"
                }>
                <p>{targetBoardName}</p>
                <div className="initial-step">
                  <Field
                    sx={{
                      width: isMobile ? "100%" : "180px",
                    }}
                    className="mui-status-field"
                    required
                    // value={getValues(`targetStep`)}
                    select
                    {...register(`targetStep`)}>
                    {workflowSteps?.data?.data?.responseData.map((option) => (
                      <MenuItem
                        key={`option.workflow_step_id`}
                        id={`option.workflow_step_id`}
                        value={option.workflow_step_id}>
                        {option.status}
                      </MenuItem>
                    ))}
                  </Field>
                </div>
              </div>
            </div>
          </DialogContent>

          <DialogActions
            sx={{
              display: "flex",
              justifyContent: "end",
              alignItems: "center",
              marginTop: "30px",
              marginBottom: "30px",
              padding: "0px 22px 0px 22px",
            }}
            className="move-ticket-dialogActions">
            <Button
              className="remove-step-btn-save"
              autoFocus
              variant="contained"
              //   disabled={isloading}
              onClick={updateBoardSubmit(onSubmit)}
              // type="submit"
            >
              <div className="btn-content">Update</div>
            </Button>
            <Button
              className="remove-step-btn-cancel"
              autoFocus
              variant="outlined"
              //   disabled={isloading}
              onClick={handleClose}
              // onClick={()=>close(false)}
            >
              <div className="btn-content">Cancel</div>
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </React.Fragment>
  );
}

import React from "react";
import { Controller, useFormContext } from "react-hook-form";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css"; // Import the CSS for Quill editor
import { removeHtmlTagsAndEntities } from "../../utils/helpers";

// ----------------------------------------------------------------------

export default function RHFTextEditor({ name, minHeight, ...other }) {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <div style={{ minHeight: "172px" }}>
          <ReactQuill
            value={field.value}
            onChange={(value) =>
              removeHtmlTagsAndEntities(value).length === 0
                ? field.onChange("")
                : field.onChange(value)
            }
            onBlur={field.onBlur}
            style={{ height: minHeight }}
            {...other}
          />
          {error && (
            <p
              style={{
                color: "#d32f2f",
                marginTop: "46px",
                fontWeight: "400",
                fontSize: "0.75rem",
                marginLeft: "14px",
              }}>
              {error.message}
            </p>
          )}
        </div>
      )}
    />
  );
}

import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  allocationUsers: [],
  expanded: false,
};

const grouping = createSlice({
  name: "grouping",
  initialState,
  reducers: {
    // SetGroupUser: {
    //   prepare(allocationUsers) {
    //     return {
    //       payload: { allocationUsers },
    //     };
    //   },
    //   reducer(state, action) {
    //     if (!state.allocationUsers.length)
    //       state.allocationUsers.push(action.payload.allocationUsers);
    //   },
    // },
    setAllGroupUser: {
      prepare(allocationUsers) {
        return {
          payload: { allocationUsers },
        };
      },
      reducer(state, action) {
        state.allocationUsers = action.payload.allocationUsers;
      },
    },
    setTeamGroupUser: {
      prepare(allocationUsers) {
        return {
          payload: { allocationUsers },
        };
      },
      reducer(state, action) {
        const isMultiple = state.allocationUsers.some(
          (el) => el.team == "Multiple"
        );
        const isTeamAlready = state.allocationUsers.some(
          (el) => el.team == action.payload.allocationUsers.team
        );
        if (isTeamAlready) {
          state.allocationUsers = state.allocationUsers.filter(
            (el) => el.team != action.payload.allocationUsers.team
          );
        } else {
          if (isMultiple) {
            const sameUserInCustomGroup = state.allocationUsers
              .find((el) => el.team == "Multiple")
              .users.some(
                (el) =>
                  el?.teams?.team_name == action.payload.allocationUsers.team
              );

            if (sameUserInCustomGroup) {
              state.allocationUsers = state.allocationUsers.filter(
                (el) => el.team != "Multiple"
              );
            }
          }
          state.allocationUsers.push(action.payload.allocationUsers);
        }
      },
    },
    setGroupUser: {
      prepare(allocationUsers) {
        return {
          payload: { allocationUsers },
        };
      },
      reducer(state, action) {
        // state.allocationUsers = action.payload.allocationUsers;
        const isTeam = state.allocationUsers.some(
          (el) => el.team == action.payload.allocationUsers.team
        );
        if (isTeam) {
          state.allocationUsers = state.allocationUsers.filter(
            (el) => el.team != action.payload.allocationUsers.team
          );
        } else {
          const availabeMultiple = state.allocationUsers.some(
            (el) => el.team == "Multiple"
          );

          if (availabeMultiple) {
            const isAdd = state.allocationUsers
              .find((el) => el.team == "Multiple")
              .users.some(
                (el) => el.user_id == action.payload.allocationUsers.user_id
              );
            isAdd
              ? (state.allocationUsers = [
                  ...state.allocationUsers.filter(
                    (el) => el.team != "Multiple"
                  ),
                  {
                    team: "Multiple",
                    users: state.allocationUsers
                      .find((el) => el.team == "Multiple")
                      .users.filter(
                        (el) =>
                          el.user_id != action.payload.allocationUsers.user_id
                      ),
                  },
                ])
              : (state.allocationUsers = [
                  ...state.allocationUsers.filter(
                    (el) => el.team != "Multiple"
                  ),
                  {
                    team: "Multiple",
                    users: [
                      ...state.allocationUsers.find(
                        (el) => el.team == "Multiple"
                      ).users,
                      action.payload.allocationUsers,
                    ],
                  },
                ]);
            // state.myTaskUser = action.payload.myTaskUser;
          } else {
            state.allocationUsers.push({
              team: "Multiple",
              users: [action.payload.allocationUsers],
            });
          }
        }
      },
    },
    changeGroupExpand: {
      prepare(expanded) {
        return {
          payload: { expanded },
        };
      },
      reducer(state, action) {
        state.expanded = action.payload.expanded;
      },
    },
  },
});

export const {
  setGroupUser,
  changeGroupExpand,
  setAllGroupUser,
  setTeamGroupUser,
} = grouping.actions;

export default grouping.reducer;

/*
export default function reducer(state = initialValues, action) {
  switch (action.type) {
    case "customer/createCustomer":
      return { ...state,name: action.payload.name , nationalId: action.payload.nationalId };
   
    default:
      return state;
  }
}

export const createAccount = function (name, nationalId) {
  return { type: "customer/createCustomer", payload: { name, nationalId } };
};
*/

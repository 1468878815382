import * as React from "react";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import Select from "@mui/material/Select";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useParams } from "react-router-dom";
import { updateCard } from "../../../../../sevices/apiCards";
import toast from "react-hot-toast";
import "./ChangeStatus.scss";
import {
  useWorkflowById,
  useWorkflowsSteps,
} from "../../../../../utils/reactQuery/workflows";
import { useCard } from "../../../../../utils/reactQuery/cards";
export default function ChangeStatus({
  currentStatusId,
  cardId,
  checkCondition,
  card,
}) {
  const [status, setStatus] = React.useState("");
  const [cachedData, setCachedData] = React.useState("");
  const queryClient = useQueryClient();
  const id = card?.data?.data?.responseData?.board_id;
  const { data } = useWorkflowById(
    card?.data?.data?.responseData?.board.workflow_id,
    id
  );

  React.useEffect(() => {
    if (id && data) {
      setCachedData(data);
    }
  }, [id, data]);

  React.useEffect(() => {
    setStatus(currentStatusId);
  }, [currentStatusId]);
  const { mutate: updateMutate } = useMutation({
    mutationFn: updateCard,
    onSuccess: (res) => {
      queryClient.invalidateQueries(["cards", id]);
      queryClient.invalidateQueries({
        queryKey: [`history`, cardId],
      });
      queryClient.invalidateQueries({
        queryKey: [`card`, cardId],
      });

      // setLoading(false);
      toast.success(res.data.message);
      //  handleCloseDialog();
    },
    onError: (error) => {
      console.error("Error Delete Attachments", error);
      // toast.error(error.response.data.message);
      // setLoading(false);
      //  handleCloseDialog();
    },
  });

  const handleChange = (event) => {
    updateMutate({
      card_id: cardId,
      data: { workflow_step_id: event.target.value },
    });
    setStatus(event.target.value);
  };

  const showStatus =
    cachedData &&
    cachedData?.data?.data?.responseData?.filter(
      (el) => el.workflow_step_id == currentStatusId
    )[0];
  // console.log("status123",showStatus?.status)
  return (
    <Box sx={{}}>
      {checkCondition ? (
        <FormControl size="small" className="card-status-dropdown-parent">
          {/* <InputLabel id="demo-simple-select-label">Status</InputLabel> */}
          <Select
            labelId="demo-simple-select-label"
            //   id="demo-simple-select"
            value={showStatus && status}
            //   label="Age"
            onChange={handleChange}
            className="card-status-dropdown">
            {cachedData &&
              cachedData?.data?.data?.totalRecord &&
              cachedData?.data?.data?.responseData.map((el, index) => (
                <MenuItem value={el.workflow_step_id}>{el.status}</MenuItem>
              ))}
          </Select>
        </FormControl>
      ) : (
        <div className="project-status d-flex justify-content-center align-items-center">
          <button className="project-status-button">
            {showStatus && showStatus?.status}
          </button>
          <div className="project-status-icon">
            {/* <KeyboardArrowDownIcon/> */}
          </div>
        </div>
      )}
    </Box>
  );
}

import * as React from "react";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Form from "../../../../attachments/Form";
import { useState } from "react";
import toast from "react-hot-toast";
import { useResponsive } from "../../../../../../../hooks/useResponsive";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

export default function AttachFileDialog({
  open,
  close,
  cardId,
  createCardAttachmentMutate,
  isPending,
}) {
  const [name, setName] = useState([{ name: "" }]);
  const [file, setFile] = useState([{ file: null }]);
  const isMobile = useResponsive("down", "md");
  const handleNameChange = (e, index) => {
    const newName = [...name];
    newName[index] = { name: e.target.value };
    setName(newName);
  };
  const handleFileChange = (e, index) => {
    console.log("FILES==", e.target.files);
    setFile([...e.target.files]);
  };

  const resetState = () => {
    setName([{ name: "" }]);
    setFile([{ file: null }]);
  };

  const handleSubmit = () => {
    // const formData = name.map((item, index) => ({
    //   name: item.name,
    //   file: file[index],
    // }));

    const formData = file.map((item, index) => ({
      name: name[index]?.name ?? item.name,
      file: file[index],
    }));

    console.log("CardFoemData", formData);

    const fileData = new FormData();
    fileData.append("card_id", cardId);

    const namesArray = formData.map((item) => item.name);
    const filesArray = formData.map((item) => item.file);

    fileData.append("names", namesArray.join(", "));
    filesArray.forEach((file) => {
      fileData.append("files", file);
    });
    if (formData[0].file.name) {
      createCardAttachmentMutate(fileData);
    } else {
      toast.error("Please upload file");
    }
  };
  console.log("OPEN", open);
  return (
    <React.Fragment>
      <BootstrapDialog
        onClose={() => {
          resetState();
          close(false);
        }}
        aria-labelledby="customized-dialog-title"
        open={open}
        sx={{
          "& .MuiDialog-container": {
            marginTop: !isMobile && "60px",
            "& .MuiPaper-root": {
              maxHeight: isMobile ? "100vh" : "80vh",
              borderRadius: isMobile ? "" : "12px",
              margin: isMobile ? "auto" : "15px auto",
            },
          },
        }}
      >
        <DialogTitle
          sx={{
            m: 0,
            p: 2,
            alignItems: "center",
            backgroundColor: "rgba(242, 246, 254, 1)",
            borderBottom: "1px solid rgba(27, 62, 97, 0.16)",
          }}
          id="customized-dialog-title"
        >
          AttachFile
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={() => {
            resetState();
            close(false);
          }}
          sx={(theme) => ({
            position: "absolute",
            right: 8,
            top: 8,
            color: theme.palette.grey[500],
          })}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent dividers>
          <Form
            file={file}
            name={name}
            handleNameChange={handleNameChange}
            handleFileChange={handleFileChange}
            isMobile={isMobile}
          />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleSubmit}
            disabled={isPending}
            variant="contained"
            type="submit"
            sx={{
              bgcolor: "#1b3e61",
              mr: "10px",
              fontSize: isMobile ? "14px" : "16px",
              padding: isMobile ? "6px 18px" : "10px 16px",
              fontWeight: "600",
              textTransform: "capitalize",
              minWidth: isMobile ? "" : "130px",
            }}
          >
            Save
          </Button>
        </DialogActions>
      </BootstrapDialog>
    </React.Fragment>
  );
}

// DatePickerComponent.jsx
import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { DatePicker } from '@mui/x-date-pickers';

const RFHdatePicker = ({ name }) => {
    const { control} = useFormContext();
  
    return (
      <Controller
        name={name}
        control={control}
        defaultValue={null}
        render={({ field, fieldState: { error } }) => (
          <DatePicker
          sx={{
            '& .MuiInputBase-root':{
              height: '42px !important',
              backgroundColor: '#fff',
              width: '100%'
            }
          }}
            slotProps={{
              textField: {
                fullWidth: true,
                variant: 'outlined',
                error: !!error,
                helperText: error?.message,
                style: { height: '40px !important',
                 }, // Adjust the height here
              },
            }}
            {...field}
          />
        )}
      />
    );
  };
  

export default RFHdatePicker;

import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  sideBarDrawer: false,
};

const sideBarDrawer = createSlice({
  name: "sideBarDrawer",
  initialState,
  reducers: {
    setSideBarDrawer: {
      prepare(sideBarDrawer) {
        return {
          payload: { sideBarDrawer },
        };
      },
      reducer(state, action) {
        state.sideBarDrawer = action.payload.sideBarDrawer;
      },
    },
  },
});

export const { setSideBarDrawer } = sideBarDrawer.actions;

export default sideBarDrawer.reducer;

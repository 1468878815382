import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import {
  Box,
  IconButton,
  InputAdornment,
  Stack,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import RHFTextField from "../RFH/RFHtextfield";
import { FormProvider, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { createMilestone } from "../../schemas/createMilestone";
import RHFTextEditor from "../RFH/RFHtexteditor";
import RFHdatePicker from "../RFH/RFHdatepPicker";
import RHFTimePicker from "../RFH/RFHtimePicker";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import { createEpic } from "../../sevices/apiEpic";
import { useParams } from "react-router-dom";
import moment from "moment";
import toast from "react-hot-toast";
import { useQueryClient } from "@tanstack/react-query";
import { useResponsive } from "../../hooks/useResponsive";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

export default function AddMilestone({
  message,
  open,
  handleClose,
  callback,
  loading,
  isEdit,
  title,
  btnTitle,
  btnColor = "#1B3E61",
}) {
  const { id } = useParams();
  const queryClient = useQueryClient();
  const isMobile = useResponsive("down", "md");
  const methods = useForm({
    resolver: yupResolver(createMilestone),
  });

  const onSubmit = (formData) => {
    console.log("formData === ", formData);
    createEpic({
      board_id: id,
      data: {
        ...formData,
        start_date: moment(formData.start_date).format("YYYY-MM-DD"),
        target_release_date: moment(formData.target_release_date).format(
          "YYYY-MM-DD"
        ),
      },
    })
      .then((res) => {
        console.log("create milestone == ", res);
        toast.success(res.data.message);
        queryClient.invalidateQueries({
          queryKey: [`epic`, id],
        });
        methods.reset();
        handleClose();
      })
      .catch((er) => {
        console.log("err create milestone == ", er);
        toast.error(er.response.data.message);
      });
  };
  return (
    <React.Fragment>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        fullWidth={!isMobile}
        fullScreen={isMobile}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
        sx={{
          height: "90vh",
          "& .MuiPaper-root": {
            borderRadius: isMobile ? "0px" : "8px",
            maxWidth: "613px",
            maxHeight: isMobile ? "500px" : "none",
          },
        }}>
        <DialogTitle
          sx={{ m: 0, p: 2, bgcolor: "rgba(242, 246, 254, 1)" }}
          className="create-milestone-title">
          {title}
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 8,
            top: isMobile ? 0 : 8,
            color: (theme) => theme.palette.grey[500],
          }}>
          <CloseIcon />
        </IconButton>
        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(onSubmit)}>
            <DialogContent
              className={isMobile ? "create-milestone-content" : ""}>
              <DialogContentText id="alert-dialog-slide-description">
                <Typography className="milestone-lable" mt={isMobile ? 1 : 2}>
                  Milestone Name<span className="star">*</span>
                </Typography>
                <RHFTextField name={"epic_name"} />

                <Typography className="milestone-lable" mt={isMobile ? 1 : 2}>
                  Description<span className="star">*</span>
                </Typography>
                <RHFTextEditor name={"description"} minHeight={"135px"} />
                <Stack
                  direction="row"
                  justifyContent={"space-between"}
                  spacing={2.5}
                  mt={isMobile ? "40px" : 2}>
                  <Box sx={{ width: "100%" }}>
                    <Typography className="milestone-lable">
                      Start Date
                    </Typography>
                    <RFHdatePicker name="start_date" />
                  </Box>
                  <Box sx={{ width: "100%" }}>
                    <Typography className="milestone-lable">
                      Target Date
                    </Typography>
                    <RFHdatePicker name={"target_release_date"} />
                  </Box>
                </Stack>
              </DialogContentText>
            </DialogContent>
            <DialogActions
              sx={{ justifyContent: "end", mb: 3, p: "16px 18px" }}
              // className={isMobile ? "justify-content-center" : ""}
            >
              <Button
                onClick={callback}
                disabled={loading}
                variant="contained"
                type="submit"
                sx={{
                  bgcolor: btnColor,
                  mr: "10px",
                  fontSize: isMobile ? "14px" : "16px",
                  padding: isMobile ? "6px 18px" : "10px 16px",
                  fontWeight: "600",
                  textTransform: "capitalize",
                  minWidth: isMobile ? "" : "130px",
                }}>
                {btnTitle}
              </Button>
              <Button
                variant="outlined"
                sx={{
                  color: "#1B3E61",
                  border: "1px solid #1B3E61",
                  textTransform: "capitalize",
                  fontSize: isMobile ? "14px" : "16px",
                  padding: isMobile ? "6px 18px" : "10px 16px",
                  fontWeight: "600",
                  opacity: "0.6",
                  minWidth: isMobile ? "" : "130px",
                }}
                onClick={handleClose}
                disabled={loading}>
                Cancel
              </Button>
            </DialogActions>
          </form>
        </FormProvider>
      </Dialog>
    </React.Fragment>
  );
}

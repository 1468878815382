import React from "react";
import { useWorkflowById } from "../../../../utils/reactQuery/workflows";
import GroupedTeam from "./GroupedTeam";

function Team({ groupingUser }) {
  const { data } = useWorkflowById(1, 528);

  return (
    <>
      {groupingUser.map((team, idx) =>
        team?.users.length ? (
          <GroupedTeam key={`grouped-team-${idx}`} team={team} data={data} />
        ) : (
          ""
        )
      )}
      {groupingUser.length == 0 && (
        <div className="loader-center">
          <p>Select User</p>
        </div>
      )}
    </>
  );
}

export default Team;

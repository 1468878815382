import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Menu,
  Stack,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import moment from "moment";
import { useSelector } from "react-redux";
import { debounce } from "lodash";
import { useParams, useSearchParams } from "react-router-dom";

import manageColumnIcon from "../../../../styles/svg/Vector.svg";
import MuiTable from "../../../admin/board/components/BoardTable/MuiTbale";
import { useWorkflowById } from "../../../../utils/reactQuery/workflows";
import { useCards } from "../../../../utils/reactQuery/cards";
import {
  removeHtmlTagsAndEntities,
  truncateText,
} from "../../../../utils/helpers";
import { dataGridColumn } from "../../../../components/dataGridColumn";
import OpenCardDetail from "../Kanban/openCardDetail";
import addIcon from "../../../../styles/svg/addIcon.svg";
import CreateCardDialogListview from "./CreateCardDialogListview";

export default function ListView({ workflowId, subHeight }) {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const { id } = useParams();
  const [columns, setColumns] = useState(dataGridColumn);
  const { cards } = useCards(id);
  const [searchParams, setSearchParams] = useSearchParams();
  const [selectedColumn, setSelectedColumn] = useState({
    col1: true,
    col2: true,
    col3: true,
    col4: true,
    col5: true,
    col6: true,
    col7: true,
    col8: true,
    col9: true,
    col10: true,
    col11: true,
    col12: true,
    col13: true,
  });
  const [projects, setProjects] = useState([]);
  const { data } = useWorkflowById(workflowId, id);
  const [openCreateCard, setOpenCreateCard] = useState(false);
  //Filters
  const filterUser = searchParams.get("search");
  const noMileStoneSearch = searchParams.get("noMilestone");
  const epicSearch = useSelector((state) => state.epic.searchEpic);
  const searchText = useSelector((state) => state.search.searchText);
  const cardId = searchParams.get("card_id");
  const statusSearch = searchParams.get("workflow_Step");
  const getCradStatus = (id) => {
    const tamp = data?.data?.data?.responseData.filter(
      (ele) => ele.workflow_step_id == id
    )[0]?.status;
    return tamp;
  };

  const handleSelectColumn = (slec) => {
    const temp = { ...selectedColumn };
    temp[slec] = !selectedColumn[slec];
    setSelectedColumn(temp);
  };

  const workflowSteps = data?.data?.data?.responseData;
  // console.log("workflowId", workflowSteps);

  //   const filterUserCards = filterUser ?  cards?.data.data.responseData?.filter(
  //     (el) => el.card.assigned_to_user_id == filterUser || epicSearch.includes(el.card.epic_id)
  //   ) :cards?.data.data.responseData;

  useEffect(() => {
    // Define the debounce delay (e.g., 300 milliseconds)
    const debounceDelay = 1000;
    let allFilteredCards = cards?.data.data.responseData;

    // Create a debounced version of the filter function
    const debouncedFilter = debounce(() => {
      if (searchText) {
        allFilteredCards = allFilteredCards?.filter(
          (el) =>
            (el?.card.title
              ? `${el.card.title}`.toLowerCase().includes(searchText)
              : "") ||
            (el.card.assigned_to_user?.first_name
              ? `${el.card.assigned_to_user?.first_name}`
                  .toLowerCase()
                  .includes(searchText)
              : "")
        );
        // console.log(filteredBySearch);
        setProjects(allFilteredCards);
      }
    }, debounceDelay);
    // console.log("allFilteredCards", allFilteredCards);
    if (filterUser) {
      allFilteredCards = allFilteredCards?.filter(
        (el) => el.card.assigned_to_user_id == filterUser
      );
      // setProjects(filteredCardsByUser);
    }
    if (epicSearch && epicSearch.length > 0) {
      allFilteredCards = allFilteredCards?.filter((item) =>
        epicSearch.includes(item.card.epic_id)
      );

      // setProjects(filterEpics);
    }

    if (noMileStoneSearch === "true") {
      allFilteredCards = allFilteredCards?.filter(
        (item) => item?.card.epic_id == null
      );
    }

    if (searchText) {
      // Call the debounced filter function whenever searchText, id, or cards change
      debouncedFilter();
    }

    if (statusSearch) {
      console.log("ALLFILTERCARDS", projects);
      allFilteredCards = allFilteredCards?.filter((item) => {
        // console.log("statusInList", item.card.workflow_step_id, statusSearch);
        return item.card.workflow_step_id == statusSearch;
      });
    }

    setProjects(allFilteredCards);

    return () => {
      // Cleanup the debounce function on component unmount
      debouncedFilter.cancel();
    };
  }, [
    searchText,
    filterUser,
    epicSearch,
    id,
    cards,
    statusSearch,
    noMileStoneSearch,
  ]);

  const handleClickOpen = () => {
    setOpenCreateCard(true);
  };

  return (
    <>
      {projects && cardId && <OpenCardDetail cardsOnBoard={projects} />}
      {openCreateCard && workflowSteps && (
        <CreateCardDialogListview
          open={openCreateCard}
          handleClose={() => setOpenCreateCard(false)}
          workflowSteps={workflowSteps}
        />
      )}
      <div>
        <Stack
          direction="row"
          className="d-flex justify-content-end align-items-center"
          mb={3}
        >
          <Button
            onClick={handleClickOpen}
            className="theme-bg-color capitalize mr-3"
            variant="contained"
            startIcon={<img src={addIcon} />}
            sx={{
              position: "static",
              "&:hover": {
                backgroundColor: "#1B3E61",
                border: "none",
                boxShadow: 0,
                // letterSpacing: "0",
              },
            }}
          >
            Create Card
          </Button>
          <Stack direction="row" justifyContent="end">
            <img
              src={manageColumnIcon}
              width={14}
              alt="manage column"
              style={{ cursor: "pointer" }}
            />
            <Typography
              className="manage-board"
              onClick={(event) => setAnchorEl(event.currentTarget)}
            >
              Manage Board Columns
            </Typography>
          </Stack>
        </Stack>
        <Box sx={{ height: "calc(94vh - 154px )" }}>
          <MuiTable
            subHeight={subHeight}
            initialRows={
              projects
                ? projects?.map((ele) => {
                    return {
                      id: ele?.card?.card_id,
                      col1: ele?.card?.card_id,
                      col2: ele?.card?.title,
                      col3: ele?.card?.workflow_step_id
                        ? getCradStatus(ele?.card?.workflow_step_id)
                        : "",
                      col5: `${ele?.card?.assigned_to_user?.first_name || ""} ${
                        ele?.card?.assigned_to_user?.last_name || ""
                      }`,
                      col6: ele?.card?.labels?.join(" "),
                      col7: ele?.card?.epic?.epic_name,
                      col8: ele?.card?.allocated_hours,
                      col9: ele?.total_hours_spent,
                      col10: {
                        hoursSpent: ele?.total_hours_spent,
                        allotedHours: ele?.card.allocated_hours,
                      },
                      col11: ele?.card?.due_date
                        ? moment(ele?.card?.due_date)
                            .local()
                            .format("MMMM D,YYYY")
                        : "",
                      col12: ele?.card?.date_created
                        ? moment(ele?.card?.date_created)
                            .local()
                            .format("MMMM D,YYYY")
                        : "",
                      col13: `${ele?.card.reported_by?.first_name || ""} ${
                        ele?.card?.reported_by?.last_name || ""
                      }`,
                    };
                  })
                : []
            }
            columns={columns.filter((ele) => selectedColumn[ele?.field])}
            getRowHeight={() => "auto"}
            padding="12px"
          />
        </Box>
        <Menu
          id="user-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={() => setAnchorEl(false)}
          MenuListProps={{
            "aria-labelledby": "epics-board-dropdown",
          }}
          PaperProps={{
            elevation: 0,
            sx: {
              overflow: "visible",
              filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
              mt: 1.5,
              "&::before": {
                content: '""',
                display: "block",
                position: "absolute",
                top: 0,
                right: 14,
                width: 14,
                height: 14,
                bgcolor: "background.paper",
                transform: "translateY(-50%) rotate(45deg)",
                zIndex: 0,
              },
            },
          }}
          transformOrigin={{ horizontal: "right", vertical: "top" }}
          anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
          className="assign-board-owner-list"
        >
          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox
                  checked={Object.values(selectedColumn).every(
                    (value) => value === true
                  )}
                  onChange={() =>
                    setSelectedColumn((prevObj) => {
                      // Create a new object with all values set to true
                      const newObj = Object.keys(prevObj).reduce((acc, key) => {
                        acc[key] = true;
                        return acc;
                      }, {});
                      return newObj;
                    })
                  }
                />
              }
              label="Select All"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={selectedColumn.col3}
                  onChange={() => handleSelectColumn("col3")}
                />
              }
              label="Card Status"
            />
            {/* <FormControlLabel
              control={
                <Checkbox
                  checked={selectedColumn.col4}
                  onChange={() => handleSelectColumn("col4")}
                />
              }
              label="Card Description"
            /> */}
            <FormControlLabel
              control={
                <Checkbox
                  checked={selectedColumn.col5}
                  onChange={() => handleSelectColumn("col5")}
                />
              }
              label="Assignee"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={selectedColumn.col6}
                  onChange={() => handleSelectColumn("col6")}
                />
              }
              label="Label"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={selectedColumn.col7}
                  onChange={() => handleSelectColumn("col7")}
                />
              }
              label="Epic"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={selectedColumn.col8}
                  onChange={() => handleSelectColumn("col8")}
                />
              }
              label="Alloted Hours"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={selectedColumn.col9}
                  onChange={() => handleSelectColumn("col9")}
                />
              }
              label="Hours Spent"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={selectedColumn.col10}
                  onChange={() => handleSelectColumn("col10")}
                />
              }
              label="Time Tracking"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={selectedColumn.col11}
                  onChange={() => handleSelectColumn("col11")}
                />
              }
              label="Due Date"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={selectedColumn.col12}
                  onChange={() => handleSelectColumn("col12")}
                />
              }
              label="Current Date"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={selectedColumn.col13}
                  onChange={() => handleSelectColumn("col13")}
                />
              }
              label="Reporter"
            />
          </FormGroup>
        </Menu>
      </div>
    </>
  );
}

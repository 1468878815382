import React from "react";
import TextField from "@mui/material/TextField";

function index({
  value,
  type,
  label,
  children,
  select,
  defaultValue,
  ...rest
}) {
  if (select)
    return (
      <TextField
        id="outlined-basic"
        fullWidth
        label={label}
        variant="outlined"
        select
        value={value}
        defaultValue={defaultValue}
        {...rest}
      >
        {children}
      </TextField>
    );

  return (
    <TextField
      id="outlined-basic-input"
      fullWidth
      label={label}
      variant="outlined"
      {...rest}
    />
  );
}

export default index;

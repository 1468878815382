import React from 'react'
import ListWorkFlow from './ListWorkFlow'
import HeaderWorkflow from './HeaderWorkflow'
const ShowWorkFlow = () => {
  return (
    <>
    <HeaderWorkflow/>
    <ListWorkFlow/>
   </>
  )
}

export default ShowWorkFlow
import React, { Component } from "react";
import icon from "../styles/svg/critical_priority.svg";
import { Button, Stack } from "@mui/material";

class ErrorBoundary extends Component {
  state = {
    hasError: false,
    errorMessage: "Oops! Something went wrong.", // Default message
    errorStack: "", // Keep the stack trace but won't show it to the user
  };

  static getDerivedStateFromError(error) {
    // Update state to show error message in the UI
    return {
      hasError: true,
      errorMessage: error.message,
      errorStack: error.stack,
    };
  }

  componentDidCatch(error, errorInfo) {
    // Log the error to an error tracking service (e.g., Sentry, LogRocket, etc.)
    console.log("componentDidCatch");
    console.error("Error caught by ErrorBoundary:", error, errorInfo);

    // Optionally: You can provide custom error handling or fallbacks here if needed
    if (!error.message) {
      this.setState({
        errorMessage: "Something unexpected happened. Please try again later.",
      });
    }
  }

  render() {
    if (this.state.hasError) {
      return (
        <Stack height={"80vh"} justifyContent={"center"} alignItems={"center"}>
          <div style={{ padding: "20px", textAlign: "center" }}>
            <img src={icon} alt="error" style={{ height: "140px" }} />
            <h2>Oops! Something went wrong.</h2>
            <p>{this.state.errorMessage}</p>
            <Button
              variant="outlined"
              color="error"
              sx={{ marginTop: "16px" }}
              onClick={() => window.location.reload()}>
              Reload Page
            </Button>
          </div>
        </Stack>
      );
    }

    // If no error, render child components
    return this.props.children;
  }
}

export default ErrorBoundary;

import {
  AvatarGroup,
  Button,
  Menu,
  MenuItem,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import { Base64 } from "js-base64";
import { useEffect, useState } from "react";
import { useParams, useSearchParams } from "react-router-dom";

import Avatar from "../../../../../../components/Avatar";
import { useDispatch } from "react-redux";
//   import { filterUser } from "../../../../../redux/usersSlice";
import { useSelector } from "react-redux";
import { Avatar as Image } from "@mui/material";
import UsersIcon from "../../../../../../styles/svg/users-icon.svg";
import LastSeenUser from "./LastSeenDropdown";
import {
  createAvtarName,
  hasImage,
  getInitials,
} from "../../../../../../utils/helpers";
// import { Image } from "@mui/icons-material";
function CardLastSeenDetail({ cardDetail }) {
  const [users, setUsers] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const [visitedUser, setVisited] = useState([]);
  const open = Boolean(anchorEl);
  // let visitedUser = []
  // const visitedUser = cardDetail?.read_receipt.map((item)=> item.last_seen != null)
  // useEffect(()=>{
  //   visitedUser = cardDetail?.read_receipt.map((item)=> item.last_seen != null)
  // },[cardDetail])
  useEffect(() => {
    // setUsers(cardDetail ? cardDetail?.read_receipt.filter((item)=> item.last_seen != null) : []);
    if (cardDetail) {
      setVisited(
        cardDetail?.read_receipt
          .filter((item) => item.last_seen != null)
          .sort((a, b) => new Date(b.last_seen) - new Date(a.last_seen))
      );
      setUsers(
        cardDetail?.read_receipt.sort(
          (a, b) => new Date(b.last_seen) - new Date(a.last_seen)
        )
      );
    }
  }, [cardDetail]);
  console.log("VISITED", users);
  const closeUserList = () => {
    setAnchorEl(null);
  };

  const isLessUsers = users?.length < 6 && users?.length >= 1 ? true : false;
  // console.log('readrecipt --- ',cardDetail);
  return (
    <>
      <Stack spacing={2} direction="row" className="align-items-center">
        <div className="d-flex justify-content-center align-items-center">
          <p className="mr-8" style={{ fontSize: "14px", fontWeight: "500" }}>
            Last seen:
          </p>
          <AvatarGroup
            total={users?.length}
            // onClick={(event) => setAnchorEl(event.currentTarget)}
            renderSurplus={(surplus) => (
              <span onClick={(event) => setAnchorEl(event.currentTarget)}>
                +{users?.length - 4}
              </span>
            )}
            className={`avatar-group cursor-pointer ${"avatar-group-border"}`}
          >
            {visitedUser?.map((el) => (
              <Avatar
                key={`user-avtar-${el.user_id}`}
                //   onClick={() => filterUserById(el.user_id)}
                username={`${el?.user?.first_name} ${el?.user?.last_name}`}
                user_image={el.user.user_image}
                title={`${el?.user?.first_name} ${el?.user?.last_name}`}
                //   className={header-avatar
                //     // el.user_id == filterUserId ? "active-avtar" : ""
                //   }
                style={
                  !hasImage(el.user_image)
                    ? {
                        bgcolor: el?.profile_color?.background,
                        color: el?.profile_color?.text,
                      }
                    : {
                        color: "white",
                        bgcolor: "white",
                      }
                }
              >
                {/* {createAvtarName(el?user?.username)} */}
                {getInitials(el.user.first_name, el.user.last_name)}
              </Avatar>
            ))}
          </AvatarGroup>
          {isLessUsers && (
            <Tooltip title={"User List"}>
              <Image
                onClick={(event) => setAnchorEl(event.currentTarget)}
                src={UsersIcon}
                className={"header-avatar cursor-pointer"}
              >
                AS
              </Image>
            </Tooltip>
          )}

          <Menu
            id="user-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={closeUserList}
            MenuListProps={{
              "aria-labelledby": "epics-board-dropdown",
            }}
            PaperProps={{
              elevation: 0,
              sx: {
                overflow: "visible",
                filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                mt: 1.5,
                "&::before": {
                  content: '""',
                  display: "block",
                  position: "absolute",
                  top: 0,
                  right: 14,
                  width: 14,
                  height: 14,
                  bgcolor: "background.paper",
                  transform: "translateY(-50%) rotate(45deg)",
                  zIndex: 0,
                },
              },
            }}
            transformOrigin={{ horizontal: "right", vertical: "top" }}
            anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
            className="assign-board-owner-list"
          >
            {users.map((el) => {
              return <LastSeenUser user={el} />;
            })}
          </Menu>
        </div>
      </Stack>
    </>
  );
}

export default CardLastSeenDetail;

import * as React from "react";
import PropTypes from "prop-types";
import Button from "@mui/material/Button";
import Avatar from "@mui/material/Avatar";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import DialogTitle from "@mui/material/DialogTitle";
import Dialog from "@mui/material/Dialog";
import PersonIcon from "@mui/icons-material/Person";
import AddIcon from "@mui/icons-material/Add";
import Typography from "@mui/material/Typography";
import { blue } from "@mui/material/colors";
import { createSvgIcon } from "@mui/material/utils";
import { useUsers } from "../../../../../utils/reactQuery/users";
import { Box, DialogContent, IconButton, TextField } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { styled, useTheme } from "@mui/material/styles";

import Loader from "../../../../../components/Loader";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useParams } from "react-router-dom";
import toast from "react-hot-toast";
import { assignUser } from "../../../../../sevices/apiBoards";
import ConfirmationDialog from "../../../../../components/Dialog/ConfirmationDialog";
import Form from "./Form";
import { localStorageInstance } from "../../../../../utils/localStorage";
import { isAdmin } from "../../../../../utils/constants";
import { useSelector } from "react-redux";
import { useResponsive } from "../../../../../hooks/useResponsive";

const emails = ["username@gmail.com", "user02@gmail.com"];

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

function AddUserDialog(props) {
  const { onClose, selectedValue, open, boardName, users } = props;

  const isMobile = useResponsive("down", "md");

  // const { isLoading: isUserLoading, users, isError: isUserError } = useUsers();
  // const { id } = useParams();
  // const queryClient = useQueryClient();
  // const [searchUser, setSearchUser] = React.useState([]);
  // const [search, setSearch] = React.useState("");
  // const [openConfirmation, setOpen] = React.useState("");
  // const [loading, setLoading] = React.useState(false);

  // console.log("users: add user", users);

  // const { mutate: assignUserMutate } = useMutation({
  //   mutationFn: assignUser,
  //   onSuccess: (res) => {
  //     queryClient.invalidateQueries({
  //       queryKey: ["users"],
  //     });
  //     //   setLoading(false);
  //     //    reset();
  //     toast.success("User Assign successfully ");
  //     //    onClose();
  //     handleClose();
  //   },
  //   onError: (error) => {
  // console.log(error);
  //     //   console.error("Error Delete Attachments", error);
  //     //   setLoading(false);
  //     //   handleClose();
  //   },
  // });

  const handleClose = () => {
    onClose(selectedValue);
  };

  // // function submit(value) {
  // //   setLoading(true);
  // //   console.log(value);
  // //   // assignUserMutate({ board_id: id, user_id: value.user_id });
  // //   // onClose(value);
  // // }

  // const handleListItemClick = (value) => {
  //   // setLoading(true);
  //   // console.log(value);
  //   assignUserMutate({ board_id: id, user_id: value.user_id });
  //   // onClose(value);
  // };

  // React.useEffect(() => {
  //   if (search) {
  //     const filterUser = users.data.filter((el) => el.email.includes(search));
  //     setSearchUser(filterUser);
  //   } else {
  //     setSearchUser(users?.data || []);
  //   }
  // }, [search]);

  // React.useEffect(() => {
  //   setSearchUser(users?.data || []);
  // }, [users]);

  // function handleCloseConfirmation() {
  //   setOpen(false);
  // }
  // console.log(boardName);

  return (
    <>
      <React.Fragment>
        <BootstrapDialog
          fullWidth
          aria-labelledby="responsive-dialog-title"
          onClose={handleClose}
          open={open}
          // fullScreen={fullScreen}
          sx={{
            height: "90vh",
            "& .MuiPaper-root": {
              borderRadius: "12px",
              maxWidth: "470px",
            },
          }}>
          <DialogTitle
            sx={{
              m: 0,
              p: isMobile ? "8px 12px !important" : 2,
              fontSize: isMobile && "16px",
              bgcolor: "rgba(242, 246, 254, 1)",
              fontWeight: isMobile ? "400" : "600",
              fontSize: "16px",
            }}
            id="responsive-dialog-title">
            Add user to {boardName?.data?.responseData?.board_name}
          </DialogTitle>
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: "absolute",
              padding: isMobile ? "0px !important" : "",
              right: isMobile ? "5px !important" : 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}>
            <CloseIcon />
          </IconButton>
          <DialogContent
            dividers
            className={isMobile ? "add-user-form" : "dialog-content-form"}>
            <Form
              onClose={handleClose}
              boardUsers={users}
              isMobile={isMobile}
            />
          </DialogContent>
        </BootstrapDialog>
      </React.Fragment>
    </>
  );
}

const PlusIcon = createSvgIcon(
  // credit: plus icon from https://heroicons.com/
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    strokeWidth={1.5}
    stroke="currentColor">
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M12 4.5v15m7.5-7.5h-15"
    />
  </svg>,
  "Plus"
);

export default function AddUsers({ boardName, ownerId, users }) {
  const [open, setOpen] = React.useState(false);
  const [selectedValue, setSelectedValue] = React.useState(emails[1]);
  const user = useSelector((state) => state.users.logInUser);
  const isMobile = useResponsive("down", "md");

  // console.log("UserData", user);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = (value) => {
    setOpen(false);
    setSelectedValue(value);
  };

  return (
    <div>
      {(isAdmin() || ownerId == user.user_id) &&
        (isMobile ? (
          <Button
            onClick={handleClickOpen}
            variant="contained"
            sx={{
              bgcolor: "#1B3E61",
              mr: "10px",
              fontSize: "13px",
              padding: "3px 16px",
              fontWeight: "400",
              margin: "auto",
              marginRight: "0px !important",
              textTransform: "capitalize",
              marginTop: "8px",
            }}
            fullWidth>
            Add User
          </Button>
        ) : (
          <Button
            onClick={handleClickOpen}
            sx={{ padding: 0, minWidth: 0, paddingTop: "2px" }}>
            <Avatar
              sx={{
                bgcolor: "#1B3E61",
                cursor: "pointer",
                width: 32,
                height: 32,
              }}>
              <PlusIcon />
            </Avatar>
          </Button>
        ))}

      {open && (
        <AddUserDialog
          boardName={boardName}
          selectedValue={selectedValue}
          open={open}
          onClose={handleClose}
          users={users}
        />
      )}
    </div>
  );
}

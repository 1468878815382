import { Chip } from "@mui/material";
import React from "react";

import plus from "../../../../styles/svg/group-plus-icon.svg";
import minus from "../../../../styles/svg/group-minus-icon.svg";

function CalculateCards({ cards, workflowSteps, isExpand }) {
  const statusCards = (status) => {
    const filteredCards = cards.filter(
      (el) => el.card.workflow_step_id == status
    );
    const remainingHour = filteredCards.reduce((accumulator, currentValue) => {
      if (currentValue.card.allocated_hours) {
        if (currentValue.total_hours_spent) {
          if (
            currentValue.card.allocated_hours >= currentValue.total_hours_spent
          ) {
            return (
              accumulator +
              (currentValue.card.allocated_hours -
                currentValue.total_hours_spent)
            );
          } else {
            return accumulator;
          }
        } else {
          return currentValue.card.allocated_hours + accumulator;
        }
      } else {
        return accumulator;
      }
    }, 0);

    return `${remainingHour} Hrs (${filteredCards.length}) `;
  };
  const calculateRemainingHours = (status) => {};
  return (
    <div className="d-flex align-items-center ">
      {workflowSteps.map((el, idx) => (
        <p
          key={`grouping-status-chips-${idx}`}
          className=" w-25 grouping-status-chips team-status-title"
        >{`${statusCards(el.workflow_step_id)}`}</p>
      ))}
      {/* <p className=" w-25 grouping-status-chips">{`${statusCards(1)}`}</p>
      <p className=" w-25 grouping-status-chips">{`${statusCards(2)}`}</p>
      <p className=" w-25 grouping-status-chips">{`${statusCards(3)}`}</p>
      <p className="grouping-status-chips w-25 ">{`${statusCards(4)}`}</p> */}
      <div className=" w-25 d-flex justify-content-end-important">
        {isExpand ? <img src={minus} /> : <img src={plus} />}
      </div>
    </div>
  );
}

export default CalculateCards;

import React, { useState } from "react";
import UserBoard from "./UserBoard";

function GroupedTeam({ team, data }) {
  const [expandAll, setExpandAll] = useState(false);
  return (
    <div className="mt-25 ">
      <div className="team-header d-flex align-items-center justify-content-space-between">
        <div>
          <p className="team-title">{team.team}</p>
        </div>
        <div className="d-flex">
          {data &&
            data.data.data.responseData
              .filter((el) => !el.is_completed)
              .sort((a, b) => a.step_sequence - b.step_sequence)
              .map((el, idx) => (
                <p
                  key={`status-header-${idx}`}
                  className="w-25 team-status-title uppercase"
                >
                  {el.status}
                </p>
              ))}
          {/* <p className="w-25">Triage</p>
            <p className="w-25">Open</p>
            <p className="w-25">ReOpen</p>
            <p className="w-25">Resolved</p> */}
          <div className="w-25 text-end">
            <label
              class="custom-checkbox"
              onClick={() => setExpandAll((data) => !data)}
            >
              <input
                type="checkbox"
                id={`expand-all-checkbox`}
                onClick={(event) => {
                  console.log("Paragraph clicked");
                  event.stopPropagation();
                }}
                checked={expandAll}
              />
              <span class="checkmark"></span>
              <p className="team-status-title">Expand-All</p>
            </label>
          </div>
        </div>
      </div>
      {team.users.map((user, idx) => (
        <UserBoard
          key={`Grouping-board-${idx}`}
          user={user}
          index={idx}
          data={data}
          expandAll={expandAll}
        />
      ))}
    </div>
  );
}

export default GroupedTeam;

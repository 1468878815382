import React from 'react'
import ListComment from '../showComments/ListComment';
import ListWorklog from '../../../worklog/listWorklog';
import HistoryItem from '../history/HistoryItem';

function Item({ item, cardId }) {
  return (
    <>
      {item.objType == "worklog" && (
        <ListWorklog data={item} cardId={cardId} showIcon={true} />
      )}
      {item.objType == "comment" && (
        <ListComment data={item} cardId={cardId} showIcon={true} />
      )}
      {item.objType == "history" && (
        <HistoryItem history={item} showIcon={true} />
      )}
    </>
  );
}

export default Item
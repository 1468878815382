import React from "react";
// import { useWorkflow } from '../../../../utils/reactQuery/workflows'
import WorkFlowTable from "./WorkFlowTable";
import { useResponsive } from "../../../../hooks/useResponsive";
import { Box } from "@mui/material";
const ListWorkFlow = () => {
  const isMobile = useResponsive("down", "md");

  return (
    <Box p={isMobile && 1} mt={isMobile && 1}>
      <WorkFlowTable />
    </Box>
  );
};

export default ListWorkFlow;

import { useSearchParams } from "react-router-dom";

export function useSetMultipleParams() {
  const [searchParams, setSearchParams] = useSearchParams();

  const setMultipleSearchParams = (paramsToRemove) => {
    // Create a copy of the current search params
    const newSearchParams = new URLSearchParams(searchParams);

    // Remove each parameter in the paramsToRemove array
    paramsToRemove.forEach((param) => {
      // newSearchParams.delete(param);
      newSearchParams.set(param.name, param.value);
    });

    // Set the updated search params
    setSearchParams(newSearchParams);
  };
  return [setMultipleSearchParams];
}

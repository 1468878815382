import * as yup from "yup";

export const createCheckpoint = yup
  .object({
    checkpoint_name: yup
      .string()
      .test(
        "len",
        "Checkpoint name must be between 1 and 30 characters long",
        (val) => val && val.length <= 30
      )
      .required("Name is required"),
    datetime: yup.string().required("Date is required"),
    is_done: yup.boolean(),
  })
  .required();

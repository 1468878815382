import React from "react";

import { useBoards } from "../../../../../utils/reactQuery/boards";
import Loader from "../../../../../components/Loader";
import MuiTable from "./MuiTbale";
import ActiveIcon from "../../../../../styles/svg/isarchive-table.svg";
import Status from "./Status";
import Accessibility from "./Accessibility";
import Delete from "./Delete";
import BoardOwner from "./BoardOwner";
import KeyName from "./KeyName";
import Workflow from "./Workflow";
import { useCustomSearchParams } from "../../../../../hooks/useCustomSearchParams";
import { Box } from "@mui/material";
import { useResponsive } from "../../../../../hooks/useResponsive";

function Table() {
  const { isLoading, boards, isError, error } = useBoards();
  const [filterBoard] = useCustomSearchParams("boards-type");
  const isMobile = useResponsive("down", "md");
  const data = boards?.data.data.responseData;
  const columns = [
    {
      field: "is_archive",
      headerName: "Status",
      minWidth: 85,
      renderHeader: (cell) => (
        <div className="w-100 d-flex justify-content-center">
          <img src={ActiveIcon} />
        </div>
      ),
      renderCell: (params) => {
        return <Status isArchive={!params.value} id={params.row.board_id} />;
      },
      flex: 0.6,
      sortable: false,
      headerClassName: "center-header",
    },

    {
      field: "board_name",
      headerName: "Board Name",
      minWidth: isMobile ? 250 : 300,
      flex: 1.5,
      // editable: true,
    },
    {
      field: "key",
      headerName: "Key",
      minWidth: 100,
      renderCell: (params) => {
        return <KeyName params={params} />;
      },
      flex: 0.5,
      sortable: false,
    },
    {
      field: "board_owner_name",
      headerName: "Board Owner",
      minWidth: 200,
      renderCell: (params) => {
        return <BoardOwner params={params} />;
      },
      // width: 200,
      flex: 1.2,
      // editable: true,
      sortable: false,
    },
    {
      field: "workflow_name",
      headerName: "Workflow",
      minWidth: isMobile && 110,
      renderCell: (params) => {
        return <Workflow params={params} />;
      },
      flex: 0.8,
      // editable: true,
      sortable: false,
    },
    {
      field: "is_private",
      headerName: "Public/Private",
      minWidth: 150,
      renderHeader: (cell) => (
        <div className="w-100 d-flex justify-content-center">
          <p className="size-14 text-color weight-500">Public/Private</p>
        </div>
      ),
      renderCell: (params) => {
        return <Accessibility isPrivate={params.value} params={params} />;
      },
      flex: 1,
      // editable: true,
      sortable: false,
      headerClassName: "center-header",
    },
    {
      field: "action",
      headerName: "Action",
      width: 100,
      renderCell: (params) => {
        return <Delete id={params.row.board_id} />;
      },
      sortable: false,
      // flex: 1,
      // editable: true,
    },
  ];

  const filteringBoard = () => {
    const boardArray = data.map((el, idx) => {
      return { ...el, id: idx };
    });
    if (filterBoard) {
      if (filterBoard == "all") return boardArray;
      if (filterBoard == "active") {
        return boardArray.filter((el) => !el.is_archive);
      } else {
        return boardArray.filter((el) => el.is_archive);
      }
    } else {
      return boardArray;
    }
  };
  // console.log("error", error);
  return (
    <>
      {isLoading && (
        <div className="loader-center">
          <Loader color={"black"} />
        </div>
      )}
      {!isLoading && isError && (
        <div className="loader-center">
          <p>Error</p>
        </div>
      )}
      {!isLoading && !isError && (
        <Box p={isMobile && 1} mt={isMobile && 1}>
          <MuiTable
            initialRows={filteringBoard()}
            columns={columns}
            defaultSort={"board_name"}
            headerBgColor={"#F2F4F6"}
          />
        </Box>
      )}
    </>
  );
}

export default Table;

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  List,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useState } from "react";

import SidebarListItem from "./SidebarListItem";
import IsReporter from "./IsReporter";
import AssignedBoardIcon from "../../../styles/svg/assigned-board.svg";
import { isAdmin } from "../../../utils/constants";

function AccordionNav({
  heading,
  icon,
  listing,
  defaultExpanded,
  uniqueKey,
  expanded,
  onChange,
}) {
  const [viewAssignedBoards, setAssigned] = useState(false);
  return (
    <Accordion
      expanded={expanded}
      onChange={onChange}
      sx={{ boxShadow: "none" }}
      defaultExpanded={defaultExpanded}
      className="menu-Accordion"
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1-content"
        id="panel1-header"
        className="menu-AccordionSummary"
      >
        <div className="d-flex align-items-center">
          <img src={icon} alt="Not Found" className="side-bar-icon"/>
          {<span className="navigation-heading">{heading}</span>}
        </div>
      </AccordionSummary>
      <AccordionDetails className="menu-AccordionDetails" key={uniqueKey}>
        {/* {isAdmin() && (
          // <div className="d-flex justify-content-space-between">
          //   <div className="d-flex align-items-center ml-30">
          //     <img src={AssignedBoardIcon} alt="Not Found" />
          //     {<span className="assigned-board">My Boards</span>}
          //   </div>
          //   <IsReporter
          //     viewAssignedBoards={viewAssignedBoards}
          //     setAssigned={(val) => setAssigned(val)}
          //   />
          // </div>
        )} */}

        {listing && listing.length ? (
          <List
            dense
            sx={{ width: "100%", maxWidth: 360, bgcolor: "background.paper" }}
          >
            {viewAssignedBoards
              ? listing
                  .filter((el) => el.is_super_admin_and_board_user)
                  .map((board) => (
                    <SidebarListItem key={board.board_id} board={board} />
                  ))
              : listing.map((board) => (
                  <SidebarListItem key={board.board_id} board={board} />
                ))}
          </List>
        ) : (
          <Typography
            variant="caption"
            gutterBottom
            className="text-center color-white d-flex justify-content-center pl-0"
            sx={{ marginLeft: "-16px" }}
          >
            Boards Not Found
          </Typography>
        )}
      </AccordionDetails>
    </Accordion>
  );
}

export default AccordionNav;

import { useQuery } from "@tanstack/react-query";
import { getFeeds } from "../../sevices/apiHistory";

export function useFeeds(id, page, pageSize, milestoneId) {
  const {
    isLoading,
    data: feeds,
    error,
    isError,
  } = useQuery({
    queryKey: [`feeds`, id, milestoneId],
    queryFn: () => getFeeds(id, page, pageSize, milestoneId),
  });

  return { isLoading, error, feeds, isError };
}

import Api from "./axios";

export const getHistory = async (card_id) => {
  return await Api.get(`/card-history/${card_id}`);
};

export const getFeeds = async (board_id, page, pageSize, milestoneId) => {
  return await Api.get(
    `/feeds/${board_id}?page=${page}&page_size=${pageSize}${
      milestoneId ? `&milestone_id=${milestoneId}` : ""
    }`
  );
};

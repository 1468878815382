import React from 'react'
import { useSearchParams } from 'react-router-dom'
import { useCustomSearchParams } from '../../hooks/useCustomSearchParams'

function CardId({id}) {
    const [value,setValue]=useCustomSearchParams('card_id')
  return (
    <div>
      <p className='card-anchor' onClick={()=>setValue('card_id',id)}>{id}</p>
    </div>
  )
}

export default CardId

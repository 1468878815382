
import React from 'react'
import { useComment } from '../../../../../../utils/reactQuery/cards';
import Loader from '../../../../../../components/Loader';
import { useHistory, useWorklog } from '../../../../../../utils/reactQuery/worklog';
import AllDetailList from './AllDetailList';

const AllDetail = ({ cardId }) => {
  const {
    isLoading: isCommentLoading,
    comment,
  } = useComment(cardId);
  const { isLoading: isHistoryLoading, history } = useHistory(cardId);
  const {
    isLoading: isWorklogLoading,
    worklog,
  } = useWorklog(cardId);
  return (
    <>
      {isCommentLoading || isHistoryLoading || isWorklogLoading ? (
        <div className="loader-center">
          <Loader color={"black"} />
        </div>
      ) : (
        <AllDetailList
          cardId={cardId}
          comments={
            comment?.data.data.responseData.map((el) => ({
              ...el,
              objType: "comment",
              consolidateTime: el.comment_time,
            })) || []
          }
          historys={
            history?.data.data.responseData.map((el) => ({
              ...el,
              objType: "history",
              consolidateTime: el.at_time,
            })) || []
          }
          worklogs={
            worklog?.data.data.responseData.map((el) => ({
              ...el,
              objType: "worklog",
              consolidateTime: el.worklog_time,
            })) || []
          }
        />
      )}
    </>
  );
};

export default AllDetail
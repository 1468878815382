import React from "react";
import FeedsHeader from "./FeedsHeader";
import HistoryItems from "./HistoryItems";
import "./Feeds.scss";
import { Divider } from "@mui/material";
const Feeds = () => {
  return (
    <>
      <FeedsHeader />
      <div className="worklog-content-div">
        <HistoryItems />
      </div>
    </>
  );
};

export default Feeds;

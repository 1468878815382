import Api from "./axios";

export const getAllTeams = async () => {
  return await Api.get(`/teams_with_users`);
};

export const getTeams = async () => {
  return await Api.get(`/teams`);
};

export const createTeam = async (data) => {
  return await Api.post(`/team`, data);
};
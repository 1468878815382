import { Box, Checkbox, Stack, Typography } from "@mui/material";
import React from "react";
import { useSearchParams } from "react-router-dom";
import { createAvtarName, hasImage } from "../../../../../utils/helpers";
import Avatar from "../../../../../components/Avatar";

function UsersMobile({ users }) {
  console.log("users == ", users);
  const [searchParams, setSearchParams] = useSearchParams();
  const filterUserId = searchParams.get("search");

  const updateSearchParams = (key, value) => {
    const newSearchParams = new URLSearchParams(searchParams);
    newSearchParams.set(key, value);
    setSearchParams(newSearchParams);
  };

  const filterUserById = (userId) => {
    console.log("is same === ", userId, filterUserId);
    if (userId == filterUserId) {
      updateSearchParams("search", "");
    } else {
      updateSearchParams("search", userId);
    }
  };

  return (
    <Box>
      {users.map((ele) => {
        return (
          <Stack
            direction="row"
            alignItems="center"
            spacing={1}
            onClick={() => filterUserById(ele.user_id)}
            bgcolor={
              ele.user_id == filterUserId ? "#C5DCF3" : "rgb(242, 244, 246)"
            }
            p={"5px"}
            borderRadius={1}
            mb={1}>
            <Avatar
              key={`user-avtar-${ele.user_id}`}
              username={ele.username}
              user_image={ele.user_image}
              title={ele.username}
              className={`header-avatar ${
                ele.user_id == filterUserId ? "active-avtar-mbl" : ""
              }`}
              style={
                !hasImage(ele.user_image) && {
                  bgcolor: ele?.profile_color?.background,
                  color: ele?.profile_color?.text,
                }
              }>
              {createAvtarName(ele.username)}
            </Avatar>
            <Typography fontSize={13} fontWeight={400}>
              {ele.username}
            </Typography>
            {/* <Checkbox
              checked={ele.user_id == filterUserId}
              sx={{ padding: "3px" }}
            /> */}
          </Stack>
        );
      })}
    </Box>
  );
}

export default UsersMobile;

import Api from "./axios";
import { objectToFormData } from "../utils/helpers";

export const getEpic = async (board_id) => {
  return await Api.get(`/epics/${board_id}`);
};

export const createEpic = async ({ board_id, data }) => {
  console.log("epic", data);
  return await Api.post(`/epic/${board_id}`, data, {
    headers: { "Content-Type": "application/x-www-form-urlencoded" },
  });
};

export const getUserBoardEpic = async (user_id) => {
  return Api.get(`/epics/${user_id}`);
};

export const updateEpic = async ({ epic_id, board_id, data }) => {
  return await Api.put(`/epic/${epic_id}/${board_id}`, data, {
    headers: { "Content-Type": "application/x-www-form-urlencoded" },
  });
};

export const getEpicThroughId = async (epic_id) => {
  return await Api.get(`/epic/${epic_id}`);
};

export const getCheckpoints = async (epic_id) => {
  return await Api.get(`/milestone/checkpoint/${epic_id}`);
};
export const addCheckpoint = async ({ epic_id, data }) => {
  return await Api.post(`/milestone/checkpoint/${epic_id}`, data, {
    headers: { "Content-Type": "application/x-www-form-urlencoded" },
  });
};

export const updateEpicDetails = async ({ epic_id, board_id, data }) => {
  return await Api.put(`/epic/${epic_id}/${board_id}`, objectToFormData(data));
};

export const updateIsLock = async ({ epic_id, board_id, data }) => {
  return await Api.put(`/lock_milestone/${epic_id}/${board_id}`, data, {
    headers: { "Content-Type": "application/x-www-form-urlencoded" },
  });
};

export const updateCheckpoint = async ({ checkpoint_id, data }) => {
  return await Api.put(`/milestone/checkpoint/${checkpoint_id}`, data, {
    headers: { "Content-Type": "application/x-www-form-urlencoded" },
  });
};

export const getMileStoneHistory = async (epic_id) => {
  return await Api.get(`/milestone-history/${epic_id}`);
};

export const deleteEpic = async (epic_id, board_id) => {
  return await Api.delete(`/epic/${epic_id}/${board_id}`);
};

import * as React from "react";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import SlideShow from "./slideShow/SlideShow";
import { downloadCardAttachment } from "../../../../../sevices/apiCards";
import useDownloader from "react-use-downloader";
import { getAPIBaseUrl } from "../../../../../utils/constants";
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

export default function SlideshowPopUp({
  slideImages,
  attachmentId,
  showAttachmentSlideShow,
  setShowAttachmentSlideShow,
}) {
  const getUrl = getAPIBaseUrl();
  const { size, elapsed, percentage, download, cancel, error, isInProgress } =
    useDownloader();

  const handleDownloadAttachment = (filename) => {
    console.log("DOWNLOAD...", filename);
    const getResponse = downloadCardAttachment(filename);

    getResponse
      .then((res) => {
        // console.log("getResponse", res.data);
        download(
          `${getUrl}/${res.data.data.responseData.url}`,
          `${res.data.data.responseData.filename}`
        );
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <React.Fragment>
      <BootstrapDialog
        onClose={() => setShowAttachmentSlideShow(false)}
        aria-labelledby="customized-dialog-title"
        open={showAttachmentSlideShow}
        // sx={{
        //   "& .MuiDialog-container": {
        //     "& .MuiPaper-root": {
        //       width: "100%",
        //       // height: "calc(100vh - 30px)",
        //       borderRadius: "12px",
        //       margin: '15px auto',
        //       maxWidth: "1180px",
        //     },
        //   },
        // }}
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          "& .MuiDialog-container": {
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
            maxWidth: "1180px",
            "& .MuiPaper-root": {
              width: "100%",
              borderRadius: "12px",
              margin: "15px",
              maxWidth: "1180px",
            },
          },
        }}
      >
        {/* <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
        </DialogTitle> */}
        <IconButton
          aria-label="close"
          onClick={(e) => {
            // e.stopPropagation();
            console.log("close clicked");
            setShowAttachmentSlideShow(false);
          }}
          sx={{
            position: "absolute",
            right: 0,
            top: 0,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent>
          {attachmentId && (
            <SlideShow
              slideImages={slideImages}
              attachmentId={attachmentId}
              handleDownloadAttachment={handleDownloadAttachment}
            />
          )}
        </DialogContent>
      </BootstrapDialog>
    </React.Fragment>
  );
}

import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  checkedIds: [],
};
const checkboxSclice = createSlice({
  name: "checkbox",
  initialState,
  reducers: {
    toggleCheckbox: (state, action) => {
      const id = action.payload;
      if (state.checkedIds.includes(id)) {
        state.checkedIds = state.checkedIds.filter((itemId) => itemId !== id);
      } else {
        state.checkedIds.push(id);
      }
    },
    clearCheckedIds: (state) => {
      state.checkedIds = [];
    },
  },
});

export const { toggleCheckbox, clearCheckedIds } = checkboxSclice.actions;
export default checkboxSclice.reducer;

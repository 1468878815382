import {
  Badge,
  Box,
  Grid,
  Typography,
  styled,
  Avatar as Muiavatar,
} from "@mui/material";
import React from "react";
import "./history.scss";
import Avatar from "../../../../../../components/Avatar";
import CustomDateFormatter from "../../../dateTimeFormateConverter/dateTimeFormateConverter";
import { createAvtarName, hasImage } from "../../../../../../utils/helpers";
import arrow from "../../../../../../styles/svg/arrow.svg";
import GetHeadShotName from "../../../../../../utils/getHeadShotName";
import HistoryIcon from "../../../../../../styles/svg/history-icon.svg";
import { useResponsive } from "../../../../../../hooks/useResponsive";

const SmallAvatar = styled(Muiavatar)(({ theme }) => ({
  width: 16,
  height: 16,
  border: `2px solid ${theme.palette.background.paper}`,
}));
function HistoryItem({ history, showIcon = false }) {
  // console.log("history?.username",history?.response_object?.user_object?.username)
  // const headShotName = GetHeadShotName(history?.response_object?.user_object?.username)
  const isMobile = useResponsive("down", "md");
  const isBetween300And699 = useResponsive("between", 300, 699);
  return (
    <>
      <Box
        sx={{
          flexGrow: 1,
          px: isMobile ? "" : 2,
          pl: isMobile ? 1 : "",
          width: isMobile ? "96%" : "400px",
        }}
      >
        <Grid container spacing={2}>
          <div
            className="worklog-box"
            style={{ width: isMobile ? "100%" : "" }}
          >
            <Grid>
              {showIcon ? (
                <Badge
                  anchorOrigin={{ vertical: "top", horizontal: "right" }}
                  badgeContent={
                    <SmallAvatar alt="Remy Sharp" src={HistoryIcon} />
                  }
                >
                  <Avatar
                    user_image={
                      history?.response_object?.user_object?.user_image
                    }
                    title={history?.response_object?.user_object?.username}
                    style={{
                      height: "32px",
                      width: "32px",
                      ...(!hasImage(
                        history?.response_object?.user_object?.user_image
                      ) && {
                        bgcolor:
                          history?.response_object?.user_object?.profile_color
                            ?.background,
                        color:
                          history?.response_object?.user_object?.profile_color
                            ?.background.text,
                      }),
                    }}
                    className={" header-avatar"}
                  >
                    {/* {headShotName || ''} */}
                    {createAvtarName(
                      history?.response_object?.user_object?.username || ""
                    )}
                  </Avatar>
                </Badge>
              ) : (
                <Avatar
                  user_image={history?.response_object?.user_object?.user_image}
                  title={history?.response_object?.user_object?.username}
                  style={{
                    height: "32px",
                    width: "32px",
                    ...(!hasImage(
                      history?.response_object?.user_object?.user_image
                    ) && {
                      bgcolor:
                        history?.response_object?.user_object?.profile_color
                          ?.background,
                      color:
                        history?.response_object?.user_object?.profile_color
                          ?.background.text,
                    }),
                  }}
                  className={" header-avatar"}
                >
                  {/* {headShotName || ''} */}
                  {createAvtarName(
                    history?.response_object?.user_object?.username || ""
                  )}
                </Avatar>
              )}
              {/* <Avatar
                title={tooltipName}
                className={" header-avatar worklog-box-img"}
                user_image={data.user_image}
              >
                {name}
              </Avatar> */}
              {/* <Avatar className="worklog-box-img">{data.user_image}</Avatar> */}
            </Grid>
            <div
              className="worklog-box-body"
              style={{ width: isMobile ? "100%" : "" }}
            >
              <div className="d-flex">
                <Grid sx={{ width: "100%" }}>
                  {/* <Typography className="worklog-box-name"> */}
                  <div className="d-flex justify-content-space-between">
                    <div
                      dangerouslySetInnerHTML={{ __html: history.history_text }}
                      className={isMobile ? "mobile-font" : ""}
                    ></div>
                    {isBetween300And699 ? (
                      ""
                    ) : (
                      <Typography className="worklog-box-date">
                        <div className="worklog-box-date-field">
                          <CustomDateFormatter date={history.at_time} />
                        </div>
                      </Typography>
                    )}
                  </div>
                  {/* </Typography> */}

                  <div>
                    {history.response_object && (
                      <>
                        {history.response_object?.event_type ==
                          "card_assign" && (
                          <div className="d-flex align-items-center history-heading">
                            {history.response_object?.old_assignee && (
                              <>
                                <div className=".history-img">
                                  <Avatar
                                    user_image={
                                      history.response_object?.old_assignee
                                        ?.user_image
                                    }
                                    className={"history-avatar mr-1"}
                                    title={
                                      history?.response_object?.old_assignee
                                        ?.username
                                    }
                                    style={
                                      !hasImage(
                                        history.response_object?.old_assignee
                                          ?.user_image
                                      ) && {
                                        bgcolor:
                                          history?.response_object?.old_assignee
                                            ?.profile_color?.background,
                                        color:
                                          history?.response_object?.old_assignee
                                            ?.profile_color?.background.text,
                                      }
                                    }
                                  >
                                    {createAvtarName(
                                      history.response_object?.old_assignee
                                        ?.username
                                    )}
                                  </Avatar>
                                </div>
                                <p
                                  className={isMobile ? "mobile-font" : "mr-4"}
                                >
                                  {
                                    history.response_object?.old_assignee
                                      ?.username
                                  }{" "}
                                  {isMobile ? (
                                    ""
                                  ) : (
                                    <img className="ml-4" src={arrow} alt="" />
                                  )}
                                </p>
                                {isMobile ? (
                                  <img
                                    className="ml-2 mr-2"
                                    src={arrow}
                                    alt=""
                                  />
                                ) : (
                                  ""
                                )}
                              </>
                            )}
                            {history.response_object?.new_assignee && (
                              <>
                                <div>
                                  <Avatar
                                    user_image={
                                      history.response_object?.new_assignee
                                        ?.user_image
                                    }
                                    className={"history-avatar mr-1"}
                                    title={
                                      history?.response_object?.new_assignee
                                        ?.username
                                    }
                                    style={
                                      !hasImage(
                                        history.response_object?.new_assignee
                                          ?.user_image
                                      ) && {
                                        bgcolor:
                                          history?.response_object?.new_assignee
                                            ?.profile_color?.background,
                                        color:
                                          history?.response_object?.new_assignee
                                            ?.profile_color?.background.text,
                                      }
                                    }
                                  >
                                    {createAvtarName(
                                      history.response_object?.new_assignee
                                        ?.username
                                    )}
                                  </Avatar>
                                </div>
                                <p
                                  className={isMobile ? "mobile-font" : "mr-4"}
                                >
                                  {
                                    history.response_object?.new_assignee
                                      ?.username
                                  }
                                </p>
                              </>
                            )}
                          </div>
                        )}
                        {history.response_object?.event_type ==
                          "change_status" && (
                          <div className="d-flex align-items-center history-heading">
                            <div className="old-status-box">
                              <p className="old-status">
                                {history.response_object?.old_status}
                              </p>
                            </div>
                            {<img className="arrow-img" src={arrow} alt="" />}
                            <div className="new-status-box">
                              <p className="new-status">
                                {history.response_object?.new_status}
                              </p>
                            </div>
                          </div>
                        )}
                        {history.response_object?.event_type ==
                          "change_board" && (
                          <div className="d-flex align-items-center history-heading">
                            <div className="old-status-box">
                              <p className="old-status">
                                {history.response_object?.old_board}
                              </p>
                            </div>
                            {<img className="arrow-img" src={arrow} alt="" />}
                            <div className="new-status-box">
                              <p className="new-status">
                                {history.response_object?.new_board}
                              </p>
                            </div>
                          </div>
                        )}
                      </>
                    )}
                  </div>
                </Grid>
                {/* <Grid>
                  <Typography className="worklog-box-date">
                    <div className="worklog-box-date-field">
                    <CustomDateFormatter date={history.at_time} />
                    </div>
                  </Typography>
                </Grid> */}
              </div>
              {isBetween300And699 ? (
                <Typography className="worklog-box-date">
                  <div>
                    <CustomDateFormatter date={history.at_time} />
                  </div>
                </Typography>
              ) : (
                ""
              )}
            </div>
          </div>
        </Grid>
      </Box>
    </>
  );
}

export default HistoryItem;

import { useSearchParams } from "react-router-dom";

export function useRemoveMultipleParams() {
  const [searchParams, setSearchParams] = useSearchParams();

  const removeSearchParams = (paramsToRemove) => {
    // Create a copy of the current search params
    const newSearchParams = new URLSearchParams(searchParams);

    // Remove each parameter in the paramsToRemove array
    paramsToRemove.forEach((param) => {
      newSearchParams.delete(param);
    });

    // Set the updated search params
    setSearchParams(newSearchParams);
  };

  return [removeSearchParams];
}

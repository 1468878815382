import React, { useState } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import { useBoardName } from "../../utils/reactQuery/boards";
import BoradHeader from "./components/BoardHeader/BoradHeader";
import Workflows from "./components/Kanban/Workflows";
import { useDispatch } from "react-redux";
import { localStorageInstance } from "../../utils/localStorage";
import { useLoginUser } from "../../utils/reactQuery/users";
import { user } from "../../redux/usersSlice";
import Loader from "../../components/Loader";
import { useSelector } from "react-redux";
import { Box } from "@mui/material";
import ListView from "./components/listView";
import { useCustomSearchParams } from "../../hooks/useCustomSearchParams";
import MilestoneSection from "./components/MilestoneSection";
import { useResponsive } from "../../hooks/useResponsive";

function BoardPage() {
  const userId = localStorageInstance.getItem("userId");
  const { data, isLoading } = useLoginUser(userId);
  const { id } = useParams();
  const [milestone, setMilestone] = useCustomSearchParams("milestone");
  const isMobile = useResponsive("down", "md");
  const { boardName } = useBoardName(id);
  const dispatch = useDispatch();
  console.log("BOARDNAME==", boardName?.data?.data?.responseData?.workflow_id);

  if (data) {
    dispatch(user(data.data.data.responseData));
  }
  const gridView = useSelector((state) => state.search.gridView);
  return (
    <>
      {isLoading ? (
        <div className="loader-center">
          <Loader color={"black"} />
        </div>
      ) : (
        <>
          {boardName?.data?.data?.responseData?.workflow_id && (
            <BoradHeader
              boardName={boardName?.data}
              boardId={id}
              ownerId={boardName?.data?.data?.responseData?.owner_user_id}
              workflowId={boardName?.data?.data?.responseData?.workflow_id}
            />
          )}

          <div
            style={{
              marginTop: milestone
                ? isMobile
                  ? "0px"
                  : "150px"
                : isMobile
                ? "0px"
                : "65px",
            }}
          >
            {/* {milestone && <MilestoneSection boardName={boardName}/>} */}
            {boardName?.data?.data?.responseData?.workflow_id &&
              (gridView ? (
                <Workflows
                  boardId={id}
                  workflowId={boardName?.data?.data?.responseData?.workflow_id}
                  boardName={boardName?.data?.data?.responseData}
                />
              ) : (
                <Box mt={milestone ? 3 : 7.5}>
                  <ListView
                    workflowId={
                      boardName?.data?.data?.responseData?.workflow_id
                    }
                    subHeight={"210"}
                  />
                </Box>
              ))}
          </div>
        </>
      )}
    </>
  );
}

export default BoardPage;

import React from "react";
import Header from "./components/Header";
import GroupingBoards from "./components/GroupingBoards";
import { useSelector } from "react-redux";
import { Button, Stack } from "@mui/material";
import icon from "../../../styles/svg/critical_priority.svg";

function AllocationGrouping() {
  const userTeam = useSelector((state) => state.users.logInUser.team_id);
  if (!userTeam) {
    // console.log("team is ", userTeam);
    // throw new Error("You have not been assigned to any team.");
    return (
      <Stack height={"80vh"} justifyContent={"center"} alignItems={"center"}>
        <div style={{ padding: "20px", textAlign: "center" }}>
          <img src={icon} alt="error" style={{ height: "140px" }} />
          <h2>You have not been assigned to any team.</h2>
        </div>
      </Stack>
    );
  }

  return (
    <div>
      <Header />
      <GroupingBoards />
    </div>
  );
}

export default AllocationGrouping;

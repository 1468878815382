import React, { useState } from "react";
import { useUsers } from "../../../../utils/reactQuery/users";
import Avatar from "../../../../components/Avatar";
import ConfirmationDialog from "../../../../components/Dialog/ConfirmationDialog";
import { createAvtarName, hasImage } from "../../../../utils/helpers";
import { isAdmin } from "../../../../utils/constants";
import { AvatarGroup, Menu, MenuItem } from "@mui/material";
import { Avatar as Image } from "@mui/material";
import { useDispatch } from "react-redux";
import { setMyTaskUser } from "../../../../redux/SearchSlice";
import SearchBoard from "../Filters/SearchBoard";
import { useResponsive } from "../../../../hooks/useResponsive";

function SelectUser({ user }) {
  // const [user, setUser] = useState(currentUser);
  const [anchorEl, setAnchorEl] = useState(null);
  const dispatch = useDispatch();
  const [searchQuery, setSearchQuery] = useState("");
  const isMobile = useResponsive("down","md")
  //    const [users, setUsers] = useState([]);
  // const [openDialog, setOpenDialog] = useState(false);
  // const [loading, setLoading] = useState(false);
  // const [userId, setUserId] = useState(null);
  const open = Boolean(anchorEl);
  const { users } = useUsers();

  const handleClick = (event) => {
    //   if (isAdmin())
    setAnchorEl(event.currentTarget);
  };
  const handleClose = (action) => {
    setAnchorEl(null);
  };

  const filteredItems = users
    ? searchQuery
      ? users.data.data.responseData.filter((item) =>
          item.first_name.toLowerCase().includes(searchQuery.toLowerCase())
        )
      : users.data.data.responseData
    : [];

  const selectAll = () => {
    const allUser = filteredItems.filter((el) => el.active);
    dispatch(setMyTaskUser([]));
  };
  return (
    <div className="d-flex align-items-center ">
      <div>
        {/* {openDialog && (
          <ConfirmationDialog
            open={openDialog}
            handleClose={() => setOpenDialog(false)}
            message={`Are you sure to assign ${userId.username} as Board Owner?`}
            //   callback={callback}
            loading={loading}
            btnTitle={"Assign"}
            title={"Assign Board Owner"}
          />
        )} */}
        <div
          onClick={handleClick}
          // className={
          //   boardName?.data?.responseData?.is_owner || isAdmin()
          //     ? "board-owner-avtar-click"
          //     : ""
          // }
          className="cursor-pointer"
        >
          <AvatarGroup max={4}>
            {user.map((el) => (
              <Avatar
                // key={`user-avtar-${el.user_id}`}
                username={`${el.first_name} ${el.last_name}`}
                user_image={el.user_image}
                title={`${el.first_name} ${el.last_name}`}
                className="user-avtar-dropdown user-avatar"
                style={!el.user_image && ({
                  bgcolor: el?.profile_color?.background,
                  color: el?.profile_color?.text,
                })}
              >
                {createAvtarName(`${el.first_name} ${el.last_name}` || "")}
              </Avatar>
            ))}
            {/* {user.length < 1 && <Image />} */}
          </AvatarGroup>
        </div>
      </div>
      {user.length == 1 ? (
        <div>
          <p className="board-heading-name">
            {user[0].first_name} {user[0].last_name}
          </p>
        </div>
      ) : (
        <div>
          <p className="board-heading-name">
            {user.length < 1 ? "Select User" : "Multiple"}
          </p>
        </div>
      )}
    </div>
  );
}

export default SelectUser;

// <Menu
//   id="account-menu"
//   anchorEl={anchorEl}
//   open={open}
//   onClose={handleClose}
//   PaperProps={{
//     elevation: 0,
//     sx: {
//       overflow: "visible",
//       filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
//       mt: 1.5,

//       "&::before": {
//         content: '""',
//         display: "block",
//         position: "absolute",
//         top: 0,
//         left: 14,
//         width: 14,
//         height: 14,
//         bgcolor: "background.paper",
//         transform: "translateY(-50%) rotate(45deg)",
//         zIndex: 0,
//       },
//     },
//   }}
//   transformOrigin={{ horizontal: "left", vertical: "top" }}
//   // anchorOrigin={{ horizontal: "left", vertical: "bottom" }}
//   className="assign-board-owner-list"
// >
//   <div onKeyDown={(e) => e.stopPropagation()}>
//     <SearchBoard
//       onSearch={(val) => setSearchQuery(val)}
//       searchQuery={searchQuery}
//     />
//   </div>
//   {!searchQuery && (
//     <MenuItem onClick={selectAll}>
//       {/* <label class="custom-checkbox">
//                 <input
//                   type="checkbox"
//                   id={`board-list-my-task-checkbox-all-board`}
//                   onChange={selectAll}
//                 /> */}
//       {/* <span class="checkmark"></span> */}
//       Select All Users
//       {/* </label> */}
//     </MenuItem>
//   )}
//   {filteredItems
//     .filter((el) => el.active)
//     .sort(function (a, b) {
//       return a.first_name.localeCompare(b.first_name);
//     })
//     .map((el) => {
//       return (
//         <MenuItem
//           key={`user-avtar-dropdown-admin-assign-${el.user_id}`}
//           onClick={() => dispatch(setMyTaskUser([el]))}
//         >
//           {/* <label class="custom-checkbox">
//                     <input
//                       type="checkbox"
//                       id={`user-check-box-${el.user_id}`}
//                       // checked={el.user_id == user.user_id}
//                       checked={user.some(
//                         (element) => element.user_id == el.user_id
//                       )}
//                       onClick={(event) => {
//                         console.log("Paragraph clicked");
//                         event.stopPropagation();
//                       }}
//                     />
//                     <span class="checkmark"></span> */}

//           <Avatar
//             key={`user-avtar-${el.user_id}`}
//             username={`${el.first_name} ${el.last_name}`}
//             user_image={el.user_image}
//             title={`${el.first_name} ${el.last_name}`}
//             className="user-avtar-dropdown user-avatar mr-8"
//             style={{
//               bgcolor: el?.profile_color?.background,
//               color: el?.profile_color?.text,
//             }}
//           >
//             {createAvtarName(`${el.first_name} ${el.last_name}`)}
//           </Avatar>

//           {`${el.first_name} ${el.last_name}`}
//           {/* </label> */}
//         </MenuItem>
//       );
//     })}
// </Menu>;

import React, { useEffect, useState } from "react";
import { useCustomSearchParams } from "../../../../../hooks/useCustomSearchParams";
import MuiTable from "../../../board/components/BoardTable/MuiTbale";
import { getMissingStatus } from "../../../../../sevices/apiMissingReport";
import MissingStatusUser from "./MissingStatusUser";
import Loader from "../../../../../components/Loader";
import moment from "moment";
import { useResponsive } from "../../../../../hooks/useResponsive";
import { Box } from "@mui/material";
function StatusTable() {
  const [filterBoard] = useCustomSearchParams("missingStatus");
  const [status, setStatus] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const filterBoardObj = JSON.parse(filterBoard);
  const isMobile = useResponsive("down", "md");

  const splitString = (date) => {
    if (date) return date.split("T");
  };
  function changeDateFormat(dateStr) {
    // Split the date string by '/'
    const [day, month, year] = dateStr.split("/");

    // Rearrange the date components to 'YYYY-MM-DD' format
    return `${year}-${month}-${day}`;
  }

  function changeDateFormatToMonthDayYear(dateStr) {
    // Split the date string by '/'
    const [year, month, day] = dateStr.split("/");

    // Rearrange the date components to 'YYYY-MM-DD' format
    return `${month}/${day}/${year}`;
  }

  const start_date = filterBoardObj
    ? moment(filterBoardObj.startDate).format("YYYY-MM-DD")
    : "";
  const end_date = filterBoardObj
    ? moment(filterBoardObj.endDate).format("YYYY-MM-DD")
    : "";

  // console.log("date", start_date, end_date);

  const fetchData = async () => {
    setIsLoading(true);
    console.log("start date and end date === ", start_date, end_date);
    try {
      const response = await getMissingStatus({ start_date, end_date });
      const responseData = response?.data?.data?.responseData;
      const statusData = responseData.map((item, index) => ({
        ...item,
        id: index + 1, // Replace this with a unique identifier suitable for your data
      }));
      setStatus(statusData); // Assuming response is the data you want to set
      setIsLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, [start_date, end_date]);

  const columns = [
    {
      field: "username",
      headerName: "User Name",
      renderCell: (params) => {
        return (
          <div className="report-user-name">
            <MissingStatusUser params={params} />
          </div>
        );
      },
      minWidth: isMobile && 180,
      // headerClassName: 'paddingLeft',
      flex: 1.5,
      // editable: true,
      // sortable: false,
    },
    {
      field: "date",
      headerName: "Date",
      renderCell: (params) => {
        return (
          <div className="report-date">
            {changeDateFormatToMonthDayYear(
              splitString(params.row.date)[0].replaceAll("-", "/")
            )}
          </div>
        );
      },
      // width: 200,
      minWidth: isMobile && 100,
      flex: 1.2,
      // editable: true,
      sortable: false,
    },
    {
      field: "total_hours_spent",
      headerName: "Hours Spent",
      renderCell: (params) => {
        return (
          <div className="report-date">
            {`${params.row.total_hours_spent}/8`}
          </div>
        );
        // return `${params.row.total_hours_spent}/8`;
      },
      // width: 200,
      minWidth: isMobile && 100,
      flex: 1.1,
      // editable: true,
      sortable: false,
    },
    {
      field: "delay",
      headerName: "Delay",
      renderCell: (params) => {
        return (
          <div className="report-date">
            {params.row.delay ? params.row.delay : "NA"}
          </div>
        );
        // return params.row.delay ? params.row.delay : 'NA';
      },
      // width: 200,
      minWidth: isMobile && 80,
      flex: 0.8,
      // editable: true,
      sortable: false,
    },
  ];

  return (
    <>
      {isLoading ? (
        <div className="loader-center">
          <Loader color={"black"} />
        </div>
      ) : (
        status && (
          <Box p={isMobile && 1} mt={isMobile && 1}>
            <MuiTable
              initialRows={status}
              columns={columns}
              defaultSort={"username"}
              headerBgColor={"#F2F4F6"}
            />
          </Box>
        )
      )}
    </>
  );
}

export default StatusTable;

import React from "react";
import { useState } from "react";
import Button from "@mui/material/Button";
import addIcon from "../../../../styles/svg/addIcon.svg";
import CreateWorkflow from "./createWorkflowPopup/CreateWorkflow";
import { useResponsive } from "../../../../hooks/useResponsive";
import { Grid, Stack, Typography, useScrollTrigger } from "@mui/material";
const HeaderWorkflow = () => {
  const [showCreateWorkflow, setShowCreateWorkflow] = useState(false);
  const isMobile = useResponsive("down", "md");
  const trigger = useScrollTrigger();

  return (
    <>
      {showCreateWorkflow && (
        <CreateWorkflow
          open={showCreateWorkflow}
          close={setShowCreateWorkflow}
        />
      )}
      {isMobile ? (
        <Grid
          container
          xs={12}
          className="remove-container-width fixed-ui"
          sx={{ top: trigger ? "0px" : isMobile ? "51px" : "" }}
          justifyContent={"center"}
          boxShadow={" 0px 1px 7px rgba(0, 0, 0, 0.3)"}
          pb={"16px !important"}
          pt={trigger ? "24px !important" : "20px !important"}
          alignItems={"center"}>
          <Grid
            item
            xs={6}
            md={12}
            lg={3}
            className="d-flex align-items-center">
            <Typography fontWeight={600}>Workflow</Typography>
          </Grid>
          <Grid item xs={6} md={7} lg={9}>
            <Stack direction="row" justifyContent="end">
              <Button
                onClick={() => setShowCreateWorkflow(true)}
                className="create-new-button"
                sx={{
                  backgroundColor: "#1B3E61",
                  border: "none",
                  padding: "9px 12px",
                  textTransform: "none",
                  fontWeight: "500",
                  fontSize: "14px",
                  lineHeight: "19px",
                  boxShadow: "0",
                  letterSpacing: "0",
                  "&:hover": {
                    backgroundColor: "#1B3E61",
                    border: "none",
                    boxShadow: "0",
                    letterSpacing: "0",
                  },
                }}
                variant="contained">
                <img
                  src={addIcon}
                  alt=""
                  style={{ marginRight: isMobile ? "4px" : "" }}
                />
                Create New
              </Button>
            </Stack>{" "}
          </Grid>
        </Grid>
      ) : (
        <div className="header-name d-flex justify-content-space-between align-items-center">
          <p>Workflow</p>
          <Button
            onClick={() => setShowCreateWorkflow(true)}
            className="create-new-button"
            sx={{
              backgroundColor: "#1B3E61",
              border: "none",
              padding: "9px 12px",
              textTransform: "none",
              fontWeight: "500",
              fontSize: "14px",
              lineHeight: "19px",
              boxShadow: "0",
              letterSpacing: "0",
              "&:hover": {
                backgroundColor: "#1B3E61",
                border: "none",
                boxShadow: "0",
                letterSpacing: "0",
              },
            }}
            variant="contained">
            <img src={addIcon} alt="" />
            Create New
          </Button>
        </div>
      )}
    </>
  );
};

export default HeaderWorkflow;

import { useQuery } from "@tanstack/react-query";
import { getCheckpoints, getEpic, getEpicThroughId, getMileStoneHistory} from "../../sevices/apiEpic";
import { getUserBoardEpic } from "../../sevices/apiEpic";
import { getCheckboxUtilityClass } from "@mui/material";

export function useEpic(board_id) {
    const {
      isLoading,
      data: epics,
      error,
      isError,
    } = useQuery({
      queryKey: [`epic`, board_id],
      queryFn: () => getEpic(board_id),
    });
  
    return { isLoading, error, epics, isError };
  }


export function useUserBoardEpic(id) {
  const {
    isLoading,
    data: epic,
    error,
    isError,
  } = useQuery({
    queryKey: [`userepic`, id],
    queryFn: () => getUserBoardEpic(id),
  });

  return { isLoading, error, epic, isError };
}

export function useEpicThroughId(epic_id) {
  const {
    isLoading,
    data: epics,
    error,
    isError,
  } = useQuery({
    queryKey: [`epic_id`, epic_id],
    queryFn: () => getEpicThroughId(epic_id),
  });

  return { isLoading, error, epics, isError };
}


export function useCheckpoints(epic_id) {
  const {
    isLoading,
    data: checkpoint,
    error,
    isError,
  } = useQuery({
    queryKey: [`checkpoints`, epic_id],
    queryFn: () => getCheckpoints(epic_id),
  });

  return { isLoading, error, checkpoint, isError };
}

export function useMilestoneHistory(epic_id) {
  const {
    isLoading,
    data: history,
    error,
    isError,
  } = useQuery({
    queryKey: [`milestone_history`, epic_id],
    queryFn: () => getMileStoneHistory(epic_id),
  });

  return { isLoading, error, history, isError };
}

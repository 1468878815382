import React, { useRef, useState } from "react";
import Grid from "@mui/material/Grid";
import ClearIcon from "@mui/icons-material/Clear";
import { useParams, useSearchParams } from "react-router-dom";
import { useBoardName } from "../../../../utils/reactQuery/boards";
import {
  Box,
  Button,
  Drawer,
  Stack,
  styled,
  Typography,
  useScrollTrigger,
} from "@mui/material";

import EpicButton from "../BoardHeader/epicButton/EpicButton";
import SelectDatePicker from "../../../myTask/components/Filters/SelectDatePicker";
import GlobalSearch from "../../../../components/Search";
import { useDispatch } from "react-redux";
import _ from "lodash";
import { searchCard } from "../../../../redux/SearchSlice";
import { filterUser } from "../../../../redux/usersSlice";
import Users from "../BoardHeader/Users/Users";
import { useQueryClient } from "@tanstack/react-query";
import { useResponsive } from "../../../../hooks/useResponsive";
import filterIcon from "../../../../styles/svg/epic-icon.svg";

const AnimatedDrawerContent = styled(Box)(({ theme }) => ({
  width: 250,
  transition: "transform 1s ease-in-out",
}));

const FeedsHeader = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const { id } = useParams();
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const [filterDrawer, setFilterDrawer] = useState(false);
  const isMobile = useResponsive("down", "md");
  const { boardName } = useBoardName(id);

  const handleChange = (event) => {
    const value = event.target.value;
    setInputValue(value);
    debouncedSearch(value);
  };

  const debouncedSearch = useRef(
    _.debounce((value) => {
      dispatch(searchCard(value));
    }, 300) // 300ms delay
  ).current;

  const [inputValue, setInputValue] = useState("");

  const removeSearchParams = (paramsToRemove) => {
    // Create a copy of the current search params
    const newSearchParams = new URLSearchParams(searchParams);

    // Remove each parameter in the paramsToRemove array
    paramsToRemove.forEach((param) => {
      newSearchParams.delete(param);
    });

    // Set the updated search params
    setSearchParams(newSearchParams);
  };
  const filterUserId = searchParams.get("search");
  const epicFilter = searchParams.get("epic");
  const clearFilters = () => {
    removeSearchParams(["search", "epic"]);
    dispatch(filterUser(null));
    dispatch(searchCard([]));
  };
  const trigger = useScrollTrigger();

  return (
    <>
      {isMobile ? (
        <Grid
          container
          item
          className="remove-container-width fixed-ui"
          sx={{ top: trigger ? "30px" : isMobile ? "50px" : "" }}
          justifyContent={"center"}
          alignItems={"center"}
          boxShadow={1}>
          <Grid
            item
            xs={6}
            md={12}
            lg={3}
            className="d-flex align-items-center">
            {`Feeds: ${boardName?.data?.data?.responseData?.board_name}`}
          </Grid>
          <Grid item xs={6} md={7} lg={9}>
            <div className="d-flex justify-content-end">
              <Stack direction="row">
                <img
                  src={filterIcon}
                  alt="filter"
                  style={{ marginRight: "8px" }}
                  onClick={() => setFilterDrawer(true)}
                />
              </Stack>
            </div>
          </Grid>
        </Grid>
      ) : (
        <Grid
          container
          className="remove-container-width fixed-ui feed-header"
          justifyContent={"center"}
          alignItems={"center"}>
          <Grid
            item
            xs={3}
            md={5}
            className="d-flex align-items-center feed-boardName">{`Feeds: ${boardName?.data?.data?.responseData?.board_name}`}</Grid>
          <Grid item xs={9} md={7}>
            <div className="d-flex justify-content-end">
              <Stack spacing={1} direction="row">
                <EpicButton showNoMile={false} />
                {(filterUserId || epicFilter) && (
                  <Button
                    size="small"
                    endIcon={<ClearIcon />}
                    className="capitalize"
                    onClick={clearFilters}>
                    Clear Filter
                  </Button>
                )}
                <Users
                  boardName={boardName}
                  boardId={id}
                  ownerId={boardName?.data?.data?.responseData?.owner_user_id}
                  showAddUser={false}
                />
                {/* <EpicButton boardNameDetails={boardName} /> */}
                <SelectDatePicker />
                <GlobalSearch
                  placeholder={"Search..."}
                  searchText={inputValue}
                  handleChange={handleChange}
                />
              </Stack>
            </div>
          </Grid>
        </Grid>
      )}

      {filterDrawer && (
        <Drawer
          open={filterDrawer}
          anchor="right"
          onClose={() => setFilterDrawer(false)}>
          <AnimatedDrawerContent
            style={{
              transform: filterDrawer ? "translateX(0)" : "translateX(100%)",
            }}>
            <Box p={1}>
              <Typography color="#1B3E61" mb={1} mt={1} fontWeight={600}>
                Select user
              </Typography>
              <Users
                boardName={boardName}
                boardId={id}
                ownerId={boardName?.data?.data?.responseData?.owner_user_id}
                showAddUser={false}
              />
              {/* <EpicButton boardNameDetails={boardName} /> */}
              <Box mt={2}>
                <SelectDatePicker />
              </Box>
            </Box>
          </AnimatedDrawerContent>
        </Drawer>
      )}
    </>
  );
};

export default FeedsHeader;

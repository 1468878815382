import React from "react";
import { useCustomSearchParams } from "../../../../hooks/useCustomSearchParams";
import { useUsers } from "../../../../utils/reactQuery/users";
import Loader from "../../../../components/Loader";
import MuiTable from "../../board/components/BoardTable/MuiTbale";
import Active from "./Active";
import AdminLogo from "../../../../styles/svg/admin.svg";
import UserLogo from "../../../../styles/svg/not-owner.svg";
import InternalLogo from "../../../../styles/svg/internal.svg";
import ExternalLogo from "../../../../styles/svg/external.svg";
import { relativeTime } from "../../../../utils/helpers";
import Team from "./Team";
import Logs from "./Logs";
import UserName from "./UserName";
import Admin from "./Admin";
import { isAdmin } from "../../../../utils/constants";
import { useResponsive } from "../../../../hooks/useResponsive";
import { Box } from "@mui/material";
function UserTable() {
  const [userType] = useCustomSearchParams("users-type");
  const [userStatus] = useCustomSearchParams("users-status");
  const { isLoading, error, users, isError } = useUsers();
  const data = users?.data.data.responseData;
  const isMobile = useResponsive("down", "md");

  const filteringUser = () => {
    const usersArray = data.map((el, idx) => {
      return { ...el, id: idx };
    });
    let users = usersArray;
    if (userStatus) {
      if (userStatus == "all") users = usersArray;
      if (userStatus == "active") {
        users = users.filter((el) => el.active);
      }
      if (userStatus == "inactive") {
        users = users.filter((el) => !el.active);
      }
    }
    if (userType) {
      if (userType == "all") users = users;
      if (userType == "internal") {
        users = users.filter((el) => el.user_type == "Internal");
      }
      if (userType == "external") {
        users = users.filter((el) => el.user_type == "External");
      }
    }
    return users;
  };

  const columns = [
    {
      field: "user_type",
      headerName: "Type",
      minWidth: 80,
      renderHeader: (cell) => (
        <div className="w-100 d-flex justify-content-center">
          <p className="size-14 text-color weight-500">Type</p>
        </div>
      ),
      headerClassName: "center-header",
      renderCell: (params) => {
        return (
          <div className="w-100 d-flex justify-content-center">
            {params.value == "Internal" ? (
              <img src={InternalLogo} />
            ) : (
              <img src={ExternalLogo} />
            )}
          </div>
        );
      },
      flex: 0.6,
      sortable: false,
    },
    {
      field: "first_name",
      headerName: "Name",
      minWidth: 250,
      flex: 1.5,
      // editable: true,
      renderCell: (params) => {
        return <UserName params={params} />;
      },
    },
    {
      field: "teams",
      headerName: "Team",
      minWidth: 150,
      renderCell: (params) => {
        return <Team params={params} />;
      },
      flex: 1,
      sortable: false,
    },
    {
      field: "cardlog_required",
      headerName: "Logs",
      minWidth: 100,
      renderHeader: (cell) => (
        <div className="w-100 d-flex justify-content-center">
          <p className="size-14 text-color weight-500">Logs</p>
        </div>
      ),
      renderCell: (params) => {
        return <Logs logRequired={params.value} params={params} />;
      },
      flex: 0.7,
      sortable: false,
      headerClassName: "center-header",
    },
    {
      field: "role",
      headerName: "Admin",
      minWidth: 100,
      renderHeader: (cell) => (
        <div className="w-100 d-flex justify-content-center">
          <p className="size-14 text-color weight-500">Admin</p>
        </div>
      ),
      //   renderHeader: (cell) => <img src={ActiveIcon} />,
      renderCell: (params) => {
        return (
          <div className="w-100 d-flex justify-content-center">
            {isAdmin() && (
              <Admin isSuperAdmin={params.value} id={params.row.user_id} />
            )}
            {/* {params.value == "Super Admin" ? (
              <img src={AdminLogo} />
            ) : (
              <img src={UserLogo} />
            )} */}
          </div>
        );
      },
      headerClassName: "center-header",
      flex: 1,
      sortable: false,
    },
    {
      field: "active",
      headerName: "Active",
      minWidth: 85,
      renderHeader: (cell) => (
        <div className="w-100 d-flex justify-content-center">
          <p className="size-14 text-color weight-500">Active</p>
        </div>
      ),
      renderCell: (params) => {
        return <Active isActive={params.value} id={params.row.user_id} />;
      },
      flex: 1,
      sortable: false,
      headerClassName: "center-header",
    },
    {
      field: "last_seen",
      headerName: "Last Active",
      minWidth: 100,
      //   renderHeader: (cell) => <img src={ActiveIcon} />,
      renderCell: (params) => {
        return <p>{relativeTime(params.value)}</p>;
      },
      flex: 1.2,
      sortable: false,
    },

    //   {
    //     field: "board_owner_name",
    //     headerName: "Board Owner",
    //     renderCell: (params) => {
    //       return <BoardOwner params={params} />;
    //     },
    //     width: 200,
    //     // flex: 1,
    //     // editable: true,
    //     sortable: false,
    //   },
    //   {
    //     field: "workflow_name",
    //     headerName: "Workflow",
    //     width: 150,
    //     renderCell: (params) => {
    //       return <Workflow params={params} />;
    //     },
    //     // flex: 1,
    //     // editable: true,
    //     sortable: false,
    //   },
    //   {
    //     field: "is_private",
    //     headerName: "Public/Private",
    //     width: 120,
    //     renderCell: (params) => {
    //       return <Accessibility isPrivate={params.value} params={params} />;
    //     },
    //     // flex: 1,
    //     // editable: true,
    //     sortable: false,
    //   },
    //   {
    //     field: "action",
    //     headerName: "Action",
    //     width: 75,
    //     renderCell: (params) => {
    //       return <Delete id={params.row.board_id} />;
    //     },
    //     sortable: false,
    //     // flex: 1,
    //     // editable: true,
    //   },
  ];
  return (
    <>
      {isLoading && (
        <div className="loader-center">
          <Loader color={"black"} />
        </div>
      )}
      {!isLoading && isError && (
        <div className="loader-center">
          <p>Error</p>
        </div>
      )}
      {!isLoading && !isError && (
        <Box p={isMobile && 1} mt={isMobile && 1}>
          <MuiTable
            initialRows={filteringUser()}
            columns={columns}
            defaultSort={"first_name"}
            headerBgColor={"#F2F4F6"}
          />
        </Box>
      )}
    </>
  );
}

export default UserTable;

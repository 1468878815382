import React from "react";
import { Button, Divider, MenuItem, Stack, Typography } from "@mui/material";

import { useCustomSearchParams } from "../../../../hooks/useCustomSearchParams";
import DownArrow from "../../../../styles/svg/downArrow.svg";
import CustomMenu from "../../../../components/Menu";
import { useResponsive } from "../../../../hooks/useResponsive";

function FilterUserType() {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [userFilter, updateParams, removeSearchParam] =
    useCustomSearchParams("users-type");
  const isMobile = useResponsive("down", "md");

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);
  return (
    <div className="mr-2">
      {isMobile ? (
        <>
          <Typography
            className={"workflow_steps pl-2"}
            sx={{
              backgroundColor: userFilter == "all" && "#C5DCF3",
            }}
            onClick={() => updateParams("users-type", "all")}>
            See All
          </Typography>
          <Typography
            className={"workflow_steps pl-2"}
            sx={{
              backgroundColor: userFilter == "internal" && "#C5DCF3",
            }}
            onClick={() => updateParams("users-type", "internal")}>
            Internal
          </Typography>
          <Typography
            className={"workflow_steps pl-2"}
            sx={{
              backgroundColor: userFilter == "external" && "#C5DCF3",
            }}
            onClick={() => updateParams("users-type", "external")}>
            External
          </Typography>
        </>
      ) : (
        <Button
          variant="outlined"
          color="primary"
          className="filter-btn white-button"
          onClick={handleClick}
          endIcon={<img src={DownArrow} />}
          sx={{
            height: "38px",
            width: "148px",
            justifyContent: "space-between",
            "&:hover": {
              outline: "none",
              backgroundColor: "rgb(240 249 254)",
              border: "1px solid #1b3e61",
            },
          }}>
          <p className="mr-4 capitalize">{userFilter || "User Type"}</p>
        </Button>
      )}
      <CustomMenu open={open} handleClose={handleClose} anchorEl={anchorEl}>
        <MenuItem
          sx={{
            textTransform: "capitalize",
            color: "rgba(33, 43, 54, 1)",
            fontWeight: "400",
            fontSize: "14px",
          }}
          onClick={() => updateParams("users-type", "all")}>
          See All
        </MenuItem>
        <Divider style={{ marginBottom: "0px", marginTop: "0px" }} />
        <MenuItem
          sx={{
            textTransform: "capitalize",
            color: "rgba(33, 43, 54, 1)",
            fontWeight: "400",
            fontSize: "14px",
          }}
          onClick={() => updateParams("users-type", "internal")}>
          Internal
        </MenuItem>
        <Divider style={{ marginBottom: "0px", marginTop: "0px" }} />
        <MenuItem
          sx={{
            textTransform: "capitalize",
            color: "rgba(33, 43, 54, 1)",
            fontWeight: "400",
            fontSize: "14px",
          }}
          onClick={() => updateParams("users-type", "external")}>
          External
        </MenuItem>
      </CustomMenu>
    </div>
  );
}

export default FilterUserType;

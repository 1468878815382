import React from 'react'
import { createAvtarName } from '../../../../utils/helpers';
import Avatar from '../../../../components/Avatar'

function UserName({params}) {
  return (
    <>
      <div className="d-flex align-items-center">
        <Avatar
          // key={`user-avtar-${el.user_id}`}
          username={`${params.row.first_name} ${params.row.last_name}`}
          user_image={params.row.user_image}
          title={`${params.row.first_name} ${params.row.last_name}`}
          className="user-avtar-dropdown user-avatar  mr-2"
          style={
            !params.row.user_image && {
              bgcolor: params.row?.profile_color?.background,
              color: params.row?.profile_color?.text,
            }
          }
        >
          {createAvtarName(`${params.row.first_name} ${params.row.last_name}`)}
        </Avatar>

        <p>{`${params.row.first_name} ${params.row.last_name}`}</p>
      </div>
    </>
  );
}

export default UserName
import React from "react";
import { Routes, Route } from "react-router-dom";
import BoardPage from "../../pages/board";
import CheckBoardAvailability from "../../pages/board/CheckBoardAvailability";
import Dashboard from "../../pages/dashboard";
import Private from "./Private";
import TaskDetail from "../../pages/myTask/components/TaskDetail";
import AdminBoards from "../../pages/admin/board";
import AdminUsers from "../../pages/admin/user";
import Feeds from "../../pages/board/components/Feeds";
import Workflow from "../../pages/admin/workflow";
import Report from "../../pages/admin/report";
import WatchingComp from "../../pages/watching";
import ErrorBoundary from "../../components/ErrorFallback";

const adminRoutes = [
  {
    path: "/admin-board",
    element: <AdminBoards />,
  },
  {
    path: "/admin-workflow",
    element: <Workflow />,
  },
  {
    path: "/admin-user",
    element: <AdminUsers />,
  },
  {
    path: "/admin-report",
    element: <Report />,
  },
];

export const Pages = {
  element: <Private />,
  children: [
    {
      element: (
        <ErrorBoundary>
          <Dashboard />
        </ErrorBoundary>
      ), // Wrap Dashboard with ErrorBoundary
      children: [
        {
          path: "/board",
          element: (
            <ErrorBoundary>
              <CheckBoardAvailability />
            </ErrorBoundary>
          ), // Wrap with ErrorBoundary
        },
        {
          path: "/board/:id",
          element: (
            <ErrorBoundary>
              <BoardPage />
            </ErrorBoundary>
          ), // Wrap BoardPage with ErrorBoundary
        },
        {
          path: "/my-task",
          element: (
            <ErrorBoundary>
              <TaskDetail />
            </ErrorBoundary>
          ), // Wrap TaskDetail with ErrorBoundary
        },
        {
          path: "/feeds/:id",
          element: (
            <ErrorBoundary>
              <Feeds />
            </ErrorBoundary>
          ), // Wrap Feeds with ErrorBoundary
        },
        {
          path: "/watching",
          element: (
            <ErrorBoundary>
              <WatchingComp />
            </ErrorBoundary>
          ), // Wrap WatchingComp with ErrorBoundary
        },
        ...adminRoutes.map((route) => ({
          ...route,
          element: <ErrorBoundary>{route.element}</ErrorBoundary>, // Wrap admin routes with ErrorBoundary
        })),
      ],
    },
  ],
};

import * as yup from "yup";

export const schema = yup
  .object({
    team_name: yup.string().required("First name is required"),
    user_ids: yup.array()
      .of(
        yup.object()
          .required("User's is required")
      )
      .min(1, "At least one User is required"),
  })
  .required();

import React, { useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import {
  createTheme,
  ThemeProvider,
  StyledEngineProvider,
} from "@mui/material/styles";
import { Box, Stack, Typography } from "@mui/material";

function MuiTable({
  initialRows,
  columns,
  headerBgColor,
  defaultSort,
  subHeight,
  footer,
  border,
  minRowHeight,
  getRowHeight = "",
  padding = "",
}) {
  const [rows, setRows] = useState([""]);
  const [selectedRows, setSelectedRows] = useState([]);

  const handleSelectionChange = (selectionModel) => {
    const newSelectedRow = selectionModel[0];
    if (newSelectedRow != null && !selectedRows.includes(newSelectedRow)) {
      setSelectedRows([...selectedRows, newSelectedRow]);
      setRows(
        rows.map((row) =>
          row.id === newSelectedRow ? { ...row, disabled: true } : row
        )
      );
    }
  };

  const getRowClassName = (params) => {
    const { row } = params;
    return row?.is_archive ? "inactive-row" : "active-row";
  };

  const theme = createTheme({
    components: {
      MuiDataGrid: {
        styleOverrides: {
          root: {
            "& .MuiDataGrid-columnHeader:focus, & .MuiDataGrid-cell:focus": {
              outline: "none",
            },
            "& .MuiDataGrid-virtualScrollerRenderZone, & .MuiDataGrid-virtualScrollerContent":
              {
                height: `calc(96vh - ${subHeight}px) !important`,
                overflowY: "scroll",
              },
            // "& .MuiDataGrid-columnHeader, .MuiDataGrid-cell": {
            //   borderRight: `1px solid #CED4DA !important`,
            // },
            "& .MuiDataGrid-columnsContainer, .MuiDataGrid-cell": {
              // borderBottom: `1px solid #CED4DA !important`,
            },
            // "&.active-row ": {
            //   backgroundColor: "lightgreen",
            // },

            "& .inactive-row": {
              backgroundColor: "#EDEDED",
            },
            "& .MuiDataGrid-row": {
              // maxHeight: "56px !important",
              // minHeight: minRowHeight ? minRowHeight : "65px !important",
              // height: "56px !important",
            },
            "& .MuiDataGrid-row.Mui-selected": {
              backgroundColor: "transparent",
              "&:hover": {
                backgroundColor: "transparent",
              },
            },

            "& .MuiDataGrid-row.Mui-selected.Mui-selected:hover": {
              backgroundColor: "transparent",
            },
            "& .MuiDataGrid-row:focus, & .MuiDataGrid-cell:focus": {
              outline: "none",
            },
            "& .MuiDataGrid-row:hover": {
              backgroundColor: "transparent",
            },
            "& .MuiDataGrid-row.disabled": {
              pointerEvents: "none",
            },
            "& .MuiDataGrid-columnSeparator": {
              display: "none",
            },
            "& .MuiDataGrid-cell": {
              //   borderRight: "1px solid rgba(224, 224, 224, 1)",
              //   fontSize: isMobile ? "10px !important" : "14px !important",
              fontWeight: "500 !important",
              // color: "#455F69",
              fontSize: "14px",
              color: "#212B36CC",
              display: "flex",
              alignItems: "center",

              flexWrap: "wrap",
              height: "auto",
              minHeight: "inherit",
              maxHeight: "inherit",
              lineHeight: "20px",
              whiteSpace: "normal",
              padding: padding,
            },
            "& .MuiDataGrid-columnHeader": {
              //   height: isMobile ? "30px !important" : "",
              backgroundColor: headerBgColor ? headerBgColor : "#F5F8FC",
              //   fontSize: isMobile ? "12px" : "",
              // color: "#455F69",
              color: "#1B3E61",

              // borderRight: '1px solid rgba(224, 224, 224, 1) !important',
              //   paddingLeft: "0px",
              // paddingRight: "0px",
              height: "44px  !important",
              // borderBottom: "1px solid #CED4DA",
            },
            "& .MuiDataGrid-columnHeaderTitleContainer": {
              //   height: isMobile ? "30px !important" : "",
            },
            "& .MuiDataGrid-columnHeaderTitle": {
              //   fontWeight: isMobile ? "400 !important" : "500 !important",
              fontSize: "14px !important",
            },
            "& .MuiDataGrid-columnGroupHeader": {
              border: "1px solid #CED4DA",
              backgroundColor: "#F5F8FC !important",
              color: "#fff",
              outline: "none",

              //   fontSize: isMobile ? "10px" : "20px",
            },
            "& .MuiDataGrid-columnHeaderTitleContainerContent": {
              //   paddingLeft: isMobile ? "5px" : "10px",
              //   margin: isMobile ? "20px 5px !important" : "",
              // width: "100%",
            },

            "& .MuiDataGrid-columnHeader--filledGroup .MuiDataGrid-columnHeaderTitleContainerContent":
              {
                margin: "auto !important",
                paddingLeft: "0px",
              },
            "& .MuiDataGrid-columnHeader--filledGroup .MuiDataGrid-columnHeaderTitleContainer":
              {
                borderBottomWidth: "1px !important",
                backgroundColor: "#F5F8FC",
                // height: isMobile ? "50px !important" : "100px !important",
                borderBottom: "1px solid #CED4DA",
                textWrap: "wrap",
              },
            "& .MuiDataGrid-columnHeader--filledGroup": {
              //   height: isMobile ? "150px !important" : "100px !important",
              //   maxHeight: isMobile ? "50px !important" : "100px",
              textAlign: "center",
            },
            "& .MuiDataGrid-columnHeader--filledGroup .MuiDataGrid-columnHeaderTitle":
              {
                fontWeight: "700 !important",
                textWrap: "wrap",
              },
            "& .MuiDataGrid-filler": {
              height: "0px !important",
            },
            "& .MuiTablePagination-displayedRows": {
              //   fontSize: isMobile ? "10px !important" : "",
            },
            "& .MuiDataGrid-virtualScroller": {
              overflowY: "hidden !important", // Hides the vertical scrollbar
            },

            "& .MuiDataGrid-scrollbar--vertical": {
              overflowY: "hidden !important", // Hides the vertical scrollbar
              display: "none !important",
            },
            "& .MuiDataGrid-scrollbarFiller": {
              display: "none !important",
            },
            "& .MuiDataGrid-main": {
              // border: "1px solid #CED4DA", // Apply border to the table
              border: "1px solid #E0E0E0",
              //   borderRight: 0,
              borderRadius: "4px",
            },
            "& .MuiDataGrid-footerContainer": {
              border: "none", // Ensure the footer has no border
            },
            "& .MuiDataGrid-overlayWrapper": {
              //   top: isMobile ? "0px !important" : "",
            },
            "& .MuiDataGrid-cell:focus-within": {
              outline: "none",
            },
          },
        },
      },
    },
  });

  const CustomNoRowsOverlay = () => {
    return (
      <Stack justifyContent="center" height="100%">
        <Typography variant="subtitle1" textAlign="center">
          No data found
        </Typography>
      </Stack>
    );
  };
  const CustomNoData = () => {
    return (
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          height: "100%",
          backgroundColor: "#f5f5f5",
        }}
      >
        <Typography variant="h6" color="textSecondary">
          No rows
        </Typography>
      </Box>
    );
  };
  return (
    <Box>
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
          <Box
            style={{
              width: "100%",
              //   height: initialRows.length > 0 ? "" : isMobile ? "" : "319px",
              // overflowX: "hidden",
              // height:'50vh'
            }}
          >
            <DataGrid
              rows={initialRows}
              getRowClassName={getRowClassName}
              columns={columns}
              disableColumnFilter
              disableColumnMenu
              disableSelectionOnClick={true}
              onSelectionModelChange={(newSelection) =>
                handleSelectionChange(newSelection)
              }
              slots={{ noRowsOverlay: CustomNoRowsOverlay }}
              selectionModel={selectedRows}
              initialState={{
                pagination: { paginationModel: { pageSize: 100 } },
                sorting: {
                  sortModel: [{ field: defaultSort, sort: "asc" }],
                },
              }}
              autoHeight
              pageSizeOptions={[10, 20, 50, 100]}
              disableExtendRowFullWidth
              checkboxSelection={false}
              hideFooterSelectedRowCount
              showCellVerticalBorder={border ? border : false}
              showColumnVerticalBorder={border ? border : false}
              rowHeight={minRowHeight ? minRowHeight : 65}
              getRowHeight={getRowHeight}
              hideFooter={footer ? footer : false}
              sx={{
                border: 0,
              }}
            />
          </Box>
        </ThemeProvider>
      </StyledEngineProvider>
    </Box>
  );
}

export default MuiTable;

// flex-wrap: wrap;
//     height: auto;
//     min-height: inherit;
//     max-height: inherit;
//     line-height: 20px;
//     white-space: normal;

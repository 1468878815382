import { useQuery } from "@tanstack/react-query";
import {
  getCards,
  getCard,
  getCardAttachment,
  getUserCards,
  getMyCards,
} from "../../sevices/apiCards";
import { getComments } from "../../sevices/apiComment";
export function useCards(id) {
  const {
    isLoading,
    data: cards,
    error,
    isError,
  } = useQuery({
    queryKey: [`cards`, id],
    queryFn: () => getCards(id),
  });

  return { isLoading, error, cards, isError };
}

export function useUserCards(id) {
  const {
    isLoading,
    data: cards,
    error,
    isError,
  } = useQuery({
    queryKey: [`userCards`, id],
    queryFn: () => getUserCards(id),
  });

  return { isLoading, error, cards, isError };
}

export function useComment(id) {
  const {
    isLoading,
    data: comment,
    error,
    isError,
  } = useQuery({
    queryKey: [`comment`, id],
    queryFn: () => getComments(id),
  });

  return { isLoading, error, comment, isError };
}

export function useCard(id) {
  const {
    isLoading,
    data: card,
    error,
    isError,
  } = useQuery({
    queryKey: [`card`, id],
    queryFn: () => getCard(id),
  });

  return { isLoading, error, card, isError };
}

export function useCardAttachment(id) {
  const {
    isLoading,
    data: cardAttachment,
    error,
    isError,
  } = useQuery({
    queryKey: [`cardAttachment`, id],
    queryFn: () => getCardAttachment(id),
  });

  return { isLoading, error, cardAttachment, isError };
}

export function useMineCards(
  user,
  checkedList,
  epicSearch,
  reporterCards,
  workflow_id,
  unassigned_task,
  boards
) {
  const { isLoading, data, error, isError, refetch, isRefetching } = useQuery({
    queryKey: [
      "mineCards",
      workflow_id,
      user[0]?.user_id,
      user[1]?.user_id,
      boards,
    ],
    queryFn: () =>
      getMyCards(
        user,
        checkedList,
        epicSearch,
        reporterCards,
        workflow_id,
        unassigned_task,
        boards
      ),
  });
  console.log("USER___", user);
  return { isLoading, error, data, isError, refetch, isRefetching };
}

import * as yup from "yup";

export const schema = yup
  .object({
    first_name: yup.string().required("First name is required"),
    last_name: yup.string().required("Last name is required"),
    email: yup
      .string()
      .email("Invalid email address")
      .required("Email Address is required"),
  })
  .required();

export const internalInviteSchema= yup
.object({
  user: yup.string().required("Please select user")
})
.required();
import React from 'react'
import Item from './Item';

function AllDetailList({ comments, historys, worklogs ,cardId}) {
  const types = ["change_status", "card_assign", "create_card", "change_board"];
  const prepareHistory = historys.filter(
    (el) => types.includes(el.response_object.event_type)
  );

  const consolidateArray = [...prepareHistory, ...worklogs, ...comments].sort(
    (a, b) => new Date(b.consolidateTime) - new Date(a.consolidateTime)
  );

  return (
    <>
      {consolidateArray.map((item, idx) => (
        <Item
          item={item}
          key={`consolidate-card-item-${idx}`}
          cardId={cardId}
        />
      ))}
    </>
  );
}

export default AllDetailList
import React, { useEffect } from "react";
import Header from "./components/Header";
import "./worklog.scss";
import Filters from "./components/Filters";
import History from "./components/History";
import { Divider } from "@mui/material";
import { useResponsive } from "../../../hooks/useResponsive";
import { useBoards } from "../../../utils/reactQuery/boards";
import { useDispatch } from "react-redux";
import { setActiveBoards } from "../../../redux/ActiveBoardSlice";

function Worklog() {
  const isMobile = useResponsive("down", "md");
  const dispatch = useDispatch();
  const { isLoading, boards, isError } = useBoards();
  // console.log("left boards",isLoading, boards);
  useEffect(() => {
    if (boards) {
      dispatch(
        setActiveBoards(
          boards?.data?.data?.responseData
            .filter((el) => !el.is_archive)
            .sort(function (a, b) {
              return a.board_name.localeCompare(b.board_name);
            }) || []
        )
      );
    }
  }, [boards]);

  return (
    <>
      <Header />
      <Divider />
      {!isMobile && <Filters />}
      <History />
    </>
  );
}

export default Worklog;

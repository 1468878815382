import React from "react";
import Avatar from "../../../../../components/Avatar";
import { Grid, Tooltip } from "@mui/material";
import moment from "moment";
import { createAvtarName, hasImage } from "../../../../../utils/helpers";
import { useResponsive } from "../../../../../hooks/useResponsive";

function FeedGitHistory({ history }) {
  const isMobile = useResponsive("down", "md");
  return (
    <>
      {history ? (
        <Grid
          container
          spacing={2}
          sx={{
            width: isMobile
              ? "calc(100% - 10px) !important"
              : "calc(100% + 16px) !important",
          }}
          className="worklog-item d-flex justify-content-space-between">
          <Grid item xs={isMobile ? 12 : 9}>
            <div className="d-flex w-100">
              <Avatar
                // key={`user-avtar-${el.user_id}`}
                username={`${history?.response_object?.user_object?.username}`}
                user_image={history?.response_object?.user_object?.user_image}
                title={`${history?.response_object?.user_object?.username}`}
                className="user-avtar-dropdown user-avatar mr-8">
                {createAvtarName(
                  `${history?.response_object?.user_object?.username}` || ""
                )}
              </Avatar>
              <div className="w-100">
                <div
                  className={
                    isMobile
                      ? "d-flex justify-content-space-between mt-2"
                      : "d-flex justify-content-space-between"
                  }>
                  <div
                    className={
                      isMobile
                        ? "d-flex align-items-center flex-dir-col"
                        : "d-flex align-items-center"
                    }>
                    <div>
                      <Tooltip title={"ABC"}>
                        <a
                          className="board-anquor cursor-pointer"
                          // onClick={openBoard}
                        >
                          {/* {`${historyresponse_object.user_object.key}`.toUpperCase()} */}
                        </a>
                      </Tooltip>
                      <p className="worklog-title mr-2">
                        {history?.response_object?.user_object?.username}
                      </p>
                    </div>
                  </div>
                  {/* <p className="hours">{worklog.hours_spent} Hour</p> */}
                </div>
                <p
                  className={
                    isMobile
                      ? "worklog-worklog comment-content mt-4"
                      : "worklog-worklog comment-content"
                  }
                  dangerouslySetInnerHTML={{
                    __html: history?.history_text,
                  }}></p>
              </div>
            </div>
          </Grid>
          <Grid
            item
            xs={isMobile ? 12 : 3}
            sx={{ paddingTop: isMobile && "0px !important" }}>
            <div className={"right-details d-flex"}>
              <p className="worklog-date-time comment-date comment">
                Updated on{" "}
              </p>
              <p className="worklog-date-time comment-date">
                {/* {!sameDay && <img src={lateIcon} className="late-icon" />} */}

                {isMobile
                  ? moment
                      .utc(history?.at_time)
                      .local()
                      .format("MMM D, YYYY [at] h:mm A")
                  : moment
                      .utc(history?.at_time)
                      .local()
                      .format("MMMM D, YYYY [at] h:mm A")}
              </p>
            </div>
          </Grid>
        </Grid>
      ) : (
        <></>
      )}
    </>
  );
}

export default FeedGitHistory;

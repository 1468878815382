import React, { useState } from 'react'
import {
  Button,
  FormControl,
  InputAdornment,
  OutlinedInput,
  TextareaAutosize,
  Typography,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import CheckBox from "../../../../styles/svg/id-icon.svg";

function FieldCreateEditCard({
  handleKeyDown,
  setTitle,
  setCreateBtnOpen,
  edit = false,
  value,
  minRows = 3,
  className = "create-card-textarea",
}) {
   const [error, setError] = useState(false);

   const handleChange = (event) => {
     const value = event.target.value;
     setTitle(value);
     if (value.length <= 255) {
       setError(false);
     } else {
       setError(true);
     }
   };

  return (
    <FormControl fullWidth onClick={(e) => e.stopPropagation()}>
      <TextareaAutosize
        aria-label="empty textarea"
        placeholder="What needs to be done?"
        minRows={minRows} // Set the minimum number of rows
        className={`${className} ${error ? "create-card-error-textarea" : ""}`}
        defaultValue={value}
        onKeyDown={handleKeyDown}
        onChange={handleChange}
        onBlur={setCreateBtnOpen}
        autoFocus
      />

      {!edit && (
        <InputAdornment position="end">
          {/* <IconButt edge="end" aria-label="send"> */}
          {/* <Send sx={{ marginBottom: "35px" }} />{" "} */}
          <img src={CheckBox} className=" mb-6" />
          <ExpandMoreIcon className="mb-6" />
          {/* Replace Send with your desired icon */}
          {/* </IconButt> */}
        </InputAdornment>
      )}
      {error && (
        <Typography variant="body2" color="error" className='card-error-font-size'>
          Character limit exceeded! Maximum 255 characters allowed.
        </Typography>
      )}
    </FormControl>
  );
}

export default FieldCreateEditCard
import React, { useState } from 'react'
import FormDialog from '../../../../components/Dialog/FormDialog'
import TeamForm from './TeamForm';

function Team({ params }) {
     const [anchorEl, setAnchorEl] = useState(null);
     const open = Boolean(anchorEl);
     const handleClose = (action) => {
       setAnchorEl(null);
     };
     const handleClick = (event) => {
       setAnchorEl(event.currentTarget);
     };
  return (
    <>
      {open && (
        <FormDialog
          open={open}
          handleClose={handleClose}
          title={"Update Team"}
          btnTitle={"Update"}
        >
          <TeamForm params={params} onClose={handleClose} />
        </FormDialog>
      )}
      {params.value ? (
        <div onClick={handleClick} className="cursor-pointer">
          <p className="underline">{params.value?.team_name}</p>
        </div>
      ) : (
        <div onClick={handleClick} className="cursor-pointer">
          <p className="underline">NA</p>
        </div>
      )}
    </>
  );
}

export default Team
import React, { useState } from "react";
import { MenuItem } from "@mui/material";
import {Delete,Clear} from "@mui/icons-material";

import { createAvtarName, differenceInDaysOrHours, hasImage } from "../../../../../utils/helpers";
import { localStorageInstance } from "../../../../../utils/localStorage";
import Avatar from "../../../../../components/Avatar";
import ConfirmationDialog from "../../../../../components/Dialog/ConfirmationDialog";
import toast from "react-hot-toast";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useParams } from "react-router-dom";
import { deleteBoardUsers } from "../../../../../sevices/apiBoards";
import { getAPIBaseUrl, isAdmin } from "../../../../../utils/constants";
import { useDispatch } from "react-redux";
import { filterUser } from "../../../../../redux/usersSlice";
import { useSelector } from "react-redux";

function RemoveUser({ user, ownerId, onClick, closeUserList, isSelected ,removeSearchParam}) {
  const { id } = useParams();
  const queryClient = useQueryClient();
  const [removeDialog, setRemoveDialog] = useState(false);
  const [loading, setLoading] = useState(false);
  const [hoveredIndex, setHoveredIndex] = useState(null);
  const dispatch = useDispatch();
  const getUrl = getAPIBaseUrl();

  const currentUser = useSelector((state) => state.users.logInUser);
  const convertedImage = getUrl + user.user_image;
  console.log("USER",user)
  const handleCloseDialog = () => {
    setRemoveDialog(false);
  };

  const { mutate: removeMutate } = useMutation({
    mutationFn: deleteBoardUsers,
    onSuccess: (res) => {
      queryClient.invalidateQueries({
        queryKey: ["boardUsers", id],
      });
      setLoading(false);
      toast.success(res.data.message);
      handleCloseDialog();
    },
    onError: (error) => {
      console.error("Error Delete Attachments", error);
      toast.error(error.response.data.message);
      setLoading(false);
      handleCloseDialog();
    },
  });

  const callback = () => {
    setLoading(true);
    removeMutate({ board_id: id, user_id: user.user_id });
  };

  const clearFilter = (e)=>{
    e.stopPropagation();
    dispatch(filterUser(null));
   removeSearchParam('search')
  }

  

  const openPopUp = (e) => {
    e.stopPropagation();
    setRemoveDialog(true);
  };


  return (
    <>
      {removeDialog && (
        <ConfirmationDialog
          open={removeDialog}
          handleClose={handleCloseDialog}
          message={"Are you sure to delete this user from board?"}
          callback={callback}
          loading={loading}
          btnTitle={"Delete"}
          title={"Delete User From Board"}
          btnColor="var(--dark-light-error, #B71D18);
"
        />
      )}
      <MenuItem
        key={`user-avtar-dropdown-${user.user_id}`}
        //   onClick={() => setAnchorEl(null)}
        onClick={() => {
          onClick();
          closeUserList();
        }}
        className={`${isSelected ? "active-assign" : ""}`}
        onMouseEnter={() => setHoveredIndex(true)}
        onMouseLeave={() => setHoveredIndex(null)}
      >
     
          <Avatar
            key={`user-avtar-${user.user_id}`}
            username={user.username}
            user_image={user.user_image}
            title={user.username}
            className="user-avtar-dropdown user-avatar mr-8"
            style={! hasImage(user.user_image) && ({
              bgcolor: user?.profile_color?.background,
              color: user?.profile_color?.text,
            })}
          >
            {createAvtarName(user.username)}
          </Avatar>
          <div>
        {user.username}
            <p style={{fontWeight: '400px', fontSize: '14px', color: 'rgba(33, 43, 54, 0.6)'}}>{differenceInDaysOrHours(user.last_seen)}</p>
        </div>
        <div className="remove-user-delete-icon">
          {isSelected && <Clear onClick={clearFilter} />}
          {(ownerId == currentUser.user_id || isAdmin()) && hoveredIndex && (
            <Delete onClick={openPopUp} />
          )}
        </div>
      </MenuItem>
    </>
  );
}

export default RemoveUser;

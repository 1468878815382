import * as React from "react";
// import {useState, useEffect} from 'react'
import Stack from "@mui/material/Stack";
import Chip from "@mui/material/Chip";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import { useLebels } from "../../../../../../utils/reactQuery/boards";
import { useParams } from "react-router-dom";
import { createAndUpdateLabel } from "../../../../../../sevices/apiCards";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import toast from "react-hot-toast";

export default function ComboBox({
  labels,
  // setLebelCallback,
  // onKeyDown,
  // onBlur,
  boardId,
  cardId,
  setShowLebel,
}) {
  // const { id } = useParams();
  const [newLabel, setNewLabel] = React.useState(
    labels.map((item) => item.label)
  );
  const [manualEntered, setManualEntered] = React.useState("");
  const queryClient = useQueryClient();
  // const [options, setOptions] = useState([])
  const { isLoading, error, lebels: lebelsOpt, isError } = useLebels(boardId);

  // const options =
  //   lebelsOpt?.data?.data?.responseData.map((item) => ({
  //     label: item.label,
  //     label_id: item.label_id,
  //   })) || [];
  console.log("board", boardId);
  const options =
    lebelsOpt?.data?.data?.responseData.map((item) => item.label) || [];

  const { mutate: createAndUpdateLabelMutate } = useMutation({
    mutationFn: createAndUpdateLabel,
    onSuccess: (res) => {
      queryClient.invalidateQueries({
        queryKey: [`card`, cardId],
      });
      queryClient.invalidateQueries({
        queryKey: [`lebels`, boardId],
      });
      queryClient.invalidateQueries({
        queryKey: [`cards`, boardId.toString()],
      });
      queryClient.invalidateQueries({
        queryKey: [`mineCards`],
      });
      // setLoading(false);
      // console.log(res);
      //    reset();
      toast.success(res.data.message);
      // setDueDate(false);
      setShowLebel(false);
      // handleClose();
    },
    onError: (error) => {
      console.error("Error Delete Attachments", error);
      toast.error(error.response.data.message);
      // setLoading(false);
      // handleClose();
    },
  });

  const handleInputChange = (event, newInputValue) => {
    console.log("handleInputChange", newInputValue);
    setNewLabel(newInputValue);
  };
  const enterCallback = (e) => {
    if (e.key === "Enter") {
      console.log("from child ===", manualEntered, newLabel);
      if (manualEntered !== "" && manualEntered !== 0) {
        console.log("from child ===", manualEntered, newLabel);
        createAndUpdateLabelMutate({
          card_id: cardId,
          data: { labels: [...newLabel, manualEntered] },
        });
      } else {
        createAndUpdateLabelMutate({
          card_id: cardId,
          data: { labels: [newLabel] },
        });
      }
      setShowLebel(false);
    }
  };

  const handleBlur = (e) => {
    if (manualEntered !== "") {
      createAndUpdateLabelMutate({
        card_id: cardId,
        data: { labels: [...newLabel, manualEntered] },
      });
    } else {
      createAndUpdateLabelMutate({
        card_id: cardId,
        data: { labels: [newLabel] },
      });
    }
    setShowLebel(false);
  };
  const handleChange = (e) => {
    console.log("CHANGE", e.target.value);
    setManualEntered(e.target.value);
  };

  return (
    <Autocomplete
      onBlur={handleBlur}
      autoFocus
      multiple
      id="size-small-outlined-multi"
      size="small"
      options={options}
      // defaultValue={labels.map((item) => ({
      //   label: item.label,
      //   label_id: item.label_id,
      // }))}
      defaultValue={labels.map((item) => item.label)}
      value={newLabel}
      onChange={handleInputChange}
      onInputChange={handleChange}
      onKeyDown={enterCallback}
      sx={{
        width: "100%",
        height: "32px",
        marginRight: "0px",
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          inputProps={{
            ...params.inputProps,
            autoFocus: true,
          }}
          style={{
            background: "white",
          }}
        />
      )}
    />
  );
}

import { Box } from "@mui/material";
import React, { useEffect } from "react";
import Header from "./header";
import Workflow from "./KanbanBoard";
import { localStorageInstance } from "../../../utils/localStorage";
import { useLoginUser } from "../../../utils/reactQuery/users";
import { useDispatch } from "react-redux";
import { user } from "../../../redux/usersSlice";
import { useParams, useSearchParams } from "react-router-dom";
import { useSelector } from "react-redux";
import Worklog from "../Worklog";
import AllocationGrouping from "../Grouping/AllocationGrouping";
import HorizontalTabs from "./Tabs";

function TaskDetail() {
  const [searchParams, setSearchParams] = useSearchParams();
  const tab = searchParams.get("tab");
  const userId = localStorageInstance.getItem("userId");
  const { data, isLoading } = useLoginUser(userId);
  const dispatch = useDispatch();
  const open = useSelector((state) => state.search.taskTab);

  const updateSearchParams = (key, value) => {
    const newSearchParams = new URLSearchParams(searchParams);
    newSearchParams.set(key, value);
    setSearchParams(newSearchParams);
  };

  if (data) {
    dispatch(user(data.data.data.responseData));
  }

  useEffect(() => {
    if (!tab) updateSearchParams("tab", "mine");
  }, []);
  return (
    <>
      {/* <HorizontalTabs /> */}
      {!isLoading && (
        <Box>
          {tab === "team" && <AllocationGrouping />}
          {tab === "mine" && (
            <>
              <Header />
              <Workflow />
            </>
          )}
          {tab === "worklog" && (
            <>
              <Worklog />
            </>
          )}
        </Box>
      )}
    </>
  );
}

export default TaskDetail;
